import React, { FC, useState } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import * as s from './style';
import { Grid, CircularProgress, Typography, InputAdornment, IconButton } from '@material-ui/core';
import BackDrop from '../BackDrop';
// import { MenuItem, Typography, Grid, TextField as MiTextField, InputAdornment, , Hidden } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Button } from '../';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ReCAPTCHA from "react-google-recaptcha";


export interface FormFields {
    [key: string]: string,
    password: string
}

export interface Props {
    idName?: string,
    idTitle?: string
    handleSubmit: (values: FormFields) => Promise<void>
}

const LoginForm: FC<Props> = ({ idName = "username", idTitle = "Email", handleSubmit }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [captcha, setCaptcha] = useState<"Success" | "Expired" | null>(null);
    const formSchema = yup.object({
        [idName]: yup.string().required("Required"),
        password: yup.string().required('Required')
    })

    const formInitialValues = { [idName]: "", password: "" }

    const handleChange = (token: string | null) => {
        console.log("Captcha value:", token);
        // if value is null recaptcha expired
        if (token === null) { setCaptcha("Expired") }
        else { setCaptcha("Success") }
    };

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        await handleSubmit(values);
        setSubmitting(false);
    }

    return (
        <Formik initialValues={formInitialValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
        >
            {({ isValid, isSubmitting }) => (
                <>
                    <Form style={{ ...s.formStyle(), minWidth: '280px', maxWidth: '500px', margin: '0 auto' }} >
                        <BackDrop open={isSubmitting} >
                            <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
                        </BackDrop>
                        {/* /////////////////////////////////////////   login credential fields /////////////////////////////////////// */}
                        <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                            <Grid item container alignItems='center' >
                                <Field required fullWidth component={TextField} name={idName} label={idTitle} type='text' variant='outlined' />
                            </Grid>
                            <br />
                            <Grid item container alignItems='center' >
                                <Field
                                    required component={TextField} fullWidth name='password' label='Password' variant='outlined'
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setShowPassword(!showPassword) }}
                                                onMouseDown={() => { setShowPassword(!showPassword) }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <br />
                            <Grid item container direction='column' >
                                <Button disabled={(!isValid || isSubmitting || captcha !== "Success")} color='primary' size='large' type='submit' >
                                    {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Login</div>}
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                        <ReCAPTCHA
                            style={{ display: "flex", width: "100%", justifyContent: "center" }}
                            sitekey={process.env.RECAPTCHA_SITE_KEY!}
                            onChange={handleChange}
                        />
                    </Form>
                </>
            )}
        </Formik>
    )
}

export default LoginForm;
