import React, { FC } from 'react';
import { Seo, PageLayout } from '../../components';
import { navigate, PageProps } from 'gatsby';
import config from '../../../app-config.json';
import { Grid, Typography } from '@material-ui/core';
import { BodyWrap, _StyledBtn1 } from "../taher-scheme";
import { siteMetadata } from '../../../gatsby-config';
import { useSelector } from 'react-redux';
import { getHsPortalDetails } from '../../reduxStore/slices/hsPortalSlice';
// import AccountApp, { } from './AccountApp';
import { AccountApp } from '../../components/husain-scheme';


export const PAGE_TITLE = config.pages.HUSAIN_SCHEME.title;
// const PAGE_PATH = config.pages.HUSAIN_SCHEME.path;
const NEW_ACCOUNT_PATH = config.pages.HUSAIN_SCHEME.routes.newAccount.path
const LOGIN_PATH = config.pages.HUSAIN_SCHEME.routes.login.path;
export const NEW_ACCOUNT_LINK = `${config.pages.HUSAIN_SCHEME.path}${NEW_ACCOUNT_PATH}`
export const LOGIN_LINK = `${config.pages.HUSAIN_SCHEME.path}${LOGIN_PATH}`
export const MetaDescription = `Participants in the ${PAGE_TITLE} are encouraged to give Qardan Hasana through the aegis of the Qardan Hasana Scheme towards the ennobling purpose of benefiting Mumineen in their time of need. The ${PAGE_TITLE} is based on the premise that Qardan Hasana provided by Mumineen to this Scheme will earn the Mumin thawaab and will be returnable. Givers to the ${PAGE_TITLE} will specify a time period/due date for which they are giving the amount. Participants should be encouraged to commit large amounts, and for longer periods, as consistently as possible.`

//////////////////////////////////  component ////////////////////////////////
const HusainiScheme: FC<PageProps> = ({ }) => {
    const { isAuthenticated, authToken } = getHsPortalDetails();

    return (
        <>
            <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} propMetaDescription={MetaDescription} />
            {isAuthenticated && authToken ?
                <AccountApp /> :
                <Default />
            }
        </>
    )
}

export default HusainiScheme;


const Default: FC<{}> = () => {
    return (
        <PageLayout>
            <BodyWrap >
                <br />
                <Typography variant='h2' color='inherit' >{PAGE_TITLE}</Typography>
                <Typography variant='h6' color='inherit' gutterBottom >(Refundable)</Typography>
                <hr /><br /> <br />
                <Typography align='justify' >Participants in the {PAGE_TITLE} are encouraged to give Qardan Hasana through the aegis of the Qardan Hasana Scheme towards the ennobling purpose of benefiting Mumineen in their time of need. The {PAGE_TITLE} is based on the premise that Qardan Hasana provided by Mumineen to this Scheme will earn the Mumin thawaab and will be returnable. Givers to the {PAGE_TITLE} will specify a time period/due date for which they are giving the amount. Participants should be encouraged to commit large amounts, and for longer periods, as consistently as possible.</Typography>
                <br />
                <Typography align='justify'>The concept of Husain scheme is to develop Mumineen’s mindset for giving Qardan Hasana as an integral aspect of their life thus creating a habit of it. </Typography>
                <br /><br />
                <Grid container justify='space-evenly' spacing={5} >
                    <Grid item container xs={12} sm={4} md={3} justify='center' >
                        <_StyledBtn1 onClick={() => { navigate(NEW_ACCOUNT_LINK) }}>
                            New Account Application Form
                        </_StyledBtn1>
                    </Grid>
                    <Grid item container xs={12} sm={4} md={3} justify='center' >
                        <_StyledBtn1 onClick={() => { navigate(LOGIN_LINK) }}>
                            Existing Account Holders
                        </_StyledBtn1>
                    </Grid>
                </Grid>
                <br /><br />
            </BodyWrap>
        </PageLayout>
    )
}