import styled from 'styled-components';
import React, { FC, memo } from 'react';
const logo300px = require('../../assets/logo-gold.png');
const goldStripe = require('../../assets/golden-stripe.png');
import { displayFlex, screenWidths} from '../../theme/css-generators';
// import {IconButton} from '@material-ui/core';
// import {Menu} from '@material-ui/icons';
import { Link } from 'gatsby';

export interface Props { hideLogoLoop?: boolean, animationOff?: boolean };

const LogoAnim: FC<Props> = ({ hideLogoLoop = false, animationOff = false }) => {
    const animDelay = animationOff ? "0s" : "1.5s";
    return (
        <div>
            <LogoStripeWrap style={{ display: hideLogoLoop ? "none" : undefined }} >
                <GoldenStripe animDelay={animDelay} mirror={true} />
                <Link to='/' ><Logo animDelay={animDelay} src={logo300px} /></Link>
                <GoldenStripe animDelay={animDelay} />
            </LogoStripeWrap>
        </div>
    );
}
export default memo(LogoAnim);


const LogoStripeWrap = styled.div`
    ${displayFlex({})}
    margin: 10px 0;
`;

const Logo = styled.img<{ animDelay: string }>`
    width: 108px;
    animation: fadeIn ${({ animDelay }) => animDelay} forwards;

    @keyframes  fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    @media (max-width: ${screenWidths._960px}){
        width: 80px;
     }
`;


const GoldenStripe = styled.div<{ mirror?: boolean, animDelay: string }>`
    ${({ mirror }) => mirror ? "-webkit-transform: scaleX(-1); transform: scaleX(-1);" : ""}
    animation: shrikWidth ${({ animDelay }) => animDelay} forwards ease-out;
    margin-bottom: 2px;
    background: url(${goldStripe}) repeat-x  0% 0% / auto 100%;
    padding: 45px 0;
    background-origin: border-box;
    @keyframes  shrikWidth {
        0% { width: 0%;  }
        100% { width: 50% ; }
    }

    @media (max-width: ${screenWidths._960px} ){
        background-image: url(${goldStripe});
        padding: 35px 0;
     }    
`;


export const SBQHtitle = styled.img<{ animDelay: string }>`
    width: 460px;
    @keyframes  slideUp {
        0% { transform: translate(0, 20px); opacity: 0; }
        100% { transform: rotate(0, 0px); opacity: 1; }
    }
    @media (max-width: ${screenWidths._1739px}){
        width: 385px;
     }
     @media (max-width: ${screenWidths._1600px}){
        width: 385px;
     }
     @media (max-width: ${screenWidths._1503px}){
        width: 310px;
     }
     @media (max-width: ${screenWidths._1375px}){
        width: 250px;
     }
     @media (max-width: ${screenWidths._1280px}){
        width: 220px;
     }
`;
