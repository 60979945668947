import { CSSProperties } from 'react';

export const displayFlex = ({ dir = 'row', justify = 'center', align = 'center', wrap = 'nowrap' }:
    {
        dir?: CSSProperties['flexDirection'],
        justify?: CSSProperties['justifyContent'],
        align?: CSSProperties['alignItems'],
        wrap?: CSSProperties['flexWrap'],
    }) => {
    return `
        display: flex;
        flex-direction: ${dir};
        justify-content: ${justify};
        align-items: ${align};
        flex-wrap: ${wrap};

    `
}

export const screenWidths = {
    _1739px: "1739px",
    _1600px: '1600px',
    _1503px: '1503px',
    _1375px: '1375px',
    _1280px: '1280px',
    _960px: '960px',
    _600px: "600px",
    _500px: "500px",
    _390px: "390px",
}