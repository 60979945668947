import { Typography } from '@material-ui/core';
import config from '../../../app-config.json';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import * as s from './style';

export interface Props {

}


//////////////////////////////////  component ////////////////////////////////
const Footer: FC<Props> = ({ }) => {
    return (
        <s.Wrap>
            <s.DesignStripe />
            <Typography align='center' gutterBottom >
                <b>
                    COPYRIGHT 2023 © ALL RIGHTS RESERVED | <Link to={config.pages.PRIVACY_POLICY.path} >{config.pages.PRIVACY_POLICY.title.toUpperCase()}</Link> | {config.WEBSITE_TITLE.toUpperCase()}
                </b>
            </Typography>
        </s.Wrap>
    )
};


export default Footer;