import { API } from "aws-amplify";
import { GraphQLResult, GraphqlError } from '../typedefs';
import { LoginResponse, QueryGetAllHSusersArgs, PaymentWithdrawalRequest, QueryGetAllSbqHusersArgs, GetAllHSusersFilter, HSuserFullInfo, WithdrawalRequestStatus, QueryGetHSuserInfoArgs, QueryGetAllWithdrawalRequestsArgs, QueryGetWithdrawalRequestDetailsArgs, FilterOptions, UserType, HSusersWithTotalBalanceOutput, QueryGetAllHSaccountsWithBalancesArgs, QueryGetAllDormantHSaccountsArgs, HSuserBank, QueryGetAllQardanApplicantsArgs, GetAllQardanApplicantsOutput, GetAllQardanInstallmentsOutput, QardanApplicant, QueryGetAllQardanInstallmentsArgs, QueryGetQardanApplicantsWithOutstandingBalancesArgs, QardanApplicantList } from '../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { HSloginResponse, SBQHloginResponse, GetAllSbqHusersOutput, GetAllHSusersOutput, GetAllWithdrawalRequestsOutput, HStransactionsOutput, QardanloginResponse } from './types';
import { getCookie } from '../helper';
import { isAuthTokenExpired } from './'


export const LoginToHSPortal = async (userId: string, password: string): Promise<GraphQLResult<{ loginToHSPortal: HSloginResponse }>> => {
  return await API.graphql({
    query: ` query MyQuery {
        loginToHSPortal (id: "${userId}", password: "${password}") {
        accessToken
        message
        warning
          userDetails {
              userType
              ... on HSuser {
                id
                email
                account_id
                first_name
                its_no
                last_name
                phone_no
                userType
                child_users
            }
          }
        }
    }
  `}) as any
}

export const LoginToQardanPortal = async (userId: string, password: string): Promise<GraphQLResult<{ loginToQardanPortal: QardanloginResponse }>> => {
  return await API.graphql({
    query: ` query MyQuery {
      loginToQardanPortal (id: "${userId}", password: "${password}") {
        accessToken
        message
        warning
        userDetails {
              userType
              ... on QardanApplicant {
                id
                userType
                title
                first_name
                last_name
                company_name
                email
                phone_no
                its_no
                loanRepaid
                loanAllocation { id date applicant_id amount repayment_date repayment_duration_in_months status }
            }
        }
      }
    }
  `}) as any
}


export const VerifySBQHSecuritycode = async (email: string, verificationCode: string): Promise<GraphQLResult<{ verifySBQHSecuritycode: SBQHloginResponse }>> => {
  return await API.graphql({
    query: ` query MyQuery {
        verifySBQHSecuritycode (email: "${email}", verificationCode: "${verificationCode}") {
            accessToken
            message
            warning
            userDetails {
                userType
                ... on SBQHuser {
                    id
                    email
                    name
                    userType
                    access {
                      HS_Accounts {
                        read
                        write
                      }
                      HS_Transactions {
                        read
                        write
                      }
                      HS_Withdrawal_Requests {
                        read
                        write
                      }
                      SBQH_Admin_Users {
                        read
                        write
                      }
                      Qardan_Applicants {
                        read
                        write
                      }
                      Qardan_Installments {
                        read
                        write
                      }
                    }
                }
            }
        }
    }
  `}, { Authorization: "" }) as any
}


export const VerifyHSsecuritycode = async (hsUserId: string, verificationCode: string): Promise<GraphQLResult<{ verifyHSsecuritycode: HSloginResponse }>> => {
  return await API.graphql({
    query: ` query MyQuery {
      verifyHSsecuritycode (hsUserId: "${hsUserId}", verificationCode: "${verificationCode}") {
            accessToken
            message
            warning
            userDetails {
                userType
                ... on HSuser {
                  id
                  email
                  account_id
                  first_name
                  its_no
                  last_name
                  phone_no
                  userType
                  child_users
              }
            }
        }
    }
  `}, { Authorization: "" }) as any
}


export const VerifyQardanSecuritycode = async (applicantId: string, verificationCode: string): Promise<GraphQLResult<{ verifyQardanSecuritycode: QardanloginResponse }>> => {
  return await API.graphql({
    query: ` query MyQuery {
      verifyQardanSecuritycode (applicantId: "${applicantId}", verificationCode: "${verificationCode}") {
            accessToken
            message
            warning
            userDetails {
                userType
                ... on QardanApplicant {
                  id
                  userType
                  title
                  first_name
                  last_name
                  company_name
                  email
                  phone_no
                  its_no
                  loanRepaid
                  loanAllocation { id date applicant_id amount repayment_date repayment_duration_in_months status }
              }
            }
        }
    }
  `}, { Authorization: "" }) as any
}


export const LoginToSBQHPortal = async (email: string, password: string): Promise<GraphQLResult<{ loginToSBQHPortal: string }>> => {
  return await API.graphql({
    query: ` query MyQuery {
        loginToSBQHPortal (email: "${email}", password: "${password}")
    }
  `}, { Authorization: "" }) as any
}


export const CheckTokenExpiryHSuser = (cookieNameForToken?: string) => async (): Promise<GraphQLResult<{ checkTokenExpiry: HSloginResponse }>> => {
  return await API.graphql({
    query: `query MyQuery {
            checkTokenExpiry {
              accessToken
              message
              warning
              userDetails {
                userType
                ... on HSuser {
                  id
                  email
                  account_id
                  first_name
                  its_no
                  last_name
                  phone_no
                  userType
                  child_users
                }

              }
            }
          }
          `}, { Authorization: `Bearer ${getCookie((cookieNameForToken as any) || "hs_auth_token")}` }) as any
}


export const CheckTokenExpirySBQHuser = async (): Promise<GraphQLResult<{ checkTokenExpiry: SBQHloginResponse }>> => {
  return await API.graphql({
    query: `query MyQuery {
            checkTokenExpiry {
              accessToken
              message
              warning
              userDetails {
                userType
                ... on SBQHuser {
                  id
                  email
                  name
                  userType
                  access {
                    HS_Accounts {
                      read
                      write
                    }
                    HS_Transactions {
                      read
                      write
                    }
                    HS_Withdrawal_Requests {
                      read
                      write
                    }
                    SBQH_Admin_Users {
                      read
                      write
                    }
                    Qardan_Applicants {
                      read
                      write
                    }
                    Qardan_Installments {
                      read
                      write
                    }
                  }
                }
              }
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}


export const CheckTokenExpiryQardanApplicant = async (): Promise<GraphQLResult<{ checkTokenExpiry: QardanloginResponse }>> => {
  return await API.graphql({
    query: `query MyQuery {
              checkTokenExpiry {
                accessToken
                message
                warning
                userDetails {
                  userType
                  ... on QardanApplicant {
                    id
                    userType
                    title
                    first_name
                    last_name
                    company_name
                    email
                    phone_no
                    its_no
                    loanAllocation { id date applicant_id amount repayment_date repayment_duration_in_months status }
                  }
                }
              }
            }
          `}, { Authorization: `Bearer ${getCookie("qardan_auth_token")}` }) as any
}


export const GetAllHSusers = async (filterBy: GetAllHSusersFilter = {}, pageNumber: number = 1, pageSize: number = 10): Promise<GraphQLResult<{ getAllHSusers: GetAllHSusersOutput }>> => {
  const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

  // const filter = Object.entries(filterBy)
  //   .map(v => `${v[0]}: ${typeof v[1] === "string" ? `"${v[1]}"` : v[1]}`)
  //   .join(', ');

  return await API.graphql({
    query: `query MyQuery( $filterBy: GetAllHSusersFilter = {}, $pageNumber: Int = 1, $pageSize: Int = 10 ) {
      getAllHSusers( filterBy: $filterBy, pageNumber: $pageNumber, pageSize: $pageSize ) {
              users{ id first_name  last_name  its_no } 
              totalCount
            }
          }
          `,
    variables: { pageNumber, pageSize, filterBy } as QueryGetAllHSusersArgs
  }
    , { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}


export const GetAllWithdrawalRequests = async ({ status, pageNumber = 1, pageSize = 10 }: QueryGetAllWithdrawalRequestsArgs): Promise<GraphQLResult<{ getAllWithdrawalRequests: GetAllWithdrawalRequestsOutput }>> => {
  const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

  const _status = status ? `status: ${status}` : ""
  return await API.graphql({
    query: `query MyQuery {
      getAllWithdrawalRequests(pageNumber:${pageNumber}, pageSize:${pageSize}, ${_status} ) {
              data { account_id first_name last_name request_id request_date email requested_amount status }
              totalCount
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}


export const GetWithdrawalRequestDetails = async ({ id }: QueryGetWithdrawalRequestDetailsArgs): Promise<GraphQLResult<{ getWithdrawalRequestDetails: PaymentWithdrawalRequest }>> => {
  const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

  return await API.graphql({
    query: `query MyQuery {
      getWithdrawalRequestDetails( id:${id} ) {
              id 
              account_id 
              request_date 
              amount 
              bank_account_number 
              email
              sort_code name_as_per_bank_account 
              status 
              fulfill_date
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const GetAllQardanApplicants = async ({ filter = {} }: QueryGetAllQardanApplicantsArgs): Promise<GraphQLResult<{ getAllQardanApplicants: GetAllQardanApplicantsOutput }>> => {
  const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

  return await API.graphql({
    query: `query MyQuery($filter: FilterOptions = {}) {
      getAllQardanApplicants( filter: $filter ) {
        list {
          id
          userType
          title
          first_name
          last_name
          company_name
          email
          phone_no
          its_no
          loanAllocation { id date applicant_id amount repayment_date repayment_duration_in_months status }
        }
        totalCount
      }
    }
    `, variables: { filter }
  }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const GetQardanApplicantsWithOutstandingBalances = async ({ filter = {} }: QueryGetQardanApplicantsWithOutstandingBalancesArgs): Promise<GraphQLResult<{ getQardanApplicantsWithOutstandingBalances: QardanApplicantList }>> => {
  const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

  return await API.graphql({
    query: `query MyQuery($filter: FilterOptions = {}) {
      getQardanApplicantsWithOutstandingBalances( filter: $filter ) {
        list {
          id
          userType
          title
          first_name
          last_name
          company_name
          email
          phone_no
          its_no
          loanRepaid
          loanAllocation { id date applicant_id amount repayment_date repayment_duration_in_months status }
        }
        totalCount
      }
    }
    `, variables: { filter }
  }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const GetQardanApplicantInfo = async (userType: UserType, { applicantId }: { applicantId: string }): Promise<GraphQLResult<{ getQardanApplicantInfo: QardanApplicant }>> => {
  let authRes;
  let jwtToken;
  if (userType === UserType.SbqhUser) {
    authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie("sbqh_auth_token")
  }
  else if (userType === UserType.QardanUser) {
    authRes = isAuthTokenExpired("qardan_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie("qardan_auth_token")
  }

  return await API.graphql({
    query: `query MyQuery {
      getQardanApplicantInfo( applicantId: "${applicantId}" ) {
          id
          userType
          title
          first_name
          last_name
          company_name
          email
          phone_no
          its_no
          loanRepaid
          loanAllocation { id date applicant_id amount repayment_date repayment_duration_in_months status }
      }
    }
    `
  }, { Authorization: `Bearer ${jwtToken}` }) as any
}

export const GetAllQardanInstallments = async (userType: UserType, { filter = {} }: QueryGetAllQardanInstallmentsArgs): Promise<GraphQLResult<{ getAllQardanInstallments: GetAllQardanInstallmentsOutput }>> => {
  let authRes;
  let jwtToken;
  if (userType === UserType.SbqhUser) {
    authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie("sbqh_auth_token")
  }
  else if (userType === UserType.QardanUser) {
    authRes = isAuthTokenExpired("qardan_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie("qardan_auth_token")
  }

  return await API.graphql({
    query: `query MyQuery($filter: FilterOptions = {}) {
      getAllQardanInstallments( filter: $filter ) {
        list {
          id
          date
          applicant_id
          amount
          description
        }
        totalCount
        totalLoanAllocated
        totalLoanPaid
        totalLoanPending
      }
    }
    `, variables: { filter }
  }, { Authorization: `Bearer ${jwtToken}` }) as any
}

export const GetHSuserInfo = (cookieNameForToken?: string) => async (userType: UserType, { userId }: QueryGetHSuserInfoArgs): Promise<GraphQLResult<{ getHSuserInfo: Omit<HSuserFullInfo, "__typename"> }>> => {
  let authRes;
  let jwtToken;
  if (userType === UserType.SbqhUser) {
    authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie("sbqh_auth_token")
  }
  else if (userType === UserType.HsUser) {
    authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie((cookieNameForToken as any) || "hs_auth_token")
  }

  return await API.graphql({
    query: `query MyQuery {
      getHSuserInfo( userId: "${userId}" ) {
              id
              first_name
              last_name
              account_id
              its_no 
              balance_amount
              phone_no
              email
            }
          }
          `}, { Authorization: `Bearer ${jwtToken}` }) as any
}


export const GetAllSBQHusers = async ({ filterBy = {}, pageNumber = 1, pageSize = 10 }: QueryGetAllSbqHusersArgs): Promise<GraphQLResult<{ getAllSBQHusers: GetAllSbqHusersOutput }>> => {
  const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

  const filter = Object.entries(filterBy || {})
    .map(v => `${v[0]}: ${typeof v[1] === "string" ? `"${v[1]}"` : v[1]}`)
    .join(', ');

  return await API.graphql({
    query: `query MyQuery {
      getAllSBQHusers( pageNumber: ${pageNumber}, pageSize: ${pageSize}, filterBy: {${filter}} ) {
        users {
          email
          id
          name
          userType
          access {
            HS_Accounts {
              read
              write
            }
            HS_Transactions {
              read
              write
            }
            HS_Withdrawal_Requests {
              read
              write
            }
            SBQH_Admin_Users {
              read
              write
            }
            Qardan_Applicants {
              read
              write
            }
            Qardan_Installments {
              read
              write
            }
          }
        }
        totalCount
      }
    }
    `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const GetAllHStransactions = (cookieNameForToken?: string) => async (userType: UserType, filter?: FilterOptions): Promise<GraphQLResult<{ getAllHStransactions: HStransactionsOutput }>> => {
  let authRes;
  let jwtToken;
  if (userType === UserType.SbqhUser) {
    authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie("sbqh_auth_token")
  }
  else if (userType === UserType.HsUser) {
    authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie((cookieNameForToken as any) || "hs_auth_token")
  }

  return await API.graphql({
    query: `query MyQuery($filter: FilterOptions = {}) {
      getAllHStransactions(filter: $filter) {
        data {
          id
          description
          date
          account_id
          hsUserId
          amount
        }
        totalCount
        amountIn
        amountOut
      }
    }
    `,
    variables: { filter }
  }, { Authorization: `Bearer ${jwtToken}` }) as any
}


export const GetAllHSaccountsWithBalances = async (args: QueryGetAllHSaccountsWithBalancesArgs): Promise<GraphQLResult<{ getAllHSaccountsWithBalances: HSusersWithTotalBalanceOutput }>> => {
  const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

  return await API.graphql({
    query: `query MyQuery($withTotalCount: Boolean, $pageSize: Int, $pageNumber: Int, $dateTo: AWSTimestamp, $dateFrom: AWSTimestamp!) {
      getAllHSaccountsWithBalances(dateFrom: $dateFrom, dateTo: $dateTo, pageSize: $pageSize, withTotalCount: $withTotalCount, pageNumber: $pageNumber) {
        data {
          account_id
          balance_amount
          email
          first_name
          id
          last_name
          its_no
          last_transaction_date
          phone_no
        }
        totalCount
      }
    }
    `,
    variables: { ...args }
  }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}


export const GetAllDormantHSaccounts = async (args: QueryGetAllDormantHSaccountsArgs): Promise<GraphQLResult<{ getAllDormantHSaccounts: HSusersWithTotalBalanceOutput }>> => {
  const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

  return await API.graphql({
    query: `query MyQuery($withTotalCount: Boolean, $pageSize: Int, $pageNumber: Int, $dateTo: AWSTimestamp, $dateFrom: AWSTimestamp!) {
      getAllDormantHSaccounts(dateFrom: $dateFrom, dateTo: $dateTo, pageSize: $pageSize, withTotalCount: $withTotalCount, pageNumber: $pageNumber) {
        data {
          account_id
          balance_amount
          email
          first_name
          id
          last_name
          its_no
          last_transaction_date
          phone_no
        }
        totalCount
      }
    }
    `,
    variables: { ...args }
  }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}


export const SendEmailConfirmationCode = (cookieNameForToken?: string) => async (userType: UserType,): Promise<GraphQLResult<{ sendEmailConfirmationCode: Response }>> => {
  let authRes;
  let jwtToken;
  if (userType === UserType.SbqhUser) {
    authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie("sbqh_auth_token")
  }
  else if (userType === UserType.HsUser) {
    authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }
    jwtToken = getCookie((cookieNameForToken as any) || "hs_auth_token")
  }

  return await API.graphql({
    query: `query MyQuery {
      sendEmailConfirmationCode 
    }
    `,
  }, { Authorization: `Bearer ${jwtToken}` }) as any
}

export const GetHsUserBankAccounts = (cookieNameForToken?: string) => async (): Promise<GraphQLResult<{ getHsUserBankAccounts: HSuserBank[] }>> => {
  const authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }

  return await API.graphql({
    query: `query MyQuery {
        getHsUserBankAccounts {
          bank_account_number
          id
          name_as_per_bank_account
          sort_code
          user_id
        }
      }`,
  }, { Authorization: `Bearer ${getCookie((cookieNameForToken as any) || "hs_auth_token")}` }) as any
}

export const GetHsUserChildAccessToken = (cookieNameForToken?: string) => async (childUserId: string): Promise<GraphQLResult<{ getHsUserChildAccessToken: string }>> => {
  const authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }

  return await API.graphql({
    query: `query MyQuery {
      getHsUserChildAccessToken(childUserId: "${childUserId}")
      }`,
  }, { Authorization: `Bearer ${getCookie((cookieNameForToken as any) || "hs_auth_token")}` }) as any
}

