import { ComparisonOperators } from '../schemaTypes';
import errorMessages from './errorMessages';


export { errorMessages };

export const comparisonOperators = {
    [ComparisonOperators.Eq]: (value: string) => `= "${value}"`,
    [ComparisonOperators.Neq]: (value: string) => `!= "${value}"`,
    [ComparisonOperators.Gt]: (value: string) => `> "${value}"`,
    [ComparisonOperators.Lt]: (value: string) => `< "${value}"`,
    [ComparisonOperators.Gte]: (value: string) => `>= "${value}"`,
    [ComparisonOperators.Lte]: (value: string) => `<= "${value}"`,
    // [ComparisonOperators.IsEmpty]: "",
    // [ComparisonOperators.IsNotEmpty]: "",
    [ComparisonOperators.StartsWith]: (value: string) => `REGEXP "^${value}" `,
    [ComparisonOperators.EndsWith]: (value: string) => `REGEXP "${value}$" `,
    [ComparisonOperators.Contains]: (value: string) => `REGEXP "${value}" `,
}
