const config = require('./app-config.json');
const { SITE_URL, } = config;

const SITE_MAP_PATH = '/sitemap.xml';

module.exports = {
    siteMetadata: {
        title: `SBQH London`,
        description: `On the auspicious occasion of the Shahadat of Amir-Al-Mumineen Moulana Ali Ibn Abi Talib in ` +
            `the year 1427H corresponding to the 11th day of October 2006, Syedna Mohammed Burhanuddin Saheb (TUS) ` +
            `established a Qardan Hasana Scheme by the name of “Burhani Qardan Hasana Scheme”.`,
        author: `Hussain.mehboob@icloud.com`,
        url: SITE_URL
    },
    plugins: [
        "gatsby-plugin-react-helmet",
        "gatsby-plugin-typescript",
        "gatsby-plugin-styled-components",
        "gatsby-plugin-material-ui",
        {
            resolve: `gatsby-plugin-create-client-paths`,
            options: { prefixes: [] },
            // options: { prefixes: [`${ADMIN.path}/*`] },
        },
        {
            resolve: `gatsby-plugin-page-creator`,
            options: {
                path: `${__dirname}/src/pages`,
                ignore: {
                    patterns: [`**/*.slice.(js|ts)`, `**/AccountApp.(ts|js)?(x)`]
                },
            },
        },
        ///////////////////////////////////// google analytics plugin implementation ///////////////////////////////////
        {
            resolve: "gatsby-plugin-google-analytics",
            options: {
                trackingId: "UA-191807580-1",
                head: true,
                anonymize: true,
            }
        },
        ///////////////////////////////////// gatsby-plugin-sitemap implementation ///////////////////////////////////
        {
            resolve: `gatsby-plugin-sitemap`,
            options: {
                output: SITE_MAP_PATH,
                exclude: [`/search`],
                query: `
                {
                  site {
                    siteMetadata {
                      url
                    }
                  }
        
                  allSitePage {
                    nodes {
                      path
                    }
                }
              }`,
                resolveSiteUrl: ({ site, allSitePage }) => {
                    //Alternatively, you may also pass in an environment variable (or any location) at the beginning of your `gatsby-config.js`.
                    return site.siteMetadata.url
                },
                serialize: ({ site, allSitePage }) =>
                    allSitePage.nodes.map(node => {
                        return {
                            url: `${site.siteMetadata.siteUrl}${node.path}`,
                            changefreq: `weekly`,
                            priority: 0.7,
                        }
                    })
            }
        },
        ////////////////////////////////// gatsby-plugin-robots-txt implementation ////////////////////////
        {
            resolve: 'gatsby-plugin-robots-txt',
            options: {
                host: SITE_URL,
                sitemap: `${SITE_URL}${SITE_MAP_PATH}`,
                policy: [{ userAgent: '*', allow: '/' }]
            }
        },
    ]
};