import React, { FC, useState } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem, Typography, Grid, TextField as MiTextField, InputAdornment, CircularProgress, Hidden, Box } from '@material-ui/core';
import { AccountCircle, Payment } from '@material-ui/icons';
import * as s from './style';
import { countries } from './countries.json';
import { Button } from '..';
import * as yup from 'yup';
import { schemaObject, formInitialValues as formFieldValues, field as userDetailField, filterNumber } from './UserDetails';
import StripeInput, { Props as StripeInputProps } from './StripeInput';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { proceedPayment, getStripeAmount } from '../../services/stripe';
import { AlertSeverity, PaymentConfirmDialog, PaymentErrorDialog } from '../Alert';
import PaymentAlert from '../Alert/PaymentAlert';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import BackDrop from '../BackDrop';
// import config from '../../../app-config.json';
// import colors from '../../theme/colors';
// import { getAmountWithStripeFee } from '../../../../../functions/src/checkout/checkout';
// import { StyledBtn } from '../Button';
// import { StripeCardCvcElementChangeEvent, StripeCardElementChangeEvent, StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';

const amountPerUnit = 2.50;
export enum Units {
    "2 units" = "2", "4 units" = "4",
    "6 units" = "6", "8 units" = "8",
    "10 units" = "10", "12 units" = "12", Other = "Other",
}

export enum FieldName {
    // amount = "amount",
    personTitle = "personTitle",
    cardNumber = "cardNumber",
    expiryDate = 'expiryDate',
    cvcNo = "cvcNo",
    units = "units",
    otherUnits = "otherUnits"
}
export enum Person { Mr = "Mr", Mrs = "Mrs", Ms = "Ms" }

const formSchema = yup.object({
    ...schemaObject,
    // [FieldName.amount]: yup.mixed<string | number>().required("amou"),
    [FieldName.personTitle]: yup.mixed<keyof typeof Person>().required('Your title'),
    [FieldName.units]: yup.mixed<keyof typeof Units>().required('Select Unit'),
    // [FieldName.otherUnits]: yup.mixed<string | number>(),

});

export interface FormFields extends yup.InferType<typeof formSchema> {
    cardNumber: null | string,
    expiryDate: null | string,
    cvcNo: null | string,
    [FieldName.otherUnits]: number | string | null,
    amount: number | null
}


export const field: { [P in keyof FormFields]: { name: string, label: string } } = {
    ...userDetailField,
    amount: { name: "amount", label: 'Amount' },
    units: { name: "units", label: 'Units' },
    otherUnits: { name: "otherUnits", label: 'Other Units' },
    cardNumber: { name: "cardNumber", label: 'Card Number' },
    expiryDate: { name: "expiryDate", label: 'Expiration Date' },
    cvcNo: { name: "cvcNo", label: 'CVC' },
}

const formInitialValues: FormFields = {
    ...formFieldValues,
    personTitle: '' as "Mr",
    amount: 0,
    units: "" as "2 units",
    otherUnits: "",
    cardNumber: null,
    expiryDate: null,
    cvcNo: null,
}

//////////////////////////////////  component ////////////////////////////////
const PaymentFrom: FC<{ pageTitle: string }> = ({ pageTitle }) => {
    // const [openPaymentAlert, setOpenPaymentAlert] = useState<boolean>(false);
    const [isPaymentSucceed, setPaymentSucceed] = useState<boolean>(false);
    const [alert, setAlert] = useState<{ msg: string, open: boolean, severity: AlertSeverity }>({ msg: '', open: false, severity: "success" });
    const [formState, setFormState] = useState<FormFields>(formInitialValues);
    const [amount, setAmount] = useState(0);
    const [isFormResend, setFormResend] = useState(false);
    const classes = s.useStyles();
    const stripe = useStripe();
    const elements = useElements();
    // console.log('render');

    const handleUnitChange = (handleChange: (e: React.ChangeEvent) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === Units.Other) { handleChange(e); setAmount(0); }
        else { handleChange(e); setAmount(amountPerUnit * Number(e.target.value)); }
        handleChange(e);
    }
    const handleOtherUnitChange = (handleChange: (e: React.ChangeEvent) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(amountPerUnit * Number(e.target.value)); handleChange(e);
    }

    const handleValidateForm = (values: FormFields) => {
        const error: { [P in keyof FormFields]?: string; } = {}
        //// checking and verifying user's mobile number according to the country code 
        if (values.units === 'Other') {
            if (!Number(values.otherUnits)) {
                error.otherUnits = "Units required"
            }
            else if (Number(values.otherUnits) < 13) {
                error.otherUnits = "Units should not be less than 13"
            }
        }

        if (values.mobileNo) {
            const isPhoneNumValid = parsePhoneNumberFromString(values.mobileNo, "GB")?.isValid();
            if (!isPhoneNumValid) {
                error.mobileNo = 'Invalid mobile number'
            }
        }

        return error
    }

    const onSubmit = async (values: FormFields, { setSubmitting, submitForm }: FormikHelpers<FormFields>) => {
        console.log("Details received");

        if (!isFormResend) {
            const { isConfirmed } = await PaymentConfirmDialog(amount, pageTitle.split(' - ')[0]);
            if (!isConfirmed) { return };
            setFormResend(false);
        }
        try {
            const result = await proceedPayment(stripe, elements, getStripeAmount(amount), pageTitle, {
                email: values.email,
                name: `${values.personTitle} ${values.firstName} ${values.lastName}`,
                phone: values.mobileNo,
            }, { ...{ ...values, amount } as FormFields, });

            setSubmitting(false);
            if (result?.paymentIntent?.status === "succeeded") {
                // setting payment succeed
                setFormState(values);
                setAlert({ ...alert, msg: "Payment successfully done", severity: 'success', open: true })
                setPaymentSucceed(true);

            } else if (result.error?.code) {
                PaymentErrorDialog(result.error?.message || "Card Declined");
                return
            }


        } catch (error: any) {
            setSubmitting(false);
            // console.log("Error==>", error.toString());
            console.log("Error==>", error?.response);
            if (error?.response?.data?.message === "Error: An error occurred with our connection to Stripe.") {
                console.log('Retrying...');
                setFormResend(true);
                submitForm();
            }
        }
    }

    if (isPaymentSucceed) {
        return <PaymentAlert
            amount={amount} itsNo={formState.itsNo}
            name={`${formState.personTitle} ${formState.firstName} ${formState.lastName}`}
            title={pageTitle}
        />
    }

    return (
        <Formik initialValues={formInitialValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
            validate={handleValidateForm}
        >
            {({ isValid, isSubmitting, values: { units }, setErrors, errors, dirty, isValidating, handleChange }) => (
                <>
                    <Form style={s.formStyle()} >
                        <BackDrop open={isSubmitting} >
                            <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
                        </BackDrop>
                        {/* /////////////////////////////////////////   personal info /////////////////////////////////////// */}
                        <s.LabelWrap>
                            <span><AccountCircle fontSize='large' /></span> <Typography variant='h5' align='center' >Personal Details</Typography>
                        </s.LabelWrap>
                        <br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid xs={12} sm={2} item><Field required variant="outlined" component={TextField} select type='select' name={field.personTitle.name} label={field.personTitle.label} fullWidth
                                children={Object.values(Person).map((name, idx) => (
                                    <MenuItem key={idx} value={name} >{name}</MenuItem>
                                ))}
                            /></Grid>
                            <Grid xs={12} sm={5} item><Field required fullWidth component={TextField} type='text' name={field.firstName.name} label={field.firstName.label} variant="outlined" /></Grid>
                            <Grid xs={12} sm={5} item><Field required fullWidth component={TextField} type='text' name={field.lastName.name} label={field.lastName.label} variant="outlined" /></Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid item xs={12} sm={6} ><Field fullWidth required component={TextField} type='text' name={field.email.name} label={field.email.label} variant="outlined" /></Grid>
                            <Grid item xs={12} sm={6} ><Field fullWidth required component={TextField} type='text' onChange={(e) => { filterNumber({ e, handleChange }) }} name={field.itsNo.name} label={field.itsNo.label} variant="outlined" /></Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid xs={12} item ><Field required component={TextField} type='text' name={field.streetAddress.name} label={field.streetAddress.label} variant="outlined" fullWidth /></Grid>
                            <Grid xs={12} sm={6} item ><Field required component={TextField} type='text' name={field.city.name} label={field.city.label} variant="outlined" fullWidth /></Grid>
                            <Grid xs={12} sm={6} item ><Field required component={TextField} select type='select' name={field.country.name} label={field.country.label} variant="outlined" fullWidth children={
                                countries.map(({ name }, idx) => (
                                    <MenuItem key={idx} value={name} >{name}</MenuItem>
                                ))
                            } /></Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid item xs={12} sm={6} ><Field required component={TextField} type='text' variant="outlined" name={field.postCode.name} label={field.postCode.label} fullWidth /></Grid>
                            <Grid item xs={12} sm={6} ><Field required component={TextField} type='tel' onChange={(e) => { filterNumber({ e, handleChange, include: ['+'] }) }} variant="outlined" name={field.mobileNo.name} label={field.mobileNo.label} fullWidth /></Grid>
                        </Grid>
                        <br /><br />

                        {/* /////////////////////////////////////////   Payment info /////////////////////////////////////// */}
                        <s.LabelWrap>
                            <span><Payment fontSize='large' /></span> <Typography variant='h5' align='center' >Payment Details</Typography>
                        </s.LabelWrap>
                        <br /><br />

                        <Grid container spacing={2} className={`${classes.fieldSet}`} >
                            <Grid xs={12} sm={(units === "Other") ? 2 : 6} item ><Field required component={TextField} onChange={handleUnitChange(handleChange)} select type='select' name={field.units.name} label={field.units.label} variant="outlined" fullWidth children={
                                Object.entries(Units).map((name, idx) => {
                                    return <MenuItem key={idx} value={name[1]} >{name[0]}</MenuItem>
                                })
                            } />
                            </Grid>
                            {(units === "Other") && <Grid item xs={12} sm={5} ><Field required component={TextField} onChange={handleOtherUnitChange(handleChange)} placeholder="0.00" variant="outlined" type='number' name={field.otherUnits.name} label={field.otherUnits.label} fullWidth InputProps={{ startAdornment: <InputAdornment position="start">Units</InputAdornment> }} /></Grid>}
                            <Grid item xs={12} sm={(units === "Other") ? 5 : 6} >
                                <s.TotalAmount>
                                    <div>
                                        <div><Typography color='textSecondary' component='span' >GBP-£ </Typography><Typography component='span' >{amount.toFixed(2)}</Typography></div>
                                        <Typography color='textSecondary'>Amount</Typography>
                                    </div>
                                </s.TotalAmount>
                            </Grid>
                            <Grid xs={12} item ><Typography style={{ margin: "0 0 17px 5px" }} gutterBottom >Each unit for {pageTitle.split(' - ')[0]} is  £2.50.</Typography></Grid>

                            <Grid item xs={12} sm={6} ><MiTextField error={!!errors[field.cardNumber.name]} helperText={errors[field.cardNumber.name]} InputProps={{ inputComponent: StripeInput as any, inputProps: { component: CardNumberElement, setErrors, errors, fieldName: field.cardNumber.name } as StripeInputProps }} required variant="outlined" name={field.cardNumber.name} label={field.cardNumber.label} fullWidth InputLabelProps={{ shrink: true }} /></Grid>
                            <Grid item xs={12} sm={3} ><MiTextField error={!!errors[field.expiryDate.name]} helperText={errors[field.expiryDate.name]} InputProps={{ inputComponent: StripeInput as any, inputProps: { component: CardExpiryElement, setErrors, errors, fieldName: field.expiryDate.name } as StripeInputProps }} required variant="outlined" name={field.expiryDate.name} label={field.expiryDate.label} fullWidth InputLabelProps={{ shrink: true }} /></Grid>
                            <Grid item xs={12} sm={3} ><MiTextField error={!!errors[field.cvcNo.name]} helperText={errors[field.cvcNo.name]} InputProps={{ inputComponent: StripeInput as any, inputProps: { component: CardCvcElement, setErrors, errors, fieldName: field.cvcNo.name } as StripeInputProps }} required variant="outlined" name={field.cvcNo.name} label={field.cvcNo.label} fullWidth InputLabelProps={{ shrink: true }} /></Grid>
                        </Grid>
                        <br />

                        {/* /////////////////////////////////////////   Submit /////////////////////////////////////// */}
                        <Hidden smUp ><Grid container justify='space-between' spacing={2} >
                            <Grid item xs={12} sm={3} > <span></span> </Grid>
                            <Grid item container justify='center' alignItems='center' xs={12} sm={3} >
                                <Button disabled={(!isValid || isSubmitting || !dirty || isValidating)} color='primary' type='submit' >
                                    {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Proceed Payment</div>}
                                </Button>
                            </Grid>
                            {/* <br/> */}
                            <Grid item xs={12} container alignItems='center' justify='center' sm={3} >
                                <img width='80%' style={{ marginTop: "20px" }} src={require('../../assets/payment-security.jpg')} alt="power-by-stripe" />
                            </Grid>
                            <Grid item xs={12} container alignItems='center' justify='center' sm={3} >
                                <Box fontSize='14px' color='red' marginTop='20px' >
                                    <Box marginBottom={0.6} >
                                        If you intend to transfer funds using your mobile banking, kindly utilise the provided bank account details
                                    </Box>
                                    <p>
                                        <b>{pageTitle.split(' - ')[0]} Bank Account Details:</b> <br />
                                        ANJUMAN-E-BURHANI (LONDON)<br />
                                        Account No: 17136516<br />
                                        SC: 60 91 48
                                    </p>
                                </Box>
                            </Grid>
                        </Grid> </Hidden>
                        <Hidden xsDown >
                            <br /><br />
                            <Grid container justify='space-between' spacing={2} >
                                <Grid item xs={12} sm={3} container alignItems='flex-end' justify='flex-start'  >
                                    <img width='100%' src={require('../../assets/payment-security.jpg')} alt="power-by-stripe" />
                                </Grid>
                                <Grid item container justify='center' alignItems='center' xs={12} sm={3} >
                                    <Button disabled={(!isValid || isSubmitting || !dirty || isValidating)} color='primary' type='submit' >
                                        {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Proceed Payment</div>}
                                    </Button>
                                </Grid>
                                {/* <br/> */}
                                <Grid item xs={12} sm={4} >
                                    <Box fontSize='15px' color='red' >
                                        <Box marginBottom={0.6} >
                                            If you intend to transfer funds using your mobile banking, kindly utilise the provided bank account details
                                        </Box>
                                        <p>
                                            <b>{pageTitle.split(' - ')[0]} Bank Account Details:</b> <br />
                                            ANJUMAN-E-BURHANI (LONDON)<br />
                                            Account No: 17136516<br />
                                            SC: 60 91 48
                                        </p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Hidden>

                    </Form>

                    {/* <br /><br />
                    <Typography><b>NOTE:</b></Typography>
                    <Typography component='blockquote' >According to our Privacy Policy, we are informing you that the payment details you have placed to pay Qardan Hasana will be confidential between you and <a href="https://stripe.com/docs/security/stripe" target="blank" >Stripe</a> (payment gateway service). The website developer, Jama'at or any other concerned person have no access to your card details. Only you are responsible for protecting against unauthorized access to your card details and to your device. For more information kindly check out our <Link to={config.pages.PRIVACY_POLICY.path} >{config.pages.PRIVACY_POLICY.title}</Link>.</Typography> */}
                </>
            )}
        </Formik>
    )

}

export default PaymentFrom;
