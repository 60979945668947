import { getCookie } from '../helper';
import { ErrorDialog } from '../components/Alert';
import { navigate } from 'gatsby';
import { LOGIN_FORM_LINK as HS_LOGIN_FORM_LINK } from '../pages/husain-scheme/login'
import { LOGIN_PAGE_LINK as SBQH_LOGIN_PAGE_LINK } from '../pages/admin/login'


/**
 * 
 * @param authType 
 * @returns if auth_token is available will return false
 */
export const isAuthTokenExpired = (authType: "hs_auth_token" | "sbqh_auth_token" | "qardan_auth_token") => {
    if (!getCookie(authType)) {
        ErrorDialog("Authentication Error", "Session expired, login again", () => {
            authType === "hs_auth_token" && navigate(HS_LOGIN_FORM_LINK);
            authType === "sbqh_auth_token" && navigate(SBQH_LOGIN_PAGE_LINK);
        });
        return {
            "errors": [
                {
                    "data": null,
                    "errorInfo": null,
                    "message": "Session expired, login again"
                }
            ]
        }
    }
    return false
}