// https://material-ui.com/components/snackbars/
import React, { FC, memo } from 'react';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
const logo300px = require('../../assets/logo-gold.png');
import config from '../../../app-config.json';
import { getAmountPlusStripeFee } from '../../services/stripe';
import colors from '../../theme/colors';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export type AlertSeverity = "error" | "warning" | "info" | "success";

interface Props {
    open: boolean;
    severity: AlertSeverity;
    message: string;
    onClose: (event, reason: SnackbarCloseReason) => void;
    timeout?: number;
}

const CustomizedSnackbars: FC<Props> = ({ open, message, severity, timeout = 6000, onClose }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar open={open} autoHideDuration={timeout} onClose={onClose}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
                <Alert onClose={onClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
export default memo(CustomizedSnackbars);


export const PaymentConfirmDialog = async (amount: number, pageTitle: string, msgWithCharges: boolean = false) => {
    const res = await Swal.fire({
        title: `You're almost done`,
        html: `<p>Please confirm you would like to contribute Qardan Hasana of £${amount.toFixed(2)}` +
            ` towards ${pageTitle}? ${msgWithCharges ? `</p> <p>A total of £${(getAmountPlusStripeFee(amount) / 100).toFixed(2)}
             (including transaction charges) will be deducted from your card.</p>`: ""}`,
        imageUrl: logo300px,
        imageWidth: 90,
        imageAlt: 'logo',
        showCancelButton: true,
        confirmButtonColor: colors[6],
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, proceed'
    })
    // console.log(res);
    return res;
}

export const PaymentErrorDialog = async (message: string) => {
    await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        confirmButtonColor: colors[6],
        confirmButtonText: 'OK',
        footer: `<p> <a href="${config.pages.CONTACT.path}" >Contact us</a> to resolve your issue</p>`
    })
}

export const LoginFailDialog = async (message: string) => {
    await Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: message,
        confirmButtonColor: colors[6],
        confirmButtonText: 'OK',
    })
}

export const ErrorDialog = async (title: string, message: string, callback?: () => void) => {
    const res = await Swal.fire({
        icon: "error",
        title,
        text: message,
        confirmButtonColor: colors[6],
        confirmButtonText: 'OK',
    })
    callback && callback()
    return res;
}

export const WarningDialog = async (title: string, message: string, callback?: () => void) => {
    const res = await Swal.fire({
        icon: "warning",
        title,
        text: message,
        confirmButtonColor: colors[6],
        confirmButtonText: 'OK',
    })
    callback && callback()
    return res;
}

export const SuccessDialog = async (title: string, message: string, callback?: () => void) => {
    await Swal.fire({
        icon: "success",
        title,
        text: message,
        confirmButtonColor: colors[6],
        confirmButtonText: 'OK',
    })
    callback && callback()
}

export const ConfirmationDialog = async (options: { title: string, message: string, confirmBtnText?: string }, callback?: () => void) => {
    const res = await Swal.fire({
        title: options.title,
        text: options.message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colors[6],
        cancelButtonColor: '#d33',
        confirmButtonText: options.confirmBtnText || 'Yes, proceed'
    })
    callback && callback();
    return res;
}

export const InfoDialog = async (options: { title: string, message: string, confirmBtnText?: string }, callback?: () => void) => {
    const res = await Swal.fire({
        title: options.title,
        text: options.message,
        icon: 'info',
        iconColor:"#bd8c0e",
        confirmButtonColor: colors[6],
        confirmButtonText: options.confirmBtnText || 'Okay'
    })
    callback && callback();
    return res;
}