import React, { FC, useCallback, useState } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem, Typography, Grid, CircularProgress } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { Button } from '../index';
import * as s from './style';
import { countries } from './countries.json';
import { createNewAccount } from '../../services/husainSchemeNewAccount';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import { Link } from 'gatsby';
import Alert, { AlertSeverity } from '../Alert';
import BackDrop from '../BackDrop';
import { LOGIN_FORM_LINK } from '../../pages/husain-scheme/login';
import * as yup from 'yup';

enum Person { Bhai = "Bhai", Behen = "Behen", Mulla = "Mulla", Shaikh = "Shaikh" }
export enum FieldName {
    personTitle = "personTitle",
    firstName = "firstName",
    lastName = "lastName",
    email = "email",
    itsNo = "itsNo",
    streetAddress = "streetAddress",
    city = "city",
    country = "country",
    postCode = "postCode",
    mobileNo = "mobileNo",
}

export const field: { [P in keyof FormFields]: { name: string, label: string } } = {
    personTitle: { name: "personTitle", label: "Title" },
    firstName: { name: "firstName", label: "First Name" },
    lastName: { name: "lastName", label: "Last Name" },
    email: { name: "email", label: "Email" },
    itsNo: { name: "itsNo", label: "ITS.No" },
    streetAddress: { name: "streetAddress", label: "Street Address" },
    city: { name: "city", label: "City" },
    country: { name: "country", label: "Country" },
    postCode: { name: "postCode", label: "Post Code" },
    mobileNo: { name: "mobileNo", label: "Mobile.No" },
    nameAsPerBankAccount: { name: "nameAsPerBankAccount", label: "Name As Per Bank Account" }
}

export const schemaObject = {
    [FieldName.personTitle]: yup.mixed<keyof typeof Person>().required('Your title'),
    [FieldName.firstName]: yup.string().required('First Name required'),
    [FieldName.lastName]: yup.string().required('Last Name required'),
    [FieldName.email]: yup.string().required("Email Address required").email('Incorrect Email Format'),
    [FieldName.itsNo]: yup.string().required('ITS number required').length(8, "Invalid length"),
    [FieldName.streetAddress]: yup.string().required('Kindly place your street address'),
    [FieldName.city]: yup.string().required('City required'),
    [FieldName.country]: yup.string().required('Country required'),
    [FieldName.postCode]: yup.string().required('PostCode required'),
    [FieldName.mobileNo]: yup.string().required('Mobile number required'),
}

const formSchema = yup.object({
    ...schemaObject,
    nameAsPerBankAccount: yup.string().required('Name required')
})

export interface FormFields extends yup.InferType<typeof formSchema> { }

export const formInitialValues: FormFields = {
    personTitle: "" as "Bhai",
    firstName: "",
    lastName: "",
    email: "",
    itsNo: "",
    streetAddress: "",
    city: "",
    country: "United Kingdom",
    postCode: "",
    mobileNo: "+44",
    nameAsPerBankAccount: "",
}

const handleValidateForm = (values: FormFields) => {
    const error: { [P in keyof FormFields]?: string; } = {}
    // checking and verifying user's mobile number according to the country code 
    if (values.mobileNo) {
        const isPhoneNumValid = parsePhoneNumberFromString(values.mobileNo, "GB")?.isValid();
        if (!isPhoneNumValid) {
            error.mobileNo = 'Invalid mobile number'
        }
    }

    return error
}

//////////////////////////////////  component ////////////////////////////////
const UserDetails: FC<{ pageTitle: string }> = ({ pageTitle }) => {
    const classes = s.useStyles();
    const [isAccountCreated, setAccountCreated] = useState<boolean>(false);
    const [alert, setAlert] = useState<{ msg: string, open: boolean, severity: AlertSeverity }>({ msg: '', open: false, severity: "success" });

    const handleAlertClose = useCallback((event, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ ...alert, open: false });
    }, [alert.open]);


    const onSubmit = async (values: FormFields, { setSubmitting, resetForm }: FormikHelpers<FormFields>) => {
        console.log("Details received");
        const result = await createNewAccount(values);

        if (result.response) {
            // console.log(result);
            setSubmitting(false); resetForm();
            setAccountCreated(true)
        } else if (result.error) {
            // console.log(result.error.response?.data.message)
            setAlert({ ...alert, msg: result.error.response?.data.message || "Form submission error", severity: 'error', open: true })
            setSubmitting(false);
        }

    }

    if (isAccountCreated) {
        return (
            <div style={{ padding: "40px 20px" }} >
                <h2 style={{ fontFamily: 'Playball', fontSize: "37px" }} >Thank you for your submission.</h2>
                <br /><br />
                <h2 style={{ fontFamily: 'Playball', fontSize: "37px" }} >One of the Husain Scheme Khidmatguzar’s will be in touch soon.</h2>
                <br /><br />
                <h2 style={{ fontFamily: 'Playball', fontSize: "37px" }} >Shukran</h2>
                <br /><br />
                <h2 style={{ fontFamily: 'Playball', fontSize: "26px" }} >{pageTitle} Management Team</h2>
                <br /><br />
            </div>
        )
    }

    return (
        <Formik initialValues={formInitialValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
            validate={handleValidateForm}
        >
            {({ isValid, isSubmitting, handleChange }) => (
                <>
                    <Form style={s.formStyle()} >

                        <Alert message={alert.msg} severity={alert.severity} open={alert.open} onClose={handleAlertClose} />
                        <BackDrop open={isSubmitting} >
                            <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
                        </BackDrop>
                        {/* /////////////////////////////////////////   personal info /////////////////////////////////////// */}
                        <s.LabelWrap>
                            <span><AccountCircle fontSize='large' /></span> <Typography variant='h5' align='center' >Personal Details</Typography>
                        </s.LabelWrap>
                        <br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid xs={12} sm={2} item><Field required variant="outlined" component={TextField} select type='select' name={field.personTitle.name} label={field.personTitle.label} fullWidth
                                children={Object.values(Person).map((name, idx) => (
                                    <MenuItem key={idx} value={name} >{name}</MenuItem>
                                ))}
                            /></Grid>
                            <Grid xs={12} sm={5} item><Field required fullWidth component={TextField} type='text' name={field.firstName.name} label={field.firstName.label} variant="outlined" /></Grid>
                            <Grid xs={12} sm={5} item><Field required fullWidth component={TextField} type='text' name={field.lastName.name} label={field.lastName.label} variant="outlined" /></Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid item xs={12} sm={6} ><Field fullWidth required component={TextField} type='text' name={field.email.name} label={field.email.label} variant="outlined" /></Grid>
                            <Grid item xs={12} sm={6} ><Field fullWidth required component={TextField} type='text' onChange={(e) => { filterNumber({ e, handleChange }) }} name={field.itsNo.name} label={field.itsNo.label} variant="outlined" /></Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid xs={12} item ><Field required component={TextField} type='text' name={field.streetAddress.name} label={field.streetAddress.label} variant="outlined" fullWidth /></Grid>
                            <Grid xs={12} sm={6} item ><Field required component={TextField} type='text' name={field.city.name} label={field.city.label} variant="outlined" fullWidth /></Grid>
                            <Grid xs={12} sm={6} item ><Field required component={TextField} select type='select' name={field.country.name} label={field.country.label} variant="outlined" fullWidth children={
                                countries.map(({ name }, idx) => (
                                    <MenuItem key={idx} value={name} >{name}</MenuItem>
                                ))
                            } /></Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid item xs={12} sm={6} ><Field required component={TextField} type='text' variant="outlined" name={field.postCode.name} label={field.postCode.label} fullWidth /></Grid>
                            <Grid item xs={12} sm={6} ><Field required component={TextField} type='tel' onChange={(e) => { filterNumber({ e, handleChange, include: ["+"] }) }} variant="outlined" name={field.mobileNo.name} label={field.mobileNo.label} fullWidth /></Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={1} className={`${classes.fieldSet}`} >
                            <Grid xs={12} sm={6} item><Field required fullWidth component={TextField} type='text' name={field.nameAsPerBankAccount.name} label={field.nameAsPerBankAccount.label} variant="outlined" /></Grid>
                        </Grid>
                        <br /><br />

                        {/* /////////////////////////////////////////   Submit /////////////////////////////////////// */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button disabled={(!isValid || isSubmitting)} color='primary' type="submit" >
                                {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Create Account</div>}
                            </Button>
                        </div>

                    </Form>
                    <br /><br />
                    <Typography align='center' >Already have an account? <Link to={LOGIN_FORM_LINK} >Click here</Link></Typography>
                </>
            )}
        </Formik>
    )

}

export default UserDetails;

/**
 * 
 * @param param0 e = accepts change event of input element
 * @param param1 handleChange = accepts the onChange handler
 * @param param2 includs = accepts an array of characters that need to be include
 * @param param3 formatting = accepts boolean, if true than if will return "1,000" else "1000"
 *                      number formatting can only be possible if the include array is empty
 */
const numFrmt = new Intl.NumberFormat();
export const filterNumber = ({ e, handleChange, include = [], formating = false }: {
    e: React.ChangeEvent<HTMLInputElement>, handleChange: (e: React.ChangeEvent) => void, include?: string[], formating?: boolean,
}) => {
    const filterChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ...include];

    let value: number | string = e.target.value.split('').filter((chr) => filterChars.includes(chr)).join('');

    if (formating) {
        const _num = Number(value);
        if (isNaN(_num)) { value = _num }
        else { value = numFrmt.format(_num) }
    }

    e.target.value = value.toString();
    handleChange(e)
}