import { CardNumberElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements, PaymentMethodCreateParams } from "@stripe/stripe-js";
import { MetadataParam, PaymentIntent, StripeError } from '@stripe/stripe-js';
import { BodyResponse } from '../../../functions/src/checkout/checkout';
import axios from 'axios';
import { endpoints } from '../../app-config.json';

type ResponseData = {
    data: BodyResponse
}

export interface BodyInput {
    metadata?: MetadataParam,
    amount?: number,
    scheme: string, // husain or mohammedi

}

export const proceedPayment = async (
    stripe?: Stripe | null,
    elements?: StripeElements | null,
    amount?: BodyInput['amount'] | null,
    scheme?: BodyInput['scheme'], // husain or mohammedi
    billingDetails?: PaymentMethodCreateParams['billing_details'],
    metadata?: BodyInput['metadata'],
): Promise<{ paymentIntent?: PaymentIntent; error?: StripeError }> => {

    if (!stripe || !elements || !amount || !metadata) {
        console.log('Cannot process the payment');
        return {};
    }

    console.log('amount', amount);

    // getting a payment secret
    const url = endpoints.checkout;
    const { data } = await axios.post<{}, ResponseData>(url,
        {
            ...{ amount, metadata, scheme } as BodyInput
        }, {
        headers: {
            "Content-Type": "application/json"
        }
    });
    console.log("DAta = ", data.client_secret);

    // paying
    const result = await stripe?.confirmCardPayment(data.client_secret!, {
        payment_method: {
            card: elements?.getElement(CardNumberElement)!,
            billing_details: billingDetails,
        }
    })

    console.log("Result = ", result);
    // console.log("Payment succeed");
    return result;

}


export const getAmountPlusStripeFee = (amount: number) => {
    /* takes actual amount and returns the amount plus transaction free 
     this method return only interger because strip takes 1.00 as 100 */
    const newAmount = ((amount / 100) * 1.2) + 0.20 + amount;
    return Math.round(newAmount * 100);
}

export const getStripeAmount = (amount: number) => {
    /* takes actual amount and returns the amount multiple of 100
     because strip takes 1.00 as 100 */
    return amount * 100
}

export const getStripeFee = (amount: number) => {
    /* take actual amount and returns the transaction free 1.2% + 20p */
    return ((amount / 100) * 1.2) + 0.20;
}