import React, { FC, ReactNode, memo } from 'react';
import _colors from '../../theme/colors';
import { StyledBtn, colors, StyledBtn1 } from './style';

export { StyledBtn, StyledBtn1 };

////////////////////////////////////////// Type defs  ///////////////////////////////////////////////////
export type VarientType = "contained" | "outline";
export type SizeType = "small" | "medium" | "large";

export interface BtnStyle {
    variant: VarientType,
    color: keyof typeof colors,
    size: SizeType,
}
export interface ButtonProps extends Partial<BtnStyle> {
    children?: ReactNode;
    disabled?: boolean,
}

/////////////////////////////////////////// Functional components ////////////////////////////////////////
const Button: FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    color = "default",
    variant = "contained",
    disabled = false,
    size = 'medium',
    ...other

}) => {

    return (
        <StyledBtn {...{ color, variant, disabled, size, ...other }} >
            <div >{children}</div>
        </StyledBtn>
    )
}


/// exports
export default memo(Button);