import React, { FC, useEffect, useState } from 'react';
import { BackDrop, PageLayout, Table, Button } from '../../';
import config from '../../../../app-config.json';
import { navigate } from 'gatsby';
import { BodyWrap } from "../../../style.pages/index.style";
import { Grid, MenuItem, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { LOGIN_FORM_LINK } from '../../../pages/husain-scheme/login';
import { screenWidths } from '../../../theme/css-generators';
import moment from 'moment';
import { FundsWithdrawRequestForm, ChangePasswordForm } from '../../../components/Form';
import { Props as ChangePasswordFormProps } from '../../../components/Form/ChangePassword';
import { getHsPortalDetails, getAllHStransactions, logoutFromHsPortal, getHSuserInfo, changeHSuserPassword, getHsUserBankAccounts, getHsUserChildAccessToken } from '../../../reduxStore/slices/hsPortalSlice';
import { Response } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import styled from 'styled-components';
import { formatNum, TransactionDescription } from '../../../helper';
import UpdateDetailsForm from './UpdateDetailsForm';
import TempPassLoginScreen from './TempPassLoginScreen';
import BankAccountsList from './BankAccountsList';

// const PAGE_TITLE = config.pages.HUSAIN_SCHEME.routes.account.title;
// const PAGE_PATH = config.pages.HUSAIN_SCHEME.routes.account.path;
const PARENT_PAGE_TITLE = config.pages.HUSAIN_SCHEME.title
// const HS_EMAIL = config.pages.HUSAIN_SCHEME.details.email
// const PARENT_PAGE_PATH = config.pages.HUSAIN_SCHEME.path
// export const HS_ACCOUNT_PAGE_LINK = `${PARENT_PAGE_PATH}${PAGE_PATH}`;

interface Props {
    // authToken: string;
}

const AccountApp: FC<Props> = ({ }) => {
    return (
        <PageLayout>
            <BodyWrap>
                <Main />
            </BodyWrap>
        </PageLayout>
    )
}

export default AccountApp;


const Main: FC<{}> = ({ }) => {
    const dispatch = useAppDispatch();
    const { isAuthenticated, accountBalance, authToken, userDetails, transactionDetails, fetchingStatus, } = getHsPortalDetails();
    const isLoggedInWithTempPassword = useAppSelector(s => s.hsPortal.isLoggedInWithTempPassword);
    // const { data, filterOptions, totalCount } = useAppSelector(s => s.hsPortal.transactionDetails);
    const widthUnder500 = useMediaQuery(`(min-width: ${screenWidths._500px})`);
    const widthUnder667 = useMediaQuery(`(min-width: 667px)`);
    const [isPaymentRequestSent, setPaymentRequestSent] = useState<boolean>(false);

    const handleLogout = () => { dispatch(logoutFromHsPortal()); navigate(LOGIN_FORM_LINK) }

    const handleChangePassword: ChangePasswordFormProps['handleSubmit'] = async (values) => {
        const { err, res } = await new Promise<{ err?: any, res?: Response }>((resolve) => {
            dispatch(changeHSuserPassword({
                oldPassword: values.oldPassword, newPassword: values.newConfirmPassword, callback: (err, res) => {
                    err && resolve({ err })
                    res && resolve({ res })
                }
            }))
        })

        if (res) {
            return { successMsg: res.message || "Password has been changed successfully" }
        } else {
            console.log("ChangePassword_Error", err);
            return { errorMsg: err.errors[0].message }
        }
    }

    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        const { filterOptions, } = transactionDetails;
        // console.log("pageNumber", pageNumber, "pageSize", pageSize)
        dispatch(getAllHStransactions({ ...filterOptions, pageNumber, pageSize }))
    }

    const loadChildUserToken = (childUserId: string) => {
        dispatch(getHsUserChildAccessToken({ childUserId }))
    }

    useEffect(() => {
        if (userDetails && authToken) {
            dispatch(getAllHStransactions(transactionDetails.filterOptions))
            dispatch(getHSuserInfo({ userId: userDetails.id }))
            dispatch(getHsUserBankAccounts())
        }
    }, [])

    if (fetchingStatus.getHSuserInfo || !transactionDetails.data || !userDetails) {
        return <BackDrop open={true} >
            <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
        </BackDrop>
    }

    if (isPaymentRequestSent) {
        return (
            <AfterRequestSent  >
                <h2 >Your withdrawal request has been received, and the funds will be processed within 48 hours.</h2>
                <h2 >Shukran</h2>
                <p >{config.pages.HUSAIN_SCHEME.title} Management Team</p>
                <Button onClick={() => { setPaymentRequestSent(false) }} variant='contained' size='medium' color='primary' >
                    Back To Account Details
                </Button>
            </AfterRequestSent>
        )
    }

    return (
        <>
            <Grid>
                <Grid container justify='space-between' alignItems='center' >
                    <Typography variant='h2' color='inherit' >{PARENT_PAGE_TITLE} Account </Typography>
                    {widthUnder500 ?
                        <Button color='primary' onClick={handleLogout} >SIGN OUT</Button> :
                        <Button color='primary' size='small' onClick={handleLogout} >SIGN OUT</Button>
                    }
                </Grid >
                {!widthUnder667 && <br />}
                <hr />
                <br />

                {/* this change password button is displayed here to change the temporary password */}
                {isLoggedInWithTempPassword &&
                    // <Grid container justify='flex-start' >
                    //     <ChangePasswordForm defaultOpen={true} handleSubmit={handleChangePassword} />
                    // </Grid>
                    <TempPassLoginScreen />
                }

                {/*Once the user has changed their temporary password then he can view the other components*/}
                {!isLoggedInWithTempPassword &&
                    <>
                        <Typography gutterBottom variant='h6' component='p' ><b>Name:</b> {userDetails.first_name} {userDetails.last_name}</Typography>
                        <Typography gutterBottom variant='h6' component='p' ><b>ITS No:</b> {userDetails.its_no || <span style={{ color: 'grey' }}>Please update your ITS Number</span>}</Typography>
                        <Typography gutterBottom variant='h6' component='p' ><b>Email:</b> {userDetails.email || <span style={{ color: 'grey' }}>Please update your email address</span>}</Typography>
                        <Typography gutterBottom variant='h6' component='p' ><b>Mobile No:</b> {userDetails.phone_no || <span style={{ color: 'grey' }}>Please update your mobile number</span>}</Typography>

                        {!!userDetails.child_users?.length &&
                            <Typography gutterBottom variant='h6' component='p' >
                                <b>Child Accounts: </b>
                                {/* {userDetails.child_users.map((user_id, idx) => {
                                    return <Typography style={{ cursor: "pointer" }} onClick={() => loadChildUserToken(user_id)} key={idx}>{user_id.toUpperCase()}</Typography>
                                })} */}
                                <TextField style={{ width: '140px' }} size='small' variant="outlined" select type='select' // onChange={(e) => loadChildUserToken(e.target.value.toLowerCase())}
                                    children={userDetails.child_users.map((user_id, idx) => (
                                        <MenuItem onClick={(e) => loadChildUserToken(user_id.toLowerCase())} key={idx} value={user_id} >{user_id.toUpperCase()}</MenuItem>
                                    ))}
                                />
                            </Typography>}

                        <br />

                        <Grid container justify='flex-start' spacing={2} >
                            <Grid item><ChangePasswordForm handleSubmit={handleChangePassword} /></Grid>
                            <Grid item><UpdateDetailsForm /></Grid>
                            <Grid item><BankAccountsList /></Grid>
                        </Grid>
                        <br /><br />
                        <Typography align='right' gutterBottom variant='h5' style={{ fontSize: '29px' }} component='p' ><b>Account No:</b> {userDetails.id.toLocaleUpperCase()}</Typography>
                        <Typography align='right' gutterBottom variant='h5' style={{ fontSize: '29px' }} component='p' ><b>Current Balance:</b> £{formatNum(accountBalance) || 0}</Typography>
                        <Grid container justify='flex-end' >
                            <FundsWithdrawRequestForm
                                maxAmount={accountBalance}
                                onSuccessSubmit={() => { setPaymentRequestSent(true) }}
                            />
                        </Grid>

                        <br />
                        <Table
                            label={
                                <Typography variant='h4' align='center' >All transactions</Typography>
                            }
                            columns={[
                                // { id: "transaction_id", align: "left", label: "ID" }, // 01 Apr 21
                                { id: "date", align: "left", label: "Date", format: (val: string) => val.length > 24 ? val : moment(val).format("DD MMM YY") },
                                { id: "amount", align: "center", label: "Money in", format: (val: number) => val >= 0 ? <p style={{ color: 'green' }} >£{formatNum(val)}</p> : "" },
                                { id: "amount", align: "center", label: "Money out", format: (val: number) => val < 0 ? <p style={{ color: 'red' }} >£{formatNum(Math.abs(val))}</p> : "" },
                                { id: "description", align: "center", label: "Description", format: (val: number) => TransactionDescription[val] || val },
                            ]}
                            rows={transactionDetails.data || []}
                            totalRows={transactionDetails.totalCount}
                            pageNumber={transactionDetails.filterOptions.pageNumber! - 1}
                            onChangePageNo={handleChangePage}
                            onChangeRowsPerPage={handleChangePage}
                            dataFetchingAsync={true}
                            loading={fetchingStatus.getAllHStransactions}
                            emptyRowMsg={<Grid >
                                <br /><br /><br />
                                <Typography variant='h4' align='center' color='textSecondary' >There are no transactions to display</Typography>
                                <br /><br /><br />
                            </Grid>}
                        />
                    </>}

            </Grid>
        </>
    )
}


const AfterRequestSent = styled(Grid)`
    font-family: "Playball";
    >h2{ font-size: 37px; margin-bottom: 35px; }
    >p{ font-size: 26px; margin-bottom: 35px; }

    @media (max-width: ${screenWidths._500px}){
        >h2{ font-size: 26px; margin-bottom: 20px; }
        >p{ font-size: 18px; margin-bottom: 20px; }
    }
`
