import React, { FC, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SwipeableDrawer, Collapse, List, ListItem, ListSubheader, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore, AccountCircle, Home, ListAlt, Contacts, Info } from '@material-ui/icons';
import { Gift, CursorDefaultClick } from 'mdi-material-ui';
const donerIcon = require('../../assets/icons/donation.svg');
// import Divider from '@material-ui/core/Divider';
import { paymentForm, schemeNames, signInLinks } from "./";
import config from '../../../app-config.json';
import { navigate } from 'gatsby';
import colors from '../../theme/colors';

const useStyles = makeStyles((theme: Theme) => createStyles({
    mainList: { width: "340px" },
    nested: { paddingLeft: theme.spacing(4), },
    collapse: { backgroundColor: "whitesmoke", },
    listItemIcon: { width: "20px", color: colors[6] },
}));

export interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const LeftDrawer: FC<Props> = ({ open, setOpen }) => {

    const classes = useStyles();
    const [expand, setExpand] = useState({ schemes: false, contributeQardan: false, signIn: false })

    const handleClickOnExpand = (listName: "schemes" | "contributeQardan" | "signIn") => () => {
        setExpand({ ...expand, [listName]: !expand[listName] });
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) { setOpen(open) }
        else { setOpen(open) }
    };

    return (
        <div >
            <SwipeableDrawer
                anchor={"left"}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <List className={classes.mainList}
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {/* <Link to='/' ><img src={logo300px} /></Link> */}
                            {/* <Typography variant='h3' align='center' >SBQH</Typography> */}
                        </ListSubheader>
                    }
                >
                    <ListItem button onClick={() => { setOpen(false); navigate(config.pages.HOME.path) }} >
                        <ListItemIcon className={classes.listItemIcon}  ><Home color='inherit' /></ListItemIcon>
                        <ListItemText primary={config.pages.HOME.title} />
                    </ListItem>

                    <ListItem button onClick={handleClickOnExpand("schemes")}>
                        <ListItemIcon className={classes.listItemIcon}  ><ListAlt color='inherit' /></ListItemIcon>
                        <ListItemText primary="Schemes" />
                        {expand.schemes ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse className={classes.collapse} in={expand.schemes} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {schemeNames.map((name, idx) => {
                                return <ListItem key={idx} button onClick={() => { setOpen(false); navigate(name.path) }} className={classes.nested}>
                                    <ListItemText primary={name.title} />
                                </ListItem>
                            })}
                        </List>
                    </Collapse>

                    <ListItem button onClick={() => { setOpen(false); navigate(config.pages.CONTACT.path) }} >
                        <ListItemIcon className={classes.listItemIcon}  ><Contacts color='inherit' /></ListItemIcon>
                        <ListItemText primary={config.pages.CONTACT.title} />
                    </ListItem>
                    <ListItem button onClick={() => { setOpen(false); navigate(config.pages.ABOUT_US.path) }} >
                        <ListItemIcon className={classes.listItemIcon}  ><Info color='inherit' /></ListItemIcon>
                        <ListItemText primary={config.pages.ABOUT_US.title} />
                    </ListItem>

                    <ListItem button onClick={handleClickOnExpand("contributeQardan")}>
                        <ListItemIcon className={classes.listItemIcon}  >
                            {/* <Gift color='inherit' /> */}
                            <img style={{}} width='22' src={donerIcon} alt="donate-icon" />
                        </ListItemIcon>
                        <ListItemText primary="Contribute Qardan Hasana" />
                        {expand.contributeQardan ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse className={classes.collapse} in={expand.contributeQardan} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {paymentForm.map((name, idx) => {
                                return <ListItem key={idx} button onClick={() => { setOpen(false); navigate(name.path) }} className={classes.nested}>
                                    <ListItemText primary={name.title} />
                                </ListItem>
                            })}
                        </List>
                    </Collapse>

                    <ListItem button onClick={() => { setOpen(false); navigate(config.pages.APPLY_QARDAN_HASANA.path) }} >
                        <ListItemIcon className={classes.listItemIcon}  ><CursorDefaultClick color='inherit' /></ListItemIcon>
                        <ListItemText primary={config.pages.APPLY_QARDAN_HASANA.title} />
                    </ListItem>

                    <ListItem button onClick={handleClickOnExpand("signIn")}>
                        <ListItemIcon className={classes.listItemIcon}  >
                            <AccountCircle color='inherit' />
                        </ListItemIcon>
                        <ListItemText primary="Sign In" />
                        {expand.signIn ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse className={classes.collapse} in={expand.signIn} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {signInLinks.map((name, idx) => {
                                return <ListItem key={idx} button onClick={() => { setOpen(false); navigate(name.path) }} className={classes.nested}>
                                    <ListItemText primary={name.title} />
                                </ListItem>
                            })}
                        </List>
                    </Collapse>

                </List>
            </SwipeableDrawer>
        </div>
    );
}

export default LeftDrawer;