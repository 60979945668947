import React, { FC, useState } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
// import { LOGIN_FORM_LINK } from '../../pages/husain-scheme/login';
import { Link } from 'gatsby';
import { Dialog, Grid, CircularProgress, Typography } from '@material-ui/core';
import * as yup from 'yup';
import * as s from './style';
import { TextField } from 'formik-material-ui';
import { Button } from '..';
// import { hsTmNumberRegex } from './HSportalLoginForm';
import { SendLoginIssue } from '../../graphql/mutations';
import { ErrorDialog, SuccessDialog } from '../Alert';
import { hsTmNumberRegex, qardanFileNumberRegex } from '../../helper';


const formSchema = yup.object({
    name: yup.string().required("Required"),
    hsUserId: yup.string().matches(hsTmNumberRegex, { message: "Invalid format" }),
    qardanApplicantId: yup.string().matches(qardanFileNumberRegex, { message: "Invalid format" }),
    message: yup.string().required('Required')
});

export interface FormFields extends yup.InferType<typeof formSchema> { }

const formInitialValues: FormFields = {
    name: "",
    hsUserId: "",
    qardanApplicantId: "",
    message: "",
}

const SigningTroubleMsgForm: FC<{ portalType: "HS" | "Qardan" }> = ({ portalType }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    const handleSigningTroubleMsg = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        handleClickOpen()
        // console.log('click')
    }

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        // console.log(values);
        const { hsUserId, qardanApplicantId, message, name } = values;
        try {
            const res = await SendLoginIssue({ hstmNumber: hsUserId, fileNumber: qardanApplicantId, name, message });
            handleClose()
            await SuccessDialog("Message Sent", "Your message has been successfully sent. One of the Husain Scheme Khidmatguzar’s will be in touch soon.");
        } catch (error) {
            await ErrorDialog("Request Failed", (error as any).errors[0].message);

        }
    }

    return (
        <>
            <Link onClick={handleSigningTroubleMsg} to={'/'} >Click here</Link>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
                <Formik initialValues={formInitialValues}
                    validationSchema={formSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form style={{ ...s.formStyle(), }} >
                            {/* ///////////////////////////////////////// fields /////////////////////////////////////// */}
                            <s.LabelWrap style={{ padding: "10px" }} >
                                <Typography variant='h5' align='center' >Login Issue</Typography>
                            </s.LabelWrap>
                            <br />
                            <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                                <Grid item container alignItems='center' >
                                    <Field required fullWidth component={TextField} name='name' label="Name" type='text' variant='outlined' />
                                </Grid>
                                <br />
                                <Grid item container alignItems='center' >
                                    {portalType === "HS" ?
                                        <Field required fullWidth component={TextField} name='hsUserId' label="HS/TM Number" type='text' variant='outlined' /> :
                                        portalType === "Qardan" ?
                                            <Field required fullWidth component={TextField} name='qardanApplicantId' label="File Number" type='text' variant='outlined' /> :
                                            null
                                    }
                                </Grid>
                                <br />
                                <Grid item container alignItems='center' >
                                    <Field required fullWidth component={TextField} name='message' label="Message" placeholder='Describe your issue' multiline rows={3} type='text' variant='outlined' />
                                </Grid>
                                <br />
                                <Grid item container justify='center' >
                                    <Button
                                        variant='contained' size='medium'
                                        disabled={isSubmitting} color='primary' type="submit" >
                                        {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Send</div>}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    )
}

export default SigningTroubleMsgForm;
