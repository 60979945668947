import { CSSProperties } from "react";
import styled from "styled-components";
import { displayFlex } from '../theme/css-generators';

export default styled.div<{ open?: boolean, display?: CSSProperties['display'] }>`
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    ${({ display }) => display ? display : displayFlex({})};
    position: fixed;
    top:0; left: 0;
    z-index: 1000;
    background-color: rgb(0,0,0,.4);
    ${({ open }) => open ? "" : `display:none;`}
`;
