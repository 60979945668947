import React, { FC } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import * as s from './style';
import { Button } from '../';
import { filterNumber, schemaObject } from './UserDetails';
import { hsTmNumberRegex } from '../../helper';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

const formSchema = yup.object({
    id: yup.string().matches(hsTmNumberRegex, { message: "Invalid format" }),
    first_name: yup.string().required("Required"),
    last_name: yup.string().required('Required'),
    its_no: schemaObject.itsNo.required('Required'),
    email: yup.string().email("Incorrect email format").required('Required'),
    phone_no: yup.string().required('Required'),
})

export interface FormFields extends yup.InferType<typeof formSchema> { }

const formInitialValues: FormFields = {
    id: "",
    first_name: "",
    last_name: "",
    its_no: "",
    email: "",
    phone_no: "",
}

interface HSaccountFormProps {
    initialValues?: Partial<FormFields>;
    action: "ADD" | "UPDATE";
    handleSubmit: (values: FormFields) => Promise<void>;
    hideFields?: (keyof FormFields)[]
}
const HSaccountForm: FC<HSaccountFormProps> = ({ initialValues = {}, action, handleSubmit, hideFields }) => {

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        // console.log(values);
        await handleSubmit(values)
    }

    const handleValidation = (values: FormFields) => {
        const error: { [P in keyof FormFields]?: string; } = {};
        if (action === "ADD" && !values.id) {
            error.id = "Required";
        }
        if (values.phone_no) {
            const isPhoneNumValid = parsePhoneNumberFromString(values.phone_no, "GB")?.isValid();
            if (!isPhoneNumValid) {
                error.phone_no = 'Invalid mobile number'
                if (!values.phone_no.includes("+")) {
                    error.phone_no = 'Invalid mobile number, please also add country code'
                }
            }
        }
        // if (action === "ADD" && !values.its_no) {
        //     error.its_no = "Required";
        // }
        // if (action === "ADD" && !values.email) {
        //     error.email = "Required";
        // }
        // if (action === "ADD" && !values.phone_no) {
        //     error.phone_no = "Required";
        // }
        return error;
    }

    return (
        <Formik initialValues={{ ...formInitialValues, ...initialValues }}
            validationSchema={formSchema}
            onSubmit={onSubmit}
            validate={handleValidation}
        >
            {({ isSubmitting, handleChange }) => (
                <Form style={action === "ADD" ? { ...s.formStyle(), } : undefined} >
                    {/* ///////////////////////////////////////// fields /////////////////////////////////////// */}
                    <s.LabelWrap>
                        <Typography variant='h5' align='center' >{action === "ADD" ? "Add New Account" : "Update Account Details"}</Typography>
                    </s.LabelWrap>
                    <br />
                    <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                        {action === "ADD" && !hideFields?.includes("id") &&
                            <Grid item container alignItems='center' >
                                <Field fullWidth component={TextField} name='id' label="Account ID" type='text' variant='outlined' />
                            </Grid>}
                        <br />
                        {!hideFields?.includes("first_name") &&
                            <Grid item container alignItems='center' style={{ marginBottom: "15px" }} >
                                <Field fullWidth component={TextField} name='first_name' label="First Name" type='text' variant='outlined' />
                            </Grid>}
                        {/* <br /> */}
                        {!hideFields?.includes("last_name") &&
                            <Grid item container alignItems='center' style={{ marginBottom: "15px" }} >
                                <Field fullWidth component={TextField} name='last_name' label="Last Name" type='text' variant='outlined' />
                            </Grid>}
                        {/* <br /> */}
                        {!hideFields?.includes("its_no") &&
                            <Grid item container alignItems='center' style={{ marginBottom: "15px" }} >
                                <Field fullWidth component={TextField} onChange={(e) => { filterNumber({ e, handleChange }) }} name='its_no' label="ITS.No" type='text' variant='outlined' />
                            </Grid>}
                        {/* <br /> */}
                        {!hideFields?.includes("email") &&
                            <Grid item container alignItems='center' style={{ marginBottom: "15px" }} >
                                <Field fullWidth component={TextField} name='email' label="Email" type='text' variant='outlined' />
                            </Grid>}
                        {/* <br /> */}
                        {!hideFields?.includes("phone_no") &&
                            <Grid item container alignItems='center' style={{ marginBottom: "15px" }}>
                                <Field fullWidth component={TextField} onChange={(e) => { filterNumber({ e, handleChange, include: ['+'] }) }} name='phone_no' label="Mobile No" type='tel' variant='outlined' />
                            </Grid>}
                        {/* <br /> */}
                        <Grid item container justify='center' >
                            <Button
                                variant='contained' size='medium'
                                disabled={isSubmitting} color='primary' type="submit" >
                                {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>{action === "ADD" ? "Add" : "Update"}</div>}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )
            }
        </Formik >

    )
}

export default HSaccountForm;
