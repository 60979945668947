

export default {
    1: "#edc7b7",
    2: "#eee2dc",
    3: "#bab2b5",
    4: "#123c69",
    5: "#ac3b61",
    6: "#BD8C0E",
    7: "#EDBB4A"
}
