import styled from 'styled-components';
import _colors from '../../theme/colors';
import { screenWidths } from '../../theme/css-generators';
import { BtnStyle, SizeType, VarientType } from './index';


export const transparentColor = 'transparent';
export const disableColor = '#eee';
export const disableFont = '#bbb';
export const borderRadius = '5px';
export const shadow = { normal: "2px 2px 5px rgba(0,0,0,0.3)", active: "0px 0px 1px rgba(0,0,0,0.3)" }
export const colors = {
    default: _colors[1],
    primary: _colors[6],
    secondary: _colors[4],
}

export const StyledBtn = styled.button.attrs((props: BtnStyle) => ({
    _color: props.variant === "outline" ? transparentColor : colors[props.color],
    fontColor: props.variant === "outline" ? colors[props.color] : props.variant === "contained" ? "white" : "black",
    padding: '4px',
    _size: props.size === "small" ? "0px" : props.size === "medium" ? "2.5px" : "5px",
})) <BtnStyle>`
    appearance: none;
    user-select: none;
    cursor: ${({ disabled }) => disabled && 'not-allowed'};
    background-color: ${({ _color, disabled }) => disabled ? disableColor : _color};
    color: ${({ fontColor, color, disabled }) => disabled ? disableFont : color === "default" ? "black" : fontColor};
    border: 1px solid ${({ _color, variant, fontColor, disabled }) => disabled ? "rgba(0,0,0,0)" : variant === "outline" ? fontColor : _color};
    font-size: ${({ size }) => size === "small" ? '14px' : size === "medium" ? '16px' : '22px'};
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: ${borderRadius};
    outline: none;
    transition: 0.15s ease-in-out;
    box-shadow: ${({ disabled }) => disabled ? 'none' : shadow.normal};
    > div{
            appearance: none;
            padding: ${({ padding, _size }) => `calc(${padding} + ${_size})    calc(calc(${padding} + ${_size}) + 3px)   `};
            transition: inherit;
            border-radius: inherit;
    }
    &:hover {
        padding: 0;
        > div{
            background-color: ${({ disabled }) => disabled ? disableColor : "rgba(0,0,0,0.1)"};
        }
    }
    &:focus {
        border: ${({ disabled }) => disabled ? 'none' : `1px solid ${"rgb(170,170,170)"}`};
    }
    &:active {
        box-shadow: ${({ disabled }) => disabled ? "none" : shadow.active};
        > div{
            background-color: ${({ disabled }) => disabled ? disableColor : "rgba(255,255,255,0.1)"};
        }
    }
`



export const StyledBtn1 = styled.button`
    /* width: 100%; height: 150px; */
    /* margin: 20px auto; */
    font-size: 25px;
    padding: 15px;
    background-color: rgba(139, 102, 9, 0.288);
    border: 10px solid #b9880c;
    border-radius: 30px;
    color: ${colors[4]};
    transition: 0.15s ease-in-out;
    :hover{ background-color: rgba(173, 137, 46, 0.616);}
    :active{background-color: rgba(218, 176, 72, 0.808);}

    @media (max-width: ${screenWidths._500px}){
        font-size: 20px;
        padding: 10px;
    }
`