import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllQardanInstallments, GetQardanApplicantInfo, GetAllQardanApplicants, LoginToSBQHPortal, CheckTokenExpirySBQHuser, GetAllWithdrawalRequests, GetAllHSusers, GetAllSBQHusers, GetAllHStransactions, GetAllHSaccountsWithBalances, GetAllDormantHSaccounts, GetHSuserInfo, VerifySBQHSecuritycode, GetQardanApplicantsWithOutstandingBalances } from '../../../graphql/queries';
import { ChangeQardanApplicantPasswordBySBQHuser, AddQardanApplicant, ChangeQardanApplicantDetails, DeleteQardanApplicant, ChangeHSuserDetails, ChangeSBQHusersAccess, ChangeHSuserPasswordBySBQHuser, AddTransaction, UpdateTransaction, FulfillPaymentWithdrawlRequests, AddSBQHuser, DeleteSBQHuser, DeleteTransaction, AddQardanInstallment, DeleteQardanInstallment, } from '../../../graphql/mutations';
import { SBQHloginResponse, ChangeSbqHusersAccessInput, SbqHuser } from '../../../graphql/types';
import { GetAllHSusersFilter, Response, WithdrawalRequestStatus, MutationAddTransactionArgs, GetAllSbqHusersFilter, FilterOptions, UserType, MutationAddSbqHuserArgs, MutationDeleteSbqHuserArgs, QueryGetAllHSaccountsWithBalancesArgs, QueryGetAllDormantHSaccountsArgs, MutationDeleteTransactionArgs, UpdateQardanApplicantInput, MutationDeleteQardanApplicantArgs, MutationAddQardanApplicantArgs, QardanApplicant, MutationAddQardanInstallmentArgs, MutationDeleteQardanInstallmentArgs, QardanInstallment, QueryGetQardanApplicantsWithOutstandingBalancesArgs, MutationChangeHSuserPasswordBySbqHuserArgs, MutationChangeQardanApplicantPasswordBySbqHuserArgs } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { GraphQLResult } from "../../../typedefs";
import { ErrorDialog, SuccessDialog } from "../../../components/Alert";
import { RootStateType } from '../../store';


type LoginToSBQHPortalProps = { email: string, password: string, callback?: (err: GraphQLResult<any> | null, res: string | null) => void }
export const loginToSBQHPortal = createAsyncThunk(
    "query/loginToSBQHPortal",
    async ({ email, password }: LoginToSBQHPortalProps, thunkAPI) => {
        try {
            return await LoginToSBQHPortal(email, password)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type VerifySBQHSecuritycodeProps = { email: string, verificationCode: string, callback?: (err: GraphQLResult<any> | null, res: SBQHloginResponse | null) => void }
export const verifySBQHSecuritycode = createAsyncThunk(
    "query/verifySBQHSecuritycode",
    async ({ email, verificationCode }: VerifySBQHSecuritycodeProps, thunkAPI) => {
        try {
            return await VerifySBQHSecuritycode(email, verificationCode)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type CheckTokenExpirySBQHuserProps = { callback?: (err: GraphQLResult<any> | null, res: SBQHloginResponse | null) => void }
export const checkTokenExpirySBQHuser = createAsyncThunk(
    "query/checkTokenExpirySBQHuser",
    async ({ }: CheckTokenExpirySBQHuserProps, thunkAPI) => {
        try {
            return await CheckTokenExpirySBQHuser()
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type GetAllWithdrawalRequestsProps = { status?: WithdrawalRequestStatus | null, pageNumber: number, pageSize: number }
export const getAllWithdrawalRequests = createAsyncThunk(
    "query/getAllWithdrawalRequests",
    async ({ status, pageSize, pageNumber }: GetAllWithdrawalRequestsProps, thunkAPI) => {
        try {
            return await GetAllWithdrawalRequests({ status: status || undefined, pageSize, pageNumber })
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)


export const getAllQardanApplicants = createAsyncThunk(
    "query/getAllQardanApplicants",
    async (filter: FilterOptions, thunkAPI) => {
        try {
            return await GetAllQardanApplicants({ filter })
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)


export const getAllQardanInstallments = createAsyncThunk(
    "query/sbqhAuth/getAllQardanInstallments",
    async (filter: FilterOptions, thunkAPI) => {
        try {
            return await GetAllQardanInstallments(UserType.SbqhUser, { filter })
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)


type GetAllHSusersProps = { filterBy?: GetAllHSusersFilter, pageNumber: number, pageSize: number }
export const getAllHSusers = createAsyncThunk(
    "query/getAllHSusers",
    async ({ filterBy, pageNumber, pageSize }: GetAllHSusersProps, thunkAPI) => {
        try {
            return await GetAllHSusers(filterBy, pageNumber, pageSize)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)


type GetAllSBQHusersProps = { filterBy?: GetAllSbqHusersFilter, pageNumber: number, pageSize: number }
export const getAllSBQHusers = createAsyncThunk(
    "query/getAllSBQHusers",
    async ({ filterBy, pageNumber, pageSize }: GetAllSBQHusersProps, thunkAPI) => {
        try {
            return await GetAllSBQHusers({ filterBy, pageNumber, pageSize })
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)


type ChangeQardanApplicantDetailsProps = { input: UpdateQardanApplicantInput, callback?: (err?: any, res?: QardanApplicant) => void }
export const changeQardanApplicantDetails = createAsyncThunk(
    "mutation/sbqhauth/changeQardanApplicantDetails",
    async ({ input }: ChangeQardanApplicantDetailsProps, thunkAPI) => {
        try {
            return await ChangeQardanApplicantDetails(input);
        } catch (error) {
            ErrorDialog("Request Failed", "Something went wrong while update.");
            throw JSON.stringify(error);
        }
    }
)

export const changeQardanApplicantPasswordBySBQHuser = createAsyncThunk(
    "mutation/changeQardanApplicantPasswordBySBQHuser",
    async ({ applicantId, newPassword }: MutationChangeQardanApplicantPasswordBySbqHuserArgs & { callback?: (err?: any, res?: Response) => void }, thunkAPI) => {
        try {
            const res = await ChangeQardanApplicantPasswordBySBQHuser(applicantId, newPassword);
            SuccessDialog("Password Reset", res.data?.changeQardanApplicantPasswordBySBQHuser?.message!);
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

type ChangeHSuserDetailsProps = { id: string, first_name: string, last_name: string, its_no?: number, email?: string, phone_no?: string, callback?: (err?: any, res?: Response) => void }
export const changeHSuserDetails = createAsyncThunk(
    "mutation/sbqhauth/changeHSuserDetails",
    async ({ id, first_name, last_name, its_no, email, phone_no }: ChangeHSuserDetailsProps, thunkAPI) => {
        try {
            return await ChangeHSuserDetails()(UserType.SbqhUser, { id, first_name, last_name, its_no, email, phone_no })
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)


type changeSBQHusersAccessProps = { users_access: ChangeSbqHusersAccessInput[], callback?: (err?: any, res?: Response) => void }
export const changeSBQHusersAccess = createAsyncThunk(
    "mutation/changeSBQHusersAccess",
    async ({ users_access }: changeSBQHusersAccessProps, thunkAPI) => {
        try {
            /* making sure that there should be id and access no other key */
            const _users_access = users_access.map((a) => ({ id: a.id, access: a.access }));
            return await ChangeSBQHusersAccess(_users_access);
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)


export const fulfillPaymentWithdrawlRequests = createAsyncThunk(
    "mutation/fulfillPaymentWithdrawlRequests",
    async ({ account_ids }: { account_ids: number[], callback?: (err?: any, res?: Response) => void }, thunkAPI) => {
        try {
            const res = await FulfillPaymentWithdrawlRequests(account_ids);
            SuccessDialog("Request Confirmed", res.data?.fulfillPaymentWithdrawlRequests.message!);
            return res;
        } catch (error) {
            ErrorDialog("Request Not Confirmed", (error as any).errors[0].message);
            throw JSON.stringify(error);
        }
    }
)

export const getAllHStransactions = createAsyncThunk(
    "query/sbqhauth/GetAllHStransactions",
    async (filterOptions: FilterOptions, thunkAPI) => {
        try {
            return await GetAllHStransactions()(UserType.SbqhUser, filterOptions)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const addQardanApplicant = createAsyncThunk(
    "mutation/addQardanApplicant",
    async ({ input }: MutationAddQardanApplicantArgs & { callback?: (err?: any, res?: QardanApplicant) => void }, thunkAPI) => {
        try {
            const res = await AddQardanApplicant(input);
            SuccessDialog("Applicant Added", "New applicant has been successfully added");
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

export const addSBQHuser = createAsyncThunk(
    "mutation/addSBQHuser",
    async ({ email, name }: MutationAddSbqHuserArgs & { callback?: (err?: any, res?: SbqHuser) => void }, thunkAPI) => {
        try {
            const res = await AddSBQHuser({ email, name });
            SuccessDialog("User Added", "New user successfully added");
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

export const deleteQardanApplicant = createAsyncThunk(
    "mutation/deleteQardanApplicant",
    async ({ id }: MutationDeleteQardanApplicantArgs & { callback?: (err?: any, res?: Response) => void }, thunkAPI) => {
        try {
            const res = await DeleteQardanApplicant(id);
            SuccessDialog("Applicant Deleted", res.data?.deleteQardanApplicant.message!);
            return res;
        } catch (error) {
            if ((error as any)?.errors[0]?.message?.includes("a foreign key constraint fails (`qardan`.`installments`")) {
                ErrorDialog("Request Failed", `You have to delete all the transactions related to the File.No: ${id}.`);
            } else {
                ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            }
            throw JSON.stringify(error);
        }
    }
)

export const deleteSBQHuser = createAsyncThunk(
    "mutation/deleteSBQHuser",
    async ({ id }: MutationDeleteSbqHuserArgs & { callback?: (err?: any, res?: Response) => void }, thunkAPI) => {
        try {
            const res = await DeleteSBQHuser({ id });
            SuccessDialog("User Deleted", res.data?.deleteSBQHuser.message!);
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

export const addQardanInstallment = createAsyncThunk(
    "mutation/addQardanInstallment",
    async ({ input }: MutationAddQardanInstallmentArgs & { callback?: (err?: any, res?: QardanInstallment) => void }, thunkAPI) => {
        try {
            const res = await AddQardanInstallment(input);
            SuccessDialog("Transaction Added", "Transaction has been successfully added.");
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

export const deleteQardanInstallment = createAsyncThunk(
    "mutation/deleteQardanInstallment",
    async ({ id }: MutationDeleteQardanInstallmentArgs & { callback?: (err?: any, res?: Response) => void }, thunkAPI) => {
        try {
            const res = await DeleteQardanInstallment({ id });
            SuccessDialog("Transaction Added", "Transaction has been successfully added.");
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

export const addTransaction = createAsyncThunk(
    "mutation/addTransaction",
    async ({ hsUserId, amount, description, date }: MutationAddTransactionArgs & { callback?: (err?: any, res?: Response) => void }, thunkAPI) => {
        try {
            const res = await AddTransaction({ hsUserId, amount, description, date });
            SuccessDialog("Transaction Added", res.data?.addTransaction.message!);
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

export const deleteTransaction = createAsyncThunk(
    "mutation/deleteTransaction",
    async ({ transaction_id }: MutationDeleteTransactionArgs & { callback?: (err?: any, res?: Response) => void }, thunkAPI) => {
        try {
            const res = await DeleteTransaction({ transaction_id });
            SuccessDialog("Transaction Deleted", res.data?.deleteTransaction.message!);
            const filterOptions = (thunkAPI.getState() as RootStateType).sbqhPortal.hs_transactions_data.filterOptions;
            thunkAPI.dispatch(getAllHStransactions(filterOptions))
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

export const changeHSuserPasswordBySBQHuser = createAsyncThunk(
    "mutation/ChangeHSuserPasswordBySBQHuser",
    async ({ hsUserid, newPassword }: MutationChangeHSuserPasswordBySbqHuserArgs & { callback?: (err?: any, res?: Response) => void }, thunkAPI) => {
        try {
            const res = await ChangeHSuserPasswordBySBQHuser(hsUserid, newPassword);
            SuccessDialog("Password Reset", res.data?.changeHSuserPasswordBySBQHuser?.message!);
            return res;
        } catch (error) {
            ErrorDialog("Request Failed", (error as any)?.errors[0]?.message);
            throw JSON.stringify(error);
        }
    }
)

export const getAllHSaccountsWithBalances = createAsyncThunk(
    "query/getAllHSaccountsWithBalances",
    async (args: QueryGetAllHSaccountsWithBalancesArgs, thunkAPI) => {
        try {
            return await GetAllHSaccountsWithBalances(args)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const getAllDormantHSaccounts = createAsyncThunk(
    "query/getAllDormantHSaccounts",
    async (args: QueryGetAllDormantHSaccountsArgs, thunkAPI) => {
        try {
            return await GetAllDormantHSaccounts(args)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type GetHSuserAccountAndTransactionType = { userId: string, fetchUserInfo?: boolean, transactionfilter?: FilterOptions }
export const getHSuserAccountAndTransaction = createAsyncThunk(
    "getHSuserAccountAndTransaction",
    async (args: GetHSuserAccountAndTransactionType, thunkAPI) => {
        try {
            const userInfo = args.fetchUserInfo ? await GetHSuserInfo()(UserType.SbqhUser, { userId: args.userId }) : undefined;
            const userTransactions = await GetAllHStransactions()(UserType.SbqhUser, args.transactionfilter);
            return {
                userDetails: userInfo?.data?.getHSuserInfo,
                userTransactions: userTransactions.data?.getAllHStransactions
            }
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type GetQardanApplicantAndInstallmentsType = { applicantId: string, fetchApplicantDetails?: boolean, installmentfilter?: FilterOptions }
export const getQardanApplicantAndInstallments = createAsyncThunk(
    "getQardanApplicantAndInstallments",
    async (args: GetQardanApplicantAndInstallmentsType, thunkAPI) => {
        try {
            const userInfo = args.fetchApplicantDetails ? await GetQardanApplicantInfo(UserType.SbqhUser, { applicantId: args.applicantId }) : undefined;
            const userTransactions = await GetAllQardanInstallments(UserType.SbqhUser, { filter: { ...args.installmentfilter } });
            return {
                applicantInfo: userInfo?.data?.getQardanApplicantInfo,
                installments: userTransactions.data?.getAllQardanInstallments
            }
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const getQardanApplicantsWithOutstandingBalances = createAsyncThunk(
    "getQardanApplicantsWithOutstandingBalances",
    async (args: QueryGetQardanApplicantsWithOutstandingBalancesArgs, thunkAPI) => {
        try {
            return await GetQardanApplicantsWithOutstandingBalances(args);
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)
