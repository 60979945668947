import React, { FC, useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import * as s from './style';
const quranAyatAudioPath = require('../../assets/audio/quran-ayat-01.mp3');

export const y_positions = ["0%", "50%", "100%"];
export type ContentNo = number;
export interface Props {

}


const HomeCoverImg: FC<Props> = ({ }) => {
    let [contentNo, setContentNo] = useState<ContentNo>(1);
    let quranAyatAudio = useRef<HTMLAudioElement>(null);
    const changeContentNumber = (step?: ContentNo) => {
        if (!step) { contentNo++ }
        if (step && typeof step === "number") { contentNo = step }
        if (contentNo > y_positions.length) { contentNo = 1 }
        if (contentNo < 1) { contentNo = y_positions.length }
        setContentNo(contentNo)
    }

    /* effect use to change the content of the home cover after every 20-seconds */
    useEffect(() => {
        const interval = setInterval(changeContentNumber, 15000);
        return () => { clearInterval(interval) }
    }, [])

    return (
        <s.Wrap>
            <Grid component='div' >
                <s.Controller className='__content-control' mirror={true} onClick={() => { changeContentNumber(contentNo - 1) }}>
                    <ArrowForwardIos />
                </s.Controller>
            </Grid>

            <Grid className="__text_container_" component='div' container justify='space-between' alignItems='flex-start' wrap='wrap-reverse' >
                <Grid component='div' container item xs={12} sm={4} xl={3} ><s.Qoutes_EN contentNo={contentNo} /> </Grid>
                <Grid component='div' container item xs={12} sm={5} xl={4} justify="flex-end"  >
                    <s.Qoutes_ARB style={{ cursor: (contentNo === 1) ? "pointer" : undefined }}
                        onClick={() => { (contentNo === 1) && quranAyatAudio.current?.play() }}
                        contentNo={contentNo} />
                    <audio src={quranAyatAudioPath} ref={quranAyatAudio} />
                </Grid>
            </Grid>

            <Grid component='div'>
                <s.Controller className='__content-control' onClick={() => { changeContentNumber(contentNo + 1) }}>
                    <ArrowForwardIos />
                </s.Controller>
            </Grid>
        </s.Wrap>
    )
}

export default HomeCoverImg;
