import { Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { FC, useState, Fragment } from 'react';
import * as s from './style';

export interface Props {
    links: { path: string, title: string }[],
    open?: boolean,
    x?: number;
    y?: number;
    width?: number;
}

//////////////////////////////////  component ////////////////////////////////
const DropDown: FC<Props> = ({ links, open = false, x = 90, y = 120, width }) => {
    const [isOpen, setOpen] = useState<boolean>(open);

    return (
        <s.DropDownWrap x={x} y={y}>
            <s.DropDownContainer
                open={isOpen || open} width={width}
                onMouseEnter={() => { setOpen(true) }} onMouseLeave={() => { setOpen(false) }}
            >
                {
                    links.map((link, idx, arr) => {
                        return <Fragment key={idx}>
                            <Link to={link.path} >
                                <Typography component='div' >{link.title}</Typography>
                            </Link>
                            <hr style={{ display: (idx + 1) !== arr.length ? "unset" : "none" }} />
                        </Fragment>
                    })
                }
            </s.DropDownContainer>
        </s.DropDownWrap>
    )
};


export default DropDown;