import React, { FC } from 'react';
import { Seo, PageLayout } from '../../components';
import { PageProps } from 'gatsby';
import config from '../../../app-config.json';
import { TS_PaymentForm } from '../../components/Form';
import { Typography } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, } from "@stripe/stripe-js";
import { siteMetadata } from '../../../gatsby-config';
import { BodyWrap, MetaDescription } from './';
// import * as s from '../../style.pages/index.style';
// import colors from '../../theme/colors';
// import { Router, RouteComponentProps } from '@reach/router';
// import { StyledBtn1 } from '../../components/Button';
// import styled from 'styled-components';
// import { screenWidths } from '../../theme/css-generators';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISH_KEY_TS!);
const PAGE_TITLE = config.pages.TAHERI_SCHEME.routes.payentForm.title;
const PARENT_PAGE_TITLE = config.pages.TAHERI_SCHEME.title
const PARENT_PAGE_PATH = config.pages.TAHERI_SCHEME.path
// const PAGE_PATH = config.pages.TAHERI_SCHEME.path;
const PAYMENT_FORM_PATH = config.pages.TAHERI_SCHEME.routes.payentForm.path
export const PAYMENT_FORM_LINK = `${PARENT_PAGE_PATH}${PAYMENT_FORM_PATH}`;


const PaymentForm: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo
                title={`${PAGE_TITLE} | ${PARENT_PAGE_TITLE} | ${siteMetadata.title}`}
                propMetaDescription={MetaDescription}
            />
            <Elements stripe={stripePromise}>
                <BodyWrap>
                    <br />
                    <Typography variant='h2' color='inherit' >Qardan Hasana Payment Form</Typography>
                    <Typography variant='h6' color='inherit' gutterBottom >QARDAN HASANA - {PARENT_PAGE_TITLE.toUpperCase()} (NON-REFUNDABLE)</Typography>
                    <hr /><br /> <br /> <br />
                    <TS_PaymentForm pageTitle={`${PARENT_PAGE_TITLE} - ${config.pages.TAHERI_SCHEME.arbic_title}`} />
                    <br />
                </BodyWrap>
            </Elements>
        </PageLayout>
    )
}

export default PaymentForm;
