import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSEmail: string;
  AWSTimestamp: number;
}

export interface Access {
  __typename?: 'Access';
  read: Scalars['Int'];
  write: Scalars['Int'];
}

export interface AccessInput {
  read: Scalars['Int'];
  write: Scalars['Int'];
}

export interface Account {
  __typename?: 'Account';
  account_holder: Scalars['String'];
  id: Scalars['Int'];
}

export interface AddQardanApplicantInput {
  company_name?: InputMaybe<Scalars['String']>;
  email: Scalars['AWSEmail'];
  first_name: Scalars['String'];
  id: Scalars['String'];
  its_no: Scalars['Int'];
  last_name: Scalars['String'];
  loanAllocation: QardanLoanAllocationInput;
  phone_no: Scalars['String'];
  title: Scalars['String'];
}

export interface AddQardanInstallmentInput {
  amount: Scalars['Float'];
  applicant_id: Scalars['String'];
  date?: InputMaybe<Scalars['AWSTimestamp']>;
  description: Scalars['String'];
}

export interface ChangeSbqHusersAccessInput {
  access: SbqHuserAccessInput;
  id: Scalars['Int'];
}

export enum ComparisonOperators {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE',
  Neq = 'NEQ',
  StartsWith = 'STARTS_WITH'
}

export interface FilterField {
  field: Scalars['String'];
  operator: ComparisonOperators;
  value: Scalars['String'];
}

export interface FilterOptions {
  filterBy?: InputMaybe<Array<InputMaybe<FilterField>>>;
  logicalOperator?: InputMaybe<LogicalOperator>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<SortBy>;
}

export interface GetAllHSusersFilter {
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  its_no?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
}

export interface GetAllHSusersOutput {
  __typename?: 'GetAllHSusersOutput';
  totalCount: Scalars['Int'];
  users: Array<Maybe<HSuserBasicInfo>>;
}

export interface GetAllQardanApplicantsOutput {
  __typename?: 'GetAllQardanApplicantsOutput';
  list: Array<Maybe<QardanApplicant>>;
  totalCount: Scalars['Int'];
}

export interface GetAllQardanInstallmentsOutput {
  __typename?: 'GetAllQardanInstallmentsOutput';
  list: Array<Maybe<QardanInstallment>>;
  totalCount: Scalars['Int'];
  totalLoanAllocated?: Maybe<Scalars['Float']>;
  totalLoanPaid?: Maybe<Scalars['Float']>;
  totalLoanPending?: Maybe<Scalars['Float']>;
}

export interface GetAllSbqHusersFilter {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface GetAllSbqHusersOutput {
  __typename?: 'GetAllSBQHusersOutput';
  totalCount: Scalars['Int'];
  users: Array<Maybe<SbqHuser>>;
}

export interface GetAllWithdrawalRequestsOutput {
  __typename?: 'GetAllWithdrawalRequestsOutput';
  data: Array<Maybe<WithdrawalRequestWithHSuserInfo>>;
  totalCount: Scalars['Int'];
}

export interface HStransactionsOutput {
  __typename?: 'HStransactionsOutput';
  amountIn?: Maybe<Scalars['Float']>;
  amountOut?: Maybe<Scalars['Float']>;
  data: Array<Maybe<Transaction>>;
  totalCount: Scalars['Int'];
}

export interface HSuser extends User {
  __typename?: 'HSuser';
  account_id: Scalars['Int'];
  banks_detail_list?: Maybe<Array<Maybe<HSuserBank>>>;
  child_users?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['String'];
  its_no?: Maybe<Scalars['Int']>;
  last_name: Scalars['String'];
  phone_no?: Maybe<Scalars['String']>;
  userType: UserType;
}

export interface HSuserBank {
  __typename?: 'HSuserBank';
  bank_account_number: Scalars['String'];
  id: Scalars['Int'];
  name_as_per_bank_account: Scalars['String'];
  sort_code: Scalars['String'];
  user_id: Scalars['String'];
}

export interface HSuserBasicInfo {
  __typename?: 'HSuserBasicInfo';
  first_name: Scalars['String'];
  id: Scalars['String'];
  its_no?: Maybe<Scalars['Int']>;
  last_name: Scalars['String'];
}

export interface HSuserFullInfo {
  __typename?: 'HSuserFullInfo';
  account_id: Scalars['Int'];
  balance_amount: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['String'];
  its_no?: Maybe<Scalars['Int']>;
  last_name: Scalars['String'];
  last_transaction_date?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
}

export interface HSusersWithTotalBalanceOutput {
  __typename?: 'HSusersWithTotalBalanceOutput';
  data: Array<Maybe<HSuserFullInfo>>;
  totalCount?: Maybe<Scalars['Int']>;
}

export enum LogicalOperator {
  And = 'AND',
  Or = 'OR'
}

export interface LoginResponse {
  __typename?: 'LoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  userDetails: HSuser | QardanApplicant | SbqHuser;
  warning?: Maybe<Scalars['String']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  addHSuser: Response;
  addHsUserBankAccount: HSuserBank;
  addQardanApplicant: QardanApplicant;
  addQardanInstallment: QardanInstallment;
  addSBQHuser: SbqHuser;
  addTransaction: Response;
  changeHSuserDetails: Response;
  changeHSuserPassword: Response;
  changeHSuserPasswordBySBQHuser: Response;
  changeQardanApplicantDetails: QardanApplicant;
  changeQardanApplicantPassword: Response;
  changeQardanApplicantPasswordBySBQHuser: Response;
  changeSBQHuserPassword: Response;
  changeSBQHusersAccess: Response;
  deleteHSuser: Response;
  deleteHsUserBankAccount: Response;
  deleteQardanApplicant: Response;
  deleteQardanInstallment: Response;
  deleteSBQHuser: Response;
  deleteTransaction: Response;
  fulfillPaymentWithdrawlRequests: Response;
  hsPaymentWithdrawalRequest: Response;
  sendLoginIssue: Response;
  updateHsUserBankAccount: Response;
  updateTransaction: Response;
}


export interface MutationAddHSuserArgs {
  email?: InputMaybe<Scalars['AWSEmail']>;
  first_name: Scalars['String'];
  id: Scalars['String'];
  its_no?: InputMaybe<Scalars['Int']>;
  last_name: Scalars['String'];
  phone_no?: InputMaybe<Scalars['String']>;
}


export interface MutationAddHsUserBankAccountArgs {
  bank_account_number: Scalars['String'];
  name_as_per_bank_account: Scalars['String'];
  sort_code: Scalars['String'];
  user_id: Scalars['String'];
}


export interface MutationAddQardanApplicantArgs {
  input: AddQardanApplicantInput;
}


export interface MutationAddQardanInstallmentArgs {
  input: AddQardanInstallmentInput;
}


export interface MutationAddSbqHuserArgs {
  email: Scalars['String'];
  name: Scalars['String'];
}


export interface MutationAddTransactionArgs {
  amount: Scalars['Float'];
  date: Scalars['AWSTimestamp'];
  description: Scalars['String'];
  hsUserId: Scalars['String'];
}


export interface MutationChangeHSuserDetailsArgs {
  email?: InputMaybe<Scalars['AWSEmail']>;
  first_name: Scalars['String'];
  id: Scalars['String'];
  its_no?: InputMaybe<Scalars['Int']>;
  last_name: Scalars['String'];
  phone_no?: InputMaybe<Scalars['String']>;
}


export interface MutationChangeHSuserPasswordArgs {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}


export interface MutationChangeHSuserPasswordBySbqHuserArgs {
  hsUserid: Scalars['String'];
  newPassword: Scalars['String'];
}


export interface MutationChangeQardanApplicantDetailsArgs {
  input: UpdateQardanApplicantInput;
}


export interface MutationChangeQardanApplicantPasswordArgs {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}


export interface MutationChangeQardanApplicantPasswordBySbqHuserArgs {
  applicantId: Scalars['String'];
  newPassword: Scalars['String'];
}


export interface MutationChangeSbqHuserPasswordArgs {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}


export interface MutationChangeSbqHusersAccessArgs {
  users_access: Array<ChangeSbqHusersAccessInput>;
}


export interface MutationDeleteHSuserArgs {
  id: Scalars['String'];
}


export interface MutationDeleteHsUserBankAccountArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteQardanApplicantArgs {
  id: Scalars['String'];
}


export interface MutationDeleteQardanInstallmentArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteSbqHuserArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteTransactionArgs {
  transaction_id: Scalars['Int'];
}


export interface MutationFulfillPaymentWithdrawlRequestsArgs {
  account_ids: Array<Scalars['Int']>;
}


export interface MutationHsPaymentWithdrawalRequestArgs {
  amount: Scalars['Float'];
  bank_account_number: Scalars['String'];
  email?: InputMaybe<Scalars['AWSEmail']>;
  name_as_per_bank_account: Scalars['String'];
  sort_code: Scalars['String'];
}


export interface MutationSendLoginIssueArgs {
  fileNumber?: InputMaybe<Scalars['String']>;
  hstmNumber?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  name: Scalars['String'];
}


export interface MutationUpdateHsUserBankAccountArgs {
  bank_account_number: Scalars['String'];
  id: Scalars['Int'];
  name_as_per_bank_account: Scalars['String'];
  sort_code: Scalars['String'];
  user_id: Scalars['String'];
}


export interface MutationUpdateTransactionArgs {
  amount: Scalars['Float'];
  date: Scalars['AWSTimestamp'];
  description: Scalars['String'];
  transaction_id: Scalars['Int'];
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface PaymentWithdrawalRequest {
  __typename?: 'PaymentWithdrawalRequest';
  account_id: Scalars['Int'];
  amount: Scalars['Float'];
  bank_account_number: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fulfill_date?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name_as_per_bank_account: Scalars['String'];
  request_date: Scalars['String'];
  sort_code: Scalars['String'];
  status: WithdrawalRequestStatus;
}

export interface QardanApplicant extends User {
  __typename?: 'QardanApplicant';
  company_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['String'];
  its_no: Scalars['Int'];
  last_name: Scalars['String'];
  loanAllocation?: Maybe<QardanLoanAllocation>;
  loanRepaid?: Maybe<Scalars['Float']>;
  phone_no?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userType: UserType;
}

export interface QardanApplicantList {
  __typename?: 'QardanApplicantList';
  list: Array<Maybe<QardanApplicant>>;
  totalCount: Scalars['Int'];
}

export interface QardanInstallment {
  __typename?: 'QardanInstallment';
  amount: Scalars['Float'];
  applicant_id: Scalars['String'];
  date: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
}

export interface QardanLoanAllocation {
  __typename?: 'QardanLoanAllocation';
  amount: Scalars['Float'];
  applicant_id: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['Int'];
  repayment_date: Scalars['String'];
  repayment_duration_in_months: Scalars['Int'];
  status: QardanLoanStatus;
}

export interface QardanLoanAllocationInput {
  amount: Scalars['Int'];
  date?: InputMaybe<Scalars['AWSTimestamp']>;
  repayment_date?: InputMaybe<Scalars['AWSTimestamp']>;
  repayment_duration_in_months: Scalars['Int'];
}

export enum QardanLoanStatus {
  Outstanding = 'Outstanding',
  Paid = 'Paid'
}

export interface Query {
  __typename?: 'Query';
  checkTokenExpiry: LoginResponse;
  getAllDormantHSaccounts: HSusersWithTotalBalanceOutput;
  getAllHSaccountsWithBalances: HSusersWithTotalBalanceOutput;
  getAllHStransactions: HStransactionsOutput;
  getAllHSusers: GetAllHSusersOutput;
  getAllQardanApplicants: GetAllQardanApplicantsOutput;
  getAllQardanInstallments: GetAllQardanInstallmentsOutput;
  getAllSBQHusers: GetAllSbqHusersOutput;
  getAllWithdrawalRequests: GetAllWithdrawalRequestsOutput;
  getHSuserInfo: HSuserFullInfo;
  getHsUserBankAccounts: Array<Maybe<HSuserBank>>;
  getHsUserChildAccessToken: Scalars['String'];
  getQardanApplicantInfo: QardanApplicant;
  getQardanApplicantsWithOutstandingBalances: QardanApplicantList;
  getWithdrawalRequestDetails: PaymentWithdrawalRequest;
  loginToHSPortal: LoginResponse;
  loginToQardanPortal: LoginResponse;
  loginToSBQHPortal: Scalars['String'];
  sendEmailConfirmationCode: Scalars['String'];
  verifyHSsecuritycode: LoginResponse;
  verifyQardanSecuritycode: LoginResponse;
  verifySBQHSecuritycode: LoginResponse;
}


export interface QueryGetAllDormantHSaccountsArgs {
  dateFrom: Scalars['AWSTimestamp'];
  dateTo?: InputMaybe<Scalars['AWSTimestamp']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  withTotalCount?: InputMaybe<Scalars['Boolean']>;
}


export interface QueryGetAllHSaccountsWithBalancesArgs {
  dateFrom: Scalars['AWSTimestamp'];
  dateTo?: InputMaybe<Scalars['AWSTimestamp']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  withTotalCount?: InputMaybe<Scalars['Boolean']>;
}


export interface QueryGetAllHStransactionsArgs {
  filter: FilterOptions;
}


export interface QueryGetAllHSusersArgs {
  filterBy?: InputMaybe<GetAllHSusersFilter>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}


export interface QueryGetAllQardanApplicantsArgs {
  filter: FilterOptions;
}


export interface QueryGetAllQardanInstallmentsArgs {
  filter: FilterOptions;
}


export interface QueryGetAllSbqHusersArgs {
  filterBy?: InputMaybe<GetAllSbqHusersFilter>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}


export interface QueryGetAllWithdrawalRequestsArgs {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<WithdrawalRequestStatus>;
}


export interface QueryGetHSuserInfoArgs {
  userId: Scalars['String'];
}


export interface QueryGetHsUserChildAccessTokenArgs {
  childUserId: Scalars['ID'];
}


export interface QueryGetQardanApplicantInfoArgs {
  applicantId: Scalars['String'];
}


export interface QueryGetQardanApplicantsWithOutstandingBalancesArgs {
  filter: FilterOptions;
}


export interface QueryGetWithdrawalRequestDetailsArgs {
  id: Scalars['Int'];
}


export interface QueryLoginToHsPortalArgs {
  id: Scalars['String'];
  password: Scalars['String'];
}


export interface QueryLoginToQardanPortalArgs {
  id: Scalars['String'];
  password: Scalars['String'];
}


export interface QueryLoginToSbqhPortalArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}


export interface QueryVerifyHSsecuritycodeArgs {
  hsUserId: Scalars['String'];
  verificationCode: Scalars['String'];
}


export interface QueryVerifyQardanSecuritycodeArgs {
  applicantId: Scalars['String'];
  verificationCode: Scalars['String'];
}


export interface QueryVerifySbqhSecuritycodeArgs {
  email: Scalars['String'];
  verificationCode: Scalars['String'];
}

export interface Response {
  __typename?: 'Response';
  message: Scalars['String'];
  responseId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  warning?: Maybe<Scalars['String']>;
}

export interface SbqHuser extends User {
  __typename?: 'SBQHuser';
  access: SbqHuserAccess;
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  userType: UserType;
}

export interface SbqHuserAccess {
  __typename?: 'SBQHuserAccess';
  HS_Accounts: Access;
  HS_Transactions: Access;
  HS_Withdrawal_Requests: Access;
  Qardan_Applicants: Access;
  Qardan_Installments: Access;
  SBQH_Admin_Users: Access;
}

export interface SbqHuserAccessInput {
  HS_Accounts?: InputMaybe<AccessInput>;
  HS_Transactions?: InputMaybe<AccessInput>;
  HS_Withdrawal_Requests?: InputMaybe<AccessInput>;
  Qardan_Applicants?: InputMaybe<AccessInput>;
  Qardan_Installments?: InputMaybe<AccessInput>;
  SBQH_Admin_Users?: InputMaybe<AccessInput>;
}

export interface SortBy {
  fields: Array<InputMaybe<Scalars['String']>>;
  order: Order;
}

export interface Transaction {
  __typename?: 'Transaction';
  account_id?: Maybe<Scalars['Int']>;
  amount: Scalars['Float'];
  date: Scalars['String'];
  description: Scalars['String'];
  hsUserId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
}

export enum TransactionType {
  Bf = 'BF',
  Cash = 'CASH',
  Chq = 'CHQ',
  Dc = 'DC',
  PaidOl = 'PAID_OL',
  So = 'SO'
}

export interface UpdateQardanApplicantInput {
  company_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  first_name?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  its_no?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  loanAllocation: QardanLoanAllocationInput;
  phone_no?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface User {
  userType: UserType;
}

export enum UserType {
  HsUser = 'HS_USER',
  QardanUser = 'QARDAN_USER',
  SbqhUser = 'SBQH_USER'
}

export enum WithdrawalRequestStatus {
  Fulfilled = 'Fulfilled',
  Pending = 'Pending'
}

export interface WithdrawalRequestWithHSuserInfo {
  __typename?: 'WithdrawalRequestWithHSuserInfo';
  account_id: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  request_date: Scalars['String'];
  request_id: Scalars['Int'];
  requested_amount: Scalars['Float'];
  status: WithdrawalRequestStatus;
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AWSEmail: ResolverTypeWrapper<Scalars['AWSEmail']>;
  AWSTimestamp: ResolverTypeWrapper<Scalars['AWSTimestamp']>;
  Access: ResolverTypeWrapper<Access>;
  AccessInput: AccessInput;
  Account: ResolverTypeWrapper<Account>;
  AddQardanApplicantInput: AddQardanApplicantInput;
  AddQardanInstallmentInput: AddQardanInstallmentInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ChangeSBQHusersAccessInput: ChangeSbqHusersAccessInput;
  ComparisonOperators: ComparisonOperators;
  FilterField: FilterField;
  FilterOptions: FilterOptions;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GetAllHSusersFilter: GetAllHSusersFilter;
  GetAllHSusersOutput: ResolverTypeWrapper<GetAllHSusersOutput>;
  GetAllQardanApplicantsOutput: ResolverTypeWrapper<GetAllQardanApplicantsOutput>;
  GetAllQardanInstallmentsOutput: ResolverTypeWrapper<GetAllQardanInstallmentsOutput>;
  GetAllSBQHusersFilter: GetAllSbqHusersFilter;
  GetAllSBQHusersOutput: ResolverTypeWrapper<GetAllSbqHusersOutput>;
  GetAllWithdrawalRequestsOutput: ResolverTypeWrapper<GetAllWithdrawalRequestsOutput>;
  HStransactionsOutput: ResolverTypeWrapper<HStransactionsOutput>;
  HSuser: ResolverTypeWrapper<HSuser>;
  HSuserBank: ResolverTypeWrapper<HSuserBank>;
  HSuserBasicInfo: ResolverTypeWrapper<HSuserBasicInfo>;
  HSuserFullInfo: ResolverTypeWrapper<HSuserFullInfo>;
  HSusersWithTotalBalanceOutput: ResolverTypeWrapper<HSusersWithTotalBalanceOutput>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  LogicalOperator: LogicalOperator;
  LoginResponse: ResolverTypeWrapper<LoginResponse>;
  Mutation: ResolverTypeWrapper<{}>;
  Order: Order;
  PaymentWithdrawalRequest: ResolverTypeWrapper<PaymentWithdrawalRequest>;
  QardanApplicant: ResolverTypeWrapper<QardanApplicant>;
  QardanApplicantList: ResolverTypeWrapper<QardanApplicantList>;
  QardanInstallment: ResolverTypeWrapper<QardanInstallment>;
  QardanLoanAllocation: ResolverTypeWrapper<QardanLoanAllocation>;
  QardanLoanAllocationInput: QardanLoanAllocationInput;
  QardanLoanStatus: QardanLoanStatus;
  Query: ResolverTypeWrapper<{}>;
  Response: ResolverTypeWrapper<Response>;
  SBQHuser: ResolverTypeWrapper<SbqHuser>;
  SBQHuserAccess: ResolverTypeWrapper<SbqHuserAccess>;
  SBQHuserAccessInput: SbqHuserAccessInput;
  SortBy: SortBy;
  String: ResolverTypeWrapper<Scalars['String']>;
  Transaction: ResolverTypeWrapper<Transaction>;
  TransactionType: TransactionType;
  UpdateQardanApplicantInput: UpdateQardanApplicantInput;
  User: ResolversTypes['HSuser'] | ResolversTypes['QardanApplicant'] | ResolversTypes['SBQHuser'];
  UserType: UserType;
  WithdrawalRequestStatus: WithdrawalRequestStatus;
  WithdrawalRequestWithHSuserInfo: ResolverTypeWrapper<WithdrawalRequestWithHSuserInfo>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AWSEmail: Scalars['AWSEmail'];
  AWSTimestamp: Scalars['AWSTimestamp'];
  Access: Access;
  AccessInput: AccessInput;
  Account: Account;
  AddQardanApplicantInput: AddQardanApplicantInput;
  AddQardanInstallmentInput: AddQardanInstallmentInput;
  Boolean: Scalars['Boolean'];
  ChangeSBQHusersAccessInput: ChangeSbqHusersAccessInput;
  FilterField: FilterField;
  FilterOptions: FilterOptions;
  Float: Scalars['Float'];
  GetAllHSusersFilter: GetAllHSusersFilter;
  GetAllHSusersOutput: GetAllHSusersOutput;
  GetAllQardanApplicantsOutput: GetAllQardanApplicantsOutput;
  GetAllQardanInstallmentsOutput: GetAllQardanInstallmentsOutput;
  GetAllSBQHusersFilter: GetAllSbqHusersFilter;
  GetAllSBQHusersOutput: GetAllSbqHusersOutput;
  GetAllWithdrawalRequestsOutput: GetAllWithdrawalRequestsOutput;
  HStransactionsOutput: HStransactionsOutput;
  HSuser: HSuser;
  HSuserBank: HSuserBank;
  HSuserBasicInfo: HSuserBasicInfo;
  HSuserFullInfo: HSuserFullInfo;
  HSusersWithTotalBalanceOutput: HSusersWithTotalBalanceOutput;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  LoginResponse: LoginResponse;
  Mutation: {};
  PaymentWithdrawalRequest: PaymentWithdrawalRequest;
  QardanApplicant: QardanApplicant;
  QardanApplicantList: QardanApplicantList;
  QardanInstallment: QardanInstallment;
  QardanLoanAllocation: QardanLoanAllocation;
  QardanLoanAllocationInput: QardanLoanAllocationInput;
  Query: {};
  Response: Response;
  SBQHuser: SbqHuser;
  SBQHuserAccess: SbqHuserAccess;
  SBQHuserAccessInput: SbqHuserAccessInput;
  SortBy: SortBy;
  String: Scalars['String'];
  Transaction: Transaction;
  UpdateQardanApplicantInput: UpdateQardanApplicantInput;
  User: ResolversParentTypes['HSuser'] | ResolversParentTypes['QardanApplicant'] | ResolversParentTypes['SBQHuser'];
  WithdrawalRequestWithHSuserInfo: WithdrawalRequestWithHSuserInfo;
};

export interface AwsEmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSEmail'], any> {
  name: 'AWSEmail';
}

export interface AwsTimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTimestamp'], any> {
  name: 'AWSTimestamp';
}

export type AccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['Access'] = ResolversParentTypes['Access']> = {
  read?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  write?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = {
  account_holder?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllHSusersOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAllHSusersOutput'] = ResolversParentTypes['GetAllHSusersOutput']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  users?: Resolver<Array<Maybe<ResolversTypes['HSuserBasicInfo']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllQardanApplicantsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAllQardanApplicantsOutput'] = ResolversParentTypes['GetAllQardanApplicantsOutput']> = {
  list?: Resolver<Array<Maybe<ResolversTypes['QardanApplicant']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllQardanInstallmentsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAllQardanInstallmentsOutput'] = ResolversParentTypes['GetAllQardanInstallmentsOutput']> = {
  list?: Resolver<Array<Maybe<ResolversTypes['QardanInstallment']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalLoanAllocated?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLoanPaid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLoanPending?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllSbqHusersOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAllSBQHusersOutput'] = ResolversParentTypes['GetAllSBQHusersOutput']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  users?: Resolver<Array<Maybe<ResolversTypes['SBQHuser']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllWithdrawalRequestsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAllWithdrawalRequestsOutput'] = ResolversParentTypes['GetAllWithdrawalRequestsOutput']> = {
  data?: Resolver<Array<Maybe<ResolversTypes['WithdrawalRequestWithHSuserInfo']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HStransactionsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HStransactionsOutput'] = ResolversParentTypes['HStransactionsOutput']> = {
  amountIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOut?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  data?: Resolver<Array<Maybe<ResolversTypes['Transaction']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HSuserResolvers<ContextType = any, ParentType extends ResolversParentTypes['HSuser'] = ResolversParentTypes['HSuser']> = {
  account_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  banks_detail_list?: Resolver<Maybe<Array<Maybe<ResolversTypes['HSuserBank']>>>, ParentType, ContextType>;
  child_users?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  its_no?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  last_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone_no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HSuserBankResolvers<ContextType = any, ParentType extends ResolversParentTypes['HSuserBank'] = ResolversParentTypes['HSuserBank']> = {
  bank_account_number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name_as_per_bank_account?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sort_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HSuserBasicInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['HSuserBasicInfo'] = ResolversParentTypes['HSuserBasicInfo']> = {
  first_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  its_no?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  last_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HSuserFullInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['HSuserFullInfo'] = ResolversParentTypes['HSuserFullInfo']> = {
  account_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  balance_amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  its_no?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  last_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_transaction_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone_no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HSusersWithTotalBalanceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HSusersWithTotalBalanceOutput'] = ResolversParentTypes['HSusersWithTotalBalanceOutput']> = {
  data?: Resolver<Array<Maybe<ResolversTypes['HSuserFullInfo']>>, ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginResponse'] = ResolversParentTypes['LoginResponse']> = {
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userDetails?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  warning?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addHSuser?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationAddHSuserArgs, 'first_name' | 'id' | 'last_name'>>;
  addHsUserBankAccount?: Resolver<ResolversTypes['HSuserBank'], ParentType, ContextType, RequireFields<MutationAddHsUserBankAccountArgs, 'bank_account_number' | 'name_as_per_bank_account' | 'sort_code' | 'user_id'>>;
  addQardanApplicant?: Resolver<ResolversTypes['QardanApplicant'], ParentType, ContextType, RequireFields<MutationAddQardanApplicantArgs, 'input'>>;
  addQardanInstallment?: Resolver<ResolversTypes['QardanInstallment'], ParentType, ContextType, RequireFields<MutationAddQardanInstallmentArgs, 'input'>>;
  addSBQHuser?: Resolver<ResolversTypes['SBQHuser'], ParentType, ContextType, RequireFields<MutationAddSbqHuserArgs, 'email' | 'name'>>;
  addTransaction?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationAddTransactionArgs, 'amount' | 'date' | 'description' | 'hsUserId'>>;
  changeHSuserDetails?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationChangeHSuserDetailsArgs, 'first_name' | 'id' | 'last_name'>>;
  changeHSuserPassword?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationChangeHSuserPasswordArgs, 'newPassword' | 'oldPassword'>>;
  changeHSuserPasswordBySBQHuser?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationChangeHSuserPasswordBySbqHuserArgs, 'hsUserid' | 'newPassword'>>;
  changeQardanApplicantDetails?: Resolver<ResolversTypes['QardanApplicant'], ParentType, ContextType, RequireFields<MutationChangeQardanApplicantDetailsArgs, 'input'>>;
  changeQardanApplicantPassword?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationChangeQardanApplicantPasswordArgs, 'newPassword' | 'oldPassword'>>;
  changeQardanApplicantPasswordBySBQHuser?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationChangeQardanApplicantPasswordBySbqHuserArgs, 'applicantId' | 'newPassword'>>;
  changeSBQHuserPassword?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationChangeSbqHuserPasswordArgs, 'newPassword' | 'oldPassword'>>;
  changeSBQHusersAccess?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationChangeSbqHusersAccessArgs, 'users_access'>>;
  deleteHSuser?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationDeleteHSuserArgs, 'id'>>;
  deleteHsUserBankAccount?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationDeleteHsUserBankAccountArgs, 'id'>>;
  deleteQardanApplicant?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationDeleteQardanApplicantArgs, 'id'>>;
  deleteQardanInstallment?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationDeleteQardanInstallmentArgs, 'id'>>;
  deleteSBQHuser?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationDeleteSbqHuserArgs, 'id'>>;
  deleteTransaction?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationDeleteTransactionArgs, 'transaction_id'>>;
  fulfillPaymentWithdrawlRequests?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationFulfillPaymentWithdrawlRequestsArgs, 'account_ids'>>;
  hsPaymentWithdrawalRequest?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationHsPaymentWithdrawalRequestArgs, 'amount' | 'bank_account_number' | 'name_as_per_bank_account' | 'sort_code'>>;
  sendLoginIssue?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationSendLoginIssueArgs, 'message' | 'name'>>;
  updateHsUserBankAccount?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationUpdateHsUserBankAccountArgs, 'bank_account_number' | 'id' | 'name_as_per_bank_account' | 'sort_code' | 'user_id'>>;
  updateTransaction?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationUpdateTransactionArgs, 'amount' | 'date' | 'description' | 'transaction_id'>>;
};

export type PaymentWithdrawalRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentWithdrawalRequest'] = ResolversParentTypes['PaymentWithdrawalRequest']> = {
  account_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bank_account_number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fulfill_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name_as_per_bank_account?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  request_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sort_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WithdrawalRequestStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QardanApplicantResolvers<ContextType = any, ParentType extends ResolversParentTypes['QardanApplicant'] = ResolversParentTypes['QardanApplicant']> = {
  company_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  its_no?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  last_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  loanAllocation?: Resolver<Maybe<ResolversTypes['QardanLoanAllocation']>, ParentType, ContextType>;
  loanRepaid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  phone_no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QardanApplicantListResolvers<ContextType = any, ParentType extends ResolversParentTypes['QardanApplicantList'] = ResolversParentTypes['QardanApplicantList']> = {
  list?: Resolver<Array<Maybe<ResolversTypes['QardanApplicant']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QardanInstallmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['QardanInstallment'] = ResolversParentTypes['QardanInstallment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  applicant_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QardanLoanAllocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['QardanLoanAllocation'] = ResolversParentTypes['QardanLoanAllocation']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  applicant_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  repayment_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  repayment_duration_in_months?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['QardanLoanStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  checkTokenExpiry?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType>;
  getAllDormantHSaccounts?: Resolver<ResolversTypes['HSusersWithTotalBalanceOutput'], ParentType, ContextType, RequireFields<QueryGetAllDormantHSaccountsArgs, 'dateFrom'>>;
  getAllHSaccountsWithBalances?: Resolver<ResolversTypes['HSusersWithTotalBalanceOutput'], ParentType, ContextType, RequireFields<QueryGetAllHSaccountsWithBalancesArgs, 'dateFrom'>>;
  getAllHStransactions?: Resolver<ResolversTypes['HStransactionsOutput'], ParentType, ContextType, RequireFields<QueryGetAllHStransactionsArgs, 'filter'>>;
  getAllHSusers?: Resolver<ResolversTypes['GetAllHSusersOutput'], ParentType, ContextType, Partial<QueryGetAllHSusersArgs>>;
  getAllQardanApplicants?: Resolver<ResolversTypes['GetAllQardanApplicantsOutput'], ParentType, ContextType, RequireFields<QueryGetAllQardanApplicantsArgs, 'filter'>>;
  getAllQardanInstallments?: Resolver<ResolversTypes['GetAllQardanInstallmentsOutput'], ParentType, ContextType, RequireFields<QueryGetAllQardanInstallmentsArgs, 'filter'>>;
  getAllSBQHusers?: Resolver<ResolversTypes['GetAllSBQHusersOutput'], ParentType, ContextType, Partial<QueryGetAllSbqHusersArgs>>;
  getAllWithdrawalRequests?: Resolver<ResolversTypes['GetAllWithdrawalRequestsOutput'], ParentType, ContextType, Partial<QueryGetAllWithdrawalRequestsArgs>>;
  getHSuserInfo?: Resolver<ResolversTypes['HSuserFullInfo'], ParentType, ContextType, RequireFields<QueryGetHSuserInfoArgs, 'userId'>>;
  getHsUserBankAccounts?: Resolver<Array<Maybe<ResolversTypes['HSuserBank']>>, ParentType, ContextType>;
  getHsUserChildAccessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetHsUserChildAccessTokenArgs, 'childUserId'>>;
  getQardanApplicantInfo?: Resolver<ResolversTypes['QardanApplicant'], ParentType, ContextType, RequireFields<QueryGetQardanApplicantInfoArgs, 'applicantId'>>;
  getQardanApplicantsWithOutstandingBalances?: Resolver<ResolversTypes['QardanApplicantList'], ParentType, ContextType, RequireFields<QueryGetQardanApplicantsWithOutstandingBalancesArgs, 'filter'>>;
  getWithdrawalRequestDetails?: Resolver<ResolversTypes['PaymentWithdrawalRequest'], ParentType, ContextType, RequireFields<QueryGetWithdrawalRequestDetailsArgs, 'id'>>;
  loginToHSPortal?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<QueryLoginToHsPortalArgs, 'id' | 'password'>>;
  loginToQardanPortal?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<QueryLoginToQardanPortalArgs, 'id' | 'password'>>;
  loginToSBQHPortal?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryLoginToSbqhPortalArgs, 'email' | 'password'>>;
  sendEmailConfirmationCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verifyHSsecuritycode?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<QueryVerifyHSsecuritycodeArgs, 'hsUserId' | 'verificationCode'>>;
  verifyQardanSecuritycode?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<QueryVerifyQardanSecuritycodeArgs, 'applicantId' | 'verificationCode'>>;
  verifySBQHSecuritycode?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<QueryVerifySbqhSecuritycodeArgs, 'email' | 'verificationCode'>>;
};

export type ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  responseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  warning?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SbqHuserResolvers<ContextType = any, ParentType extends ResolversParentTypes['SBQHuser'] = ResolversParentTypes['SBQHuser']> = {
  access?: Resolver<ResolversTypes['SBQHuserAccess'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SbqHuserAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['SBQHuserAccess'] = ResolversParentTypes['SBQHuserAccess']> = {
  HS_Accounts?: Resolver<ResolversTypes['Access'], ParentType, ContextType>;
  HS_Transactions?: Resolver<ResolversTypes['Access'], ParentType, ContextType>;
  HS_Withdrawal_Requests?: Resolver<ResolversTypes['Access'], ParentType, ContextType>;
  Qardan_Applicants?: Resolver<ResolversTypes['Access'], ParentType, ContextType>;
  Qardan_Installments?: Resolver<ResolversTypes['Access'], ParentType, ContextType>;
  SBQH_Admin_Users?: Resolver<ResolversTypes['Access'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = {
  account_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hsUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  __resolveType: TypeResolveFn<'HSuser' | 'QardanApplicant' | 'SBQHuser', ParentType, ContextType>;
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
};

export type WithdrawalRequestWithHSuserInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['WithdrawalRequestWithHSuserInfo'] = ResolversParentTypes['WithdrawalRequestWithHSuserInfo']> = {
  account_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  request_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  request_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  requested_amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WithdrawalRequestStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AWSEmail?: GraphQLScalarType;
  AWSTimestamp?: GraphQLScalarType;
  Access?: AccessResolvers<ContextType>;
  Account?: AccountResolvers<ContextType>;
  GetAllHSusersOutput?: GetAllHSusersOutputResolvers<ContextType>;
  GetAllQardanApplicantsOutput?: GetAllQardanApplicantsOutputResolvers<ContextType>;
  GetAllQardanInstallmentsOutput?: GetAllQardanInstallmentsOutputResolvers<ContextType>;
  GetAllSBQHusersOutput?: GetAllSbqHusersOutputResolvers<ContextType>;
  GetAllWithdrawalRequestsOutput?: GetAllWithdrawalRequestsOutputResolvers<ContextType>;
  HStransactionsOutput?: HStransactionsOutputResolvers<ContextType>;
  HSuser?: HSuserResolvers<ContextType>;
  HSuserBank?: HSuserBankResolvers<ContextType>;
  HSuserBasicInfo?: HSuserBasicInfoResolvers<ContextType>;
  HSuserFullInfo?: HSuserFullInfoResolvers<ContextType>;
  HSusersWithTotalBalanceOutput?: HSusersWithTotalBalanceOutputResolvers<ContextType>;
  LoginResponse?: LoginResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PaymentWithdrawalRequest?: PaymentWithdrawalRequestResolvers<ContextType>;
  QardanApplicant?: QardanApplicantResolvers<ContextType>;
  QardanApplicantList?: QardanApplicantListResolvers<ContextType>;
  QardanInstallment?: QardanInstallmentResolvers<ContextType>;
  QardanLoanAllocation?: QardanLoanAllocationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Response?: ResponseResolvers<ContextType>;
  SBQHuser?: SbqHuserResolvers<ContextType>;
  SBQHuserAccess?: SbqHuserAccessResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  WithdrawalRequestWithHSuserInfo?: WithdrawalRequestWithHSuserInfoResolvers<ContextType>;
};

