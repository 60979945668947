import React, { FC, useState } from 'react';
import { Dialog, Typography, Grid, CircularProgress, DialogContent, DialogTitle, IconButton } from "@material-ui/core"
import { Button } from '../../';
import { ErrorDialog, SuccessDialog } from '../../Alert';
import { useAppSelector, useAppDispatch } from '../../../reduxStore/store'
import { Close } from '@material-ui/icons';
import HSaccountForm, { FormFields } from '../../Form/HSaccountForm';
import { Response } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { changeHSuserDetails } from '../../../reduxStore/slices/hsPortalSlice';
import { GraphQLResult } from '../../../typedefs';



interface Props {
    // initialValues?: Partial<FormFields>;
    hideFields?: (keyof FormFields)[]
    onSuccess?: () => void
    defaultOpen?: boolean
}

const UpdateDetailsForm: FC<Props> = ({ hideFields = [], onSuccess, defaultOpen = false }) => {
    const [_open, setOpen] = useState(defaultOpen);
    const dispatch = useAppDispatch();
    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    const initialValues: FormFields = {
        id: useAppSelector(s => s.hsPortal.userDetails?.id) || "",
        first_name: useAppSelector(s => s.hsPortal.userDetails?.first_name) || "",
        last_name: useAppSelector(s => s.hsPortal.userDetails?.last_name) || "",
        its_no: useAppSelector(s => s.hsPortal.userDetails?.its_no?.toString()) || "",
        email: useAppSelector(s => s.hsPortal.userDetails?.email) || "",
        phone_no: useAppSelector(s => s.hsPortal.userDetails?.phone_no) || "",
    }

    const handleSubmit = async (values: FormFields) => {
        const { err, res } = await new Promise<{ err?: GraphQLResult<any>, res?: Response }>((resolve, reject) => {
            values.id && dispatch(changeHSuserDetails({
                input: {
                    id: values.id,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    phone_no: values.phone_no,
                    its_no: Number(values.its_no) || undefined,
                },
                callback: (err, res) => { err && reject({ err }); res && resolve({ res }); }
            }))
        })
        if (res?.message) {
            SuccessDialog("Details Updated", "Account details successfully updated.");
            onSuccess && onSuccess()
            handleClose()
        } else if (err?.errors) {
            ErrorDialog("Details cannot be Updated", err.errors[0].message);
        } else if (err) {
            ErrorDialog("Details cannot be Updated", "Some error occurred while updating account details. Please try again.");
            console.log(values);
        }
    }

    return (
        <>
            <Button onClick={handleClickOpen} color='primary'>Update Details</Button>
            <Dialog open={_open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }}>
                <DialogTitle style={{ paddingBottom: "7px" }}  >
                    <Grid container justify='flex-end' alignItems='center' style={{ color: 'inherit' }} >
                        <IconButton title='Close' onClick={handleClose} ><Close /></IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <HSaccountForm
                        hideFields={hideFields}
                        action='UPDATE'
                        initialValues={initialValues}
                        handleSubmit={handleSubmit}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

// const formSchema = yup.object({
//     first_name: yup.string().required('Required').max(255),
//     last_name: yup.string().required('Required').max(255),
//     its_no: yup.string().required('Required').min(8).max(8),
//     email: yup.string().email().required("Required"),
//     country_code: yup.string(),
//     phone_no: yup.string().required("Required"),
// })

// export interface FormFields extends yup.InferType<typeof formSchema> { }

// export interface Props {
// }

// const UpdateDetailsForm: FC<Props> = ({ }) => {
//     const [_open, setOpen] = useState(false);
//     const formInitialValues: FormFields = {
//         first_name: useAppSelector(s => s.hsPortal.userDetails?.first_name) || "",
//         last_name: useAppSelector(s => s.hsPortal.userDetails?.last_name) || "",
//         its_no: useAppSelector(s => s.hsPortal.userDetails?.its_no?.toString()) || "",
//         email: useAppSelector(s => s.hsPortal.userDetails?.email) || "",
//         phone_no: useAppSelector(s => s.hsPortal.userDetails?.phone_no) || "",
//         country_code: ""
//     }

//     const handleClickOpen = () => { setOpen(true) };
//     const handleClose = () => { setOpen(false) };

//     const handleValidation = (values: FormFields) => {
//         const error: { [P in keyof FormFields]?: string; } = {}
//         //// checking and verifying user's mobile number according to the country code 
//         if (!values.country_code) {
//             error.phone_no = "Country code required"
//         }
//         if (values.phone_no && values.country_code) {
//             const isPhoneNumValid = parsePhoneNumberFromString(values.country_code + values.phone_no, "GB")?.isValid();
//             if (!isPhoneNumValid) {
//                 error.phone_no = 'Invalid mobile number'
//             }
//         }

//         return error
//     }

//     const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
//         // const res = await handleSubmit(values);
//         // if (res.successMsg) {
//         //     SuccessDialog("Password Changed", res.successMsg);
//         handleClose()
//         // } else if (res.errorMsg) {
//         //     ErrorDialog("Password Cannot be Changed", res.errorMsg);
//         // }
//         console.log(values);
//         setSubmitting(false);
//     }

//     return (
//         <>
//             <Button onClick={handleClickOpen} color='primary'>Change Password</Button>
//             <Dialog open={_open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
//                 <Formik initialValues={formInitialValues}
//                     validationSchema={formSchema}
//                     onSubmit={onSubmit}
//                     validate={handleValidation}
//                 >
//                     {({ isValid, isSubmitting }) => {
//                         return <Form>
//                             <s.LabelWrap>
//                                 <Typography variant='h5' align='center' >Update Details</Typography>
//                             </s.LabelWrap>
//                             <br />
//                             <Grid item xs={12} sm={6} ><Field required component={TextField} type='text' variant="outlined" name={ } label={ } fullWidth /></Grid>

//                             <br />
//                             <Grid item container direction='column' >
//                                 <Button disabled={(!isValid || isSubmitting)} size='large' color='primary' type='submit' >
//                                     {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Save</div>}
//                                 </Button>
//                             </Grid>
//                         </Form>
//                     }}
//                 </Formik>
//             </Dialog>
//         </>
//     )
// }


export default UpdateDetailsForm
