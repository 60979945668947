import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginToHSPortal, GetHSuserInfo, CheckTokenExpiryHSuser, GetAllHStransactions, VerifyHSsecuritycode, SendEmailConfirmationCode, GetHsUserBankAccounts, GetHsUserChildAccessToken } from '../../../graphql/queries';
import { HSpaymentWithdrawalRequest, ChangeHSuserPassword, ChangeHSuserDetails, AddHsUserBankAccount, UpdateHsUserBankAccount, DeleteHsUserBankAccount } from '../../../graphql/mutations';
import { HSloginResponse } from '../../../graphql/types';
import { FilterOptions, HSuserBank, MutationAddHsUserBankAccountArgs, MutationDeleteHsUserBankAccountArgs, MutationHsPaymentWithdrawalRequestArgs, MutationUpdateHsUserBankAccountArgs, Response, UserType } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { GraphQLResult } from "../../../typedefs";
import { getQueryParam } from "../../../helper";

type LoginToHSPortalProps = { userId: string, password: string, callback?: (err: GraphQLResult<any> | null, res: HSloginResponse | null) => void }
export const loginToHSPortal = createAsyncThunk(
    "query/loginToHSPortal",
    async ({ userId, password }: LoginToHSPortalProps, thunkAPI) => {
        try {
            return await LoginToHSPortal(userId, password)
        } catch (error) {
            console.log("loginToHSPortal-error", error)
            throw JSON.stringify(error);
        }
    }
)

type VerifyHSsecuritycodeProps = { hsUserId: string, verificationCode: string, callback?: (err: GraphQLResult<any> | null, res: HSloginResponse | null) => void }
export const verifyHSsecuritycode = createAsyncThunk(
    "query/verifyHSsecuritycode",
    async ({ hsUserId, verificationCode }: VerifyHSsecuritycodeProps, thunkAPI) => {
        try {
            return await VerifyHSsecuritycode(hsUserId, verificationCode)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const sendEmailConfirmationCode = createAsyncThunk(
    "query/sendEmailConfirmationCode",
    async (data, thunkAPI) => {
        try {
            return await SendEmailConfirmationCode(getQueryParam('child_acc'))(UserType.HsUser);
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const changeHSuserPassword = createAsyncThunk(
    "mutation/changeHSuserPassword",
    async ({ oldPassword, newPassword }: { oldPassword: string, newPassword: string, callback?: (err: GraphQLResult<any> | null, res: Response | null) => void }, thunkAPI) => {
        try {
            const res = await ChangeHSuserPassword(getQueryParam('child_acc'))(oldPassword, newPassword);
            return res;
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const getHSuserInfo = createAsyncThunk(
    "query/getHSuserInfo",
    async ({ userId }: { userId: string }, thunkAPI) => {
        try {
            const res = await GetHSuserInfo(getQueryParam('child_acc'))(UserType.HsUser, { userId });
            return res;
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const getAllHStransactions = createAsyncThunk(
    "query/hsauth/GetAllHStransactions",
    async (filterOptions: FilterOptions, thunkAPI) => {
        try {
            return await GetAllHStransactions(getQueryParam('child_acc'))(UserType.HsUser, filterOptions)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type CheckTokenExpiryHSuserProps = { callback?: (err: GraphQLResult<any> | null, res: HSloginResponse | null) => void }
export const checkTokenExpiryHSuser = createAsyncThunk(
    "query/checkTokenExpiryHSuser",
    async ({ }: CheckTokenExpiryHSuserProps, thunkAPI) => {
        try {
            return await CheckTokenExpiryHSuser(getQueryParam('child_acc'))()
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

interface HsPaymentWithdrawalRequestArgs extends MutationHsPaymentWithdrawalRequestArgs {
    callback?: (err: GraphQLResult<any> | null, res: Response | null) => void
}
export const hsPaymentWithdrawalRequest = createAsyncThunk(
    "mutation/hsPaymentWithdrawalRequest",
    async ({ amount, bank_account_number, name_as_per_bank_account, sort_code, email }: HsPaymentWithdrawalRequestArgs, thunkAPI) => {
        try {
            return await HSpaymentWithdrawalRequest(getQueryParam('child_acc'))(amount, bank_account_number, name_as_per_bank_account, sort_code, email)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)


type ChangeHSuserDetailsProps = { input: { id: string, first_name: string, last_name: string, its_no?: number, email?: string, phone_no?: string }, callback?: (err?: GraphQLResult<any> | null, res?: Response | null) => void }
export const changeHSuserDetails = createAsyncThunk(
    "mutation/hsauth/changeHSuserDetails",
    async ({ input }: ChangeHSuserDetailsProps, thunkAPI) => {
        try {
            return await ChangeHSuserDetails(getQueryParam('child_acc'))(UserType.HsUser, input)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const getHsUserBankAccounts = createAsyncThunk(
    "query/hsauth/getHsUserBankAccounts",
    async (data, thunkAPI) => {
        try {
            return await GetHsUserBankAccounts(getQueryParam('child_acc'))()
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type AddHsUserBankAccountProps = { input: MutationAddHsUserBankAccountArgs, callback?: (err?: GraphQLResult<any> | null, res?: HSuserBank | null) => void }
export const addHsUserBankAccount = createAsyncThunk(
    "mutation/hsauth/addHsUserBankAccount",
    async ({ input }: AddHsUserBankAccountProps, thunkAPI) => {
        try {
            return await AddHsUserBankAccount(getQueryParam('child_acc'))(input)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type UpdateHsUserBankAccountProps = { input: MutationUpdateHsUserBankAccountArgs, callback?: (err?: GraphQLResult<any> | null, res?: Response | null) => void }
export const updateHsUserBankAccount = createAsyncThunk(
    "mutation/hsauth/updateHsUserBankAccount",
    async ({ input }: UpdateHsUserBankAccountProps, thunkAPI) => {
        try {
            return await UpdateHsUserBankAccount(getQueryParam('child_acc'))(input)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type DeleteHsUserBankAccountProps = { input: MutationDeleteHsUserBankAccountArgs, callback?: (err?: GraphQLResult<any> | null, res?: Response | null) => void }
export const deleteHsUserBankAccount = createAsyncThunk(
    "mutation/hsauth/deleteHsUserBankAccount",
    async ({ input }: DeleteHsUserBankAccountProps, thunkAPI) => {
        try {
            return await DeleteHsUserBankAccount(getQueryParam('child_acc'))(input)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type GetHsUserChildAccessTokenProps = { childUserId: string }
export const getHsUserChildAccessToken = createAsyncThunk(
    "mutation/hsauth/getHsUserChildAccessToken",
    async ({ childUserId }: GetHsUserChildAccessTokenProps, thunkAPI) => {
        try {
            return await GetHsUserChildAccessToken(getQueryParam('child_acc'))(childUserId)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)
