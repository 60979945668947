import React, { FC, useState } from 'react';
import { Grid, CircularProgress, Typography, InputAdornment, IconButton, Dialog } from '@material-ui/core';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import * as s from './style';
import { TextField } from 'formik-material-ui';
import { Button } from '../';
import * as yup from 'yup';
import BackDrop from '../BackDrop';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ErrorDialog, SuccessDialog } from '../Alert';

const formSchema = yup.object({
    oldPassword: yup.string().required('Required'),
    newPassword: yup.string().required('Required').min(8, "Password cannot be less than 8 characters"),
    newConfirmPassword: yup.string().required('Required').min(8, "Password cannot be less than 8 characters")
})

export interface FormFields extends yup.InferType<typeof formSchema> { }

export interface Props {
    open?: boolean;
    handleSubmit: (values: FormFields) => Promise<{ successMsg?: string, errorMsg?: string }>
    defaultOpen?: boolean
}

const formInitialValues: FormFields = {
    oldPassword: "",
    newPassword: "",
    newConfirmPassword: ""
}

const ChangePasswordForm: FC<Props> = ({ handleSubmit, open = false, defaultOpen = false }) => {
    const [_open, setOpen] = useState(open || defaultOpen);
    const [showPassword, setShowPassword] = useState({
        oldPassword: false,
        newPassword: false,
        newConfirmPassword: false,
    });

    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };
    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        const res = await handleSubmit(values);
        if (res.successMsg) {
            SuccessDialog("Password Changed", res.successMsg);
            handleClose()
        } else if (res.errorMsg) {
            ErrorDialog("Password Cannot be Changed", res.errorMsg);
        }
        setSubmitting(false);
    }
    const handleValidation = (values: FormFields) => {
        const error: { [P in keyof FormFields]?: string; } = {};
        if (values.newConfirmPassword !== values.newPassword) {
            error.newConfirmPassword = "Password mismatch"
        }
        return error;
    }

    return (
        <>
            {!open && <Button onClick={handleClickOpen} color='primary'>Change Password</Button>}
            <Dialog open={_open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
                <Formik initialValues={formInitialValues}
                    validationSchema={formSchema}
                    onSubmit={onSubmit}
                    validate={handleValidation}
                >
                    {({ isValid, isSubmitting }) => {
                        return <Form style={{ ...s.formStyle(), }} >
                            <BackDrop open={isSubmitting} >
                                <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
                            </BackDrop>
                            {/* /////////////////////////////////////////   login credential fields /////////////////////////////////////// */}
                            <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                                <s.LabelWrap style={{ padding: "10px" }} >
                                    <Typography variant='h5' align='center' >Change Password</Typography>
                                </s.LabelWrap>
                                <br />

                                <Grid item container alignItems='center' >
                                    <Field
                                        required component={TextField} fullWidth name='oldPassword' label='Old Password' variant='outlined'
                                        type={showPassword.oldPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowPassword({ ...showPassword, oldPassword: !showPassword.oldPassword }) }}
                                                    onMouseDown={() => { setShowPassword({ ...showPassword, oldPassword: !showPassword.oldPassword }) }}
                                                >
                                                    {showPassword.oldPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    />
                                </Grid>
                                <br />
                                <Grid item container alignItems='center' >
                                    <Field
                                        required component={TextField} fullWidth name='newPassword' label='New Password' variant='outlined'
                                        type={showPassword.newPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword }) }}
                                                    onMouseDown={() => { setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword }) }}
                                                >
                                                    {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    />
                                </Grid>
                                <br />
                                <Grid item container alignItems='center' >
                                    <Field
                                        required component={TextField} fullWidth name='newConfirmPassword' label='Confirm Password' variant='outlined'
                                        type={showPassword.newConfirmPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowPassword({ ...showPassword, newConfirmPassword: !showPassword.newConfirmPassword }) }}
                                                    onMouseDown={() => { setShowPassword({ ...showPassword, newConfirmPassword: !showPassword.newConfirmPassword }) }}
                                                >
                                                    {showPassword.newConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    />
                                </Grid>
                                <br />

                                <Grid item container direction='column' >
                                    <Button disabled={(!isValid || isSubmitting)} size='large' color='primary' type='submit' >
                                        {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Save</div>}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    }}
                </Formik>
            </Dialog>
        </>
    )
}

export default ChangePasswordForm;
