import React, { FC, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../../theme/colors';
import { displayFlex, screenWidths } from '../../theme/css-generators';
// import { Button, BackDrop } from '../'
// import { IconButton, CircularProgress } from '@material-ui/core';
// import { Close } from '@material-ui/icons';
declare global {
    interface Window {
    }
}
const logo = require('../../assets/logo-gold.png');

export interface Props {
    title?: string,
    name?: string,
    itsNo?: string,
    amount?: number,
}
// {/* <Title>Mohammedi Scheme - محمدي اسکيم</Title> */}
const PaymentAlert: FC<Props> = ({ amount, itsNo, name, title }) => {
    let envelopeRef: HTMLDivElement | null = null;

    /* this useEffect callback adjust the position of the Envelope elem by scrolling to it  */
    useEffect(() => {
        const elemTopPos = envelopeRef?.offsetTop as number; // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetTop
        setTimeout(() => { window.scrollTo({ top: elemTopPos, behavior: 'smooth' }) }, 200)
        // console.log(elemTopPos)
    }, [])


    return (
        <div >
            <Envelope ref={node => envelopeRef = node} >
                <Logo src={logo} alt="logo" />
                <Title >
                    <span style={{ fontFamily: 'Playball' }} >{title?.split(' - ')[0]}</span> - <span style={{ fontFamily: 'Al-kanz' }}>{title?.split(' - ')[1]}</span>
                </Title>
                <Title subTitle >Qardan Hasana</Title>
                <br /><br />
                <FieldPlaceHolder fieldnum={1}>
                    <span style={{ width: "100%" }} >{name}</span>
                    <span style={{ width: "43px", textAlign: 'end' }} >:نام</span>
                </FieldPlaceHolder>
                <br />
                <FieldPlaceHolder fieldnum={2}>
                    <span style={{ width: "115px" }} >ITS no:</span>
                    <span style={{ width: "100%" }}>{itsNo}</span>
                </FieldPlaceHolder>
                <br />
                <FieldPlaceHolder fieldnum={2}>
                    <span style={{ fontSize: '30px', width: '25px' }} >£:</span>
                    <span style={{ borderBottom: 'solid', minWidth: '150px' }} >{amount?.toFixed(2)}</span>
                </FieldPlaceHolder>
                <br /><br />
            </Envelope>
            <br /><br />
            <Bottom >
                <h2 style={{ textAlign: 'center' }} >Your payment has been successfully received.</h2>
                <br />
                <h2 style={{ textAlign: 'center' }} >Shukran for contributing in {title?.split(' - ')[0]}.</h2>
            </Bottom>
            <br /><br />
        </div>
    )
}

export default PaymentAlert;

const swipeDown = keyframes`
    0% { transform: translate(0, -50px); opacity:0; }
    100% { transform: translate(0, 0px); opacity:1; }
`

const Logo = styled.img`
    width: 100px;

    @media (max-width: ${screenWidths._500px}){
        width: 70px;
    }
`;

const fadeIn = keyframes`
    0% { opacity:0; }
    100% { opacity:1; }
`

const Envelope = styled.div`
    max-width: 600px;
    padding: 30px;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: 0 0 20px #bdbdbd;
    background-color: whitesmoke;
    font-size: larger;
    animation: ${swipeDown} ease-out forwards 1s;
    ${displayFlex({ dir: 'column' })};

    @media (max-width: ${screenWidths._500px}){
        padding: 23px;
    }
`;


const Title = styled.h2.attrs(() => ({
    fontLarge: 35,
    fontMedium: 28,

})) <{ subTitle?: boolean }>`
    font-size: ${({ fontLarge, subTitle }) => !subTitle ? fontLarge : fontLarge - 7}px;
    margin: 10px 0;
    text-align:center;

    @media (max-width: ${screenWidths._500px}){
        font-size: ${({ fontMedium, subTitle }) => !subTitle ? fontMedium : fontMedium - 5}px;
    }
`;



const FieldPlaceHolder = styled.div<{ fieldnum: number }>`
    width: 100%;
    display: flex;
    font-size: 27px;
    ${displayFlex({ align: 'normal' })};
    &>span:nth-child(${({ fieldnum }) => (`${fieldnum}`)}){
        border-bottom: 5px dotted ${colors[4]};
        margin: 0 3px;
        text-align:center;
    }

    @media (max-width: ${screenWidths._500px}){
        font-size: 22px;
    }

`;


const Bottom = styled.div`
    animation: ${fadeIn} 2s forwards;
    font-family: 'Playball';
    font-size: 34px;

    @media (max-width: ${screenWidths._500px}){
        font-size: 20px;
    }
`