import React, { FC, Fragment } from 'react';
import { countries } from './countries.json';
import { SecurityOffered } from './ApplyQardanHasana';
import { AccountCircle, EuroSymbol, Business, LocalAtm, Description, Person } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import moment from 'moment';
// import { InputHTMLAttributes } from 'react';
// import { MenuItem, Typography, Grid, GridProps, Radio, FormGroup, TextField as MiTextField, InputAdornment, FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';
// import { TextField, Checkbox, } from 'formik-material-ui';
// import { Form, Formik, Field, FormikHelpers, FormikProps } from 'formik';

export interface FieldInterface {
    type: "button" | "checkbox" | "color" | "date" | "custom" | "text-note" | "datetime-local" | "text-area" | "fieldGroup" | "email" | "file" | "hidden" | "image" | "month" | "number" | "semi-number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "select" | "tel" | "text" | "time" | "url" | "week";
    name: string;
    label: string;
    /**
     * only works if "type" is "fieldGroup"
     */
    fieldGroup?: FieldInterface[];
    notRequried?: boolean;
    value?: string;
    /**
     * options will only for "type" is equal to "radio" and "select"
     */
    options?: string[];
    inputProps?: { [key: string]: string | number | boolean | React.ReactNode };
    props?: { [key: string]: string | number | boolean | React.ReactNode },
    /**
     * CustomField will only works if "type" is "custom"
     */
    CustomField?: FC<{ label: string, name: string, onChange?: (v: any) => void, value?: any }>
}

export interface FormInterface {
    formGroup: {
        name: string,
        labelIcon: React.FC,
        label: string,
        fields: FieldInterface[]
    }[]
}


export function applyQardanHasana(): FormInterface {
    return {
        formGroup: [
            {
                name: "professional",
                labelIcon: () => <span><Business fontSize='large' /></span>,
                label: "Professional Details",
                fields: [
                    { name: "employment_types", type: "radio", label: 'Business / Employer details', options: ["Business", "Employed"] },
                    {
                        type: "fieldGroup",
                        name: "organisation_detials",
                        label: "Organisation Detials",
                        fieldGroup: [
                            { name: "organisation_name", type: "text", label: 'Company/Organisation name', value: "" },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        label: "Address",
                        name: "address",
                        fieldGroup: [
                            { type: "text", name: 'street_address', label: 'Street Address', value: "" },
                            { type: "text", name: 'city', label: 'City', value: "", props: { sm: 6, md: 4 } },
                            { type: "select", name: 'country', label: 'Country', options: countries.map(({ name }) => name), props: { sm: 6, md: 4 }, value: 'United Kingdom' },
                            { type: "text", name: 'post_code', label: 'ZIP / Postal Code', value: "", props: { sm: 6, md: 4 } },
                        ]
                    },
                    {
                        type: 'fieldGroup',
                        label: 'Job Details',
                        name: 'job_details',
                        fieldGroup: [
                            { type: "text", name: 'job_title', label: 'Job Title', value: "", props: { sm: 8 } },
                            { type: "date", name: 'joining_date', label: 'Joining/Establishment date', value: "", props: { sm: 4 } },
                            { type: "select", name: 'employment_status', options: ["Employed full time", "Employed part time", "Employed temporary contract", "Self-employed", "Retired", "Student", "Other"], label: 'Employment status', value: "", props: { sm: 4 } },
                        ]
                    },
                ]
            },
            {
                name: "personal",
                labelIcon: () => <span><AccountCircle fontSize='large' /></span>,
                label: "Personal Details",
                fields: [
                    {
                        type: "fieldGroup",
                        name: "other_fields",
                        label: "Other Fields",
                        fieldGroup: [
                            /* hidden field of date_of_application  */
                            { type: "date", name: 'date_of_application', label: 'Date Of Application', value: moment().format("DD-MM-yyyy"), props: { style: { display: 'none' } } },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        name: "name",
                        label: "Name",
                        fieldGroup: [
                            { name: "title", type: "select", label: 'Title', props: { sm: 6, md: 3 }, options: ["Bhai", "Behen", "Shaikh", "Mulla"] },
                            { name: "first_name", type: "text", label: 'First Name', value: "", props: { sm: 6, md: 3 } },
                            { name: "middle_name", type: "text", label: 'Middle Name', value: "", notRequried: true, props: { sm: 6, md: 3 } },
                            { name: "last_name", type: "text", label: 'Last Name', value: "", props: { sm: 6, md: 3 } },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        label: "DemoGraph",
                        name: 'demograph',
                        fieldGroup: [
                            { type: "date", name: 'date_of_birth', label: 'Date Of Birth', value: "", props: { sm: 6 } },
                            { type: "text", name: 'profession', label: 'Profession', value: "", props: { sm: 6 } },
                            { type: "text", name: 'its_no', label: 'ITS number', value: "", props: { sm: 6 } },
                        ]
                    },
                    { type: "file", name: 'applicant_photo', label: 'Applicant Photo*', value: "", props: { accept: ".png, .jpg, .jpeg", fieldDescription: <>Upload a passport size photo wearing a topi / rida <br /> Accepted file types: jpg, jpeg, png, Max. file size: 2 MB</> } },
                    { type: "file", name: 'applicant_passport', label: 'Applicant Passport*', value: "", props: { accept: ".png, .jpg, .jpeg, .pdf, gif", fieldDescription: <>Upload a copy of your passport <br /> Accepted file types: jpg, jpeg, gif, png, pdf, Max. file size: 2 MB</> } },
                    {
                        type: "fieldGroup",
                        label: "Address",
                        name: "address",
                        fieldGroup: [
                            { type: "text", name: 'street_address', label: 'Street Address', value: "" },
                            { type: "text", name: 'city', label: 'City', value: "", props: { sm: 6, md: 4 } },
                            { type: "select", name: 'country', label: 'Country', options: countries.map(({ name }) => name), props: { sm: 6, md: 4 }, value: 'United Kingdom' },
                            { type: "text", name: 'post_code', label: 'ZIP / Postal Code', value: "", props: { sm: 6, md: 4 } },
                        ]
                    },
                    { name: "staying_at_current_address_for_more_than_3_years", type: "radio", label: 'Have you been staying at the above address for more than 3 years?', options: ["Yes", "No"], props: { sm: 12 } },
                    {
                        type: "fieldGroup",
                        label: "Contact",
                        name: "contact",
                        fieldGroup: [
                            { name: "phone", type: "tel", label: 'Phone', value: "", props: { sm: 4 } },
                            { name: "email", type: "email", label: 'Email', value: "", props: { sm: 4 } },
                            { name: "mobile", type: "tel", label: 'Mobile', value: "", props: { sm: 4 } },
                        ]
                    },
                    { name: "martial_status", type: "radio", label: 'Martial Status', options: ["Single", "Married", "Widower"], props: { sm: 6 } },
                    {
                        type: "fieldGroup",
                        label: "Society Status",
                        name: "society_status",
                        fieldGroup: [
                            { name: "edu_qualf", type: "text", label: 'Educational Qualification', value: "", props: { sm: 4 } },
                            { name: "offered_wajebat", type: "select", label: 'Offered Wajebat', options: ["Yes", "No"], props: { sm: 4 } },
                            { name: "offered_sabeel", type: "select", label: 'Offered Sabeel', options: ["Yes", "No"], props: { sm: 4 } },
                        ]
                    },
                    { name: "member_of_husain_sheme", type: "radio", label: 'Member of Husain Scheme (Al Tawfeer al Mubarak)?', options: ["Yes", "No"] },
                    { type: "file", name: 'safai_chitthi', label: 'Upload safai chitthi', notRequried: true, props: { accept: ".png, .jpg, .jpeg, .pdf, gif", fieldDescription: "Accepted file types: jpg, jpeg, gif, png, pdf, Max. file size: 2 MB" } },

                ],
            },
            {
                name: "qardan_hasana",
                labelIcon: () => <span style={{ fontSize: "30px" }} ><b>£</b></span>,
                label: "Qardan Hasana Details",
                fields: [
                    {
                        type: "fieldGroup",
                        name: "qardan_details",
                        label: "Qardan Detials",
                        fieldGroup: [
                            { type: "select", name: "qardan_hasana_type", label: 'Type of Qardan Hasana', value: "", options: ['Business'], props: { sm: 4 } },
                            { type: "semi-number", name: "total_finance_required", label: 'Total finance required for the business', value: "", props: { sm: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "qardan_hasana_amount", label: 'Amount of Qardan Hasana required', value: "", props: { sm: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "text", name: "remaining_sum_to_be_obtained_from", label: 'Remaining sum to be obtained from', value: "", props: { sm: 4 } },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        name: "additional_detail",
                        label: "Additional Details",
                        fieldGroup: [
                            { type: "text-area", name: "details", label: 'Provide additional details', value: "", props: { rows: 6 } },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        name: "qardan_payment_details",
                        label: "Qardan Payment Details",
                        fieldGroup: [
                            { type: "text", name: "amount_to_be_repaid_monthly", label: "Amount to be repaid monthly", props: { sm: 6, md: 4 } },
                            { type: "text", name: "num_of_months", label: "For how many months", props: { sm: 6, md: 4 } },
                            { type: "text", name: "name_on_cheque", label: "Cheque to be made under the name of", props: { sm: 6, md: 4 } },
                        ]
                    },
                    { type: 'custom', name: "Jewellery_value", notRequried: true, label: "Security offered", CustomField: SecurityOffered, },
                    {
                        type: 'fieldGroup',
                        label: "Business Qardan Details",
                        name: 'business_qardan_details',
                        fieldGroup: [
                            { type: 'text-note', name: "", props: { variant: "h6" }, label: "Business Qardan Details" },
                            { type: "semi-number", name: "annual_sales", label: 'Annual sales', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "annual_expenses", label: 'Annual expenses', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "annual_profit_loss_gross", label: 'Annual profit/loss (gross)', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "annual_profit_loss_net", label: 'Annual profit/loss (net)', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "total_net_assets", label: 'Total net assets', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                        ],
                    },
                    {
                        type: 'fieldGroup',
                        label: "Project ﬁgures for new/existing business",
                        name: 'project_ﬁgures_for_business',
                        fieldGroup: [
                            { type: 'text-note', name: "", props: { variant: "h6" }, label: "Project ﬁgures for new/existing business" },
                            { type: "semi-number", name: "projected_annual_sales", label: 'Projected annual sales', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "projected_annual_expenses", label: 'Projected annual expenses', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "projected_annual_profit_loss_gross", label: 'Projected annual profit/loss (gross)', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "projected_annual_profit_loss_net", label: 'Projected annual profit/loss (net)', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "projected_total_net_assets", label: 'Projected total net assets', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "text-area", name: "business_notes", label: 'Business notes', value: "", props: { rows: 6 } }
                        ],
                    },
                    { type: "file", name: 'supporting_documents', label: 'Upload supporting documents', notRequried: true, props: { accept: "*/*", multiple: true, fieldDescription: <>i.e. Business Plan, Cash Flow, Bank Statements.<br /> If you need to upload multiple documents, please upload them as a zip file. Max file size: 2 MB.</> } },
                ],
            },
            {
                name: "financial",
                labelIcon: () => <span><Description fontSize='large' /></span>,
                label: "Financial Details",
                fields: [
                    {
                        type: 'fieldGroup',
                        label: "Income and Saving",
                        name: 'income_saving',
                        fieldGroup: [
                            { type: 'text-note', name: "", props: { variant: "h6" }, label: "Income and Savings" },
                            { type: "semi-number", name: "month_income", label: 'Net monthly income (after tax national insurance)', props: { sm: 6 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "personal_savings", label: 'Personal Savings', props: { sm: 6 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } }
                        ],
                    },
                    { name: "receive_any_benefits_from_any_government", type: "radio", label: 'Do you receive any benefits from any government body or local borough?', options: ["Yes", "No"] },
                    { name: "expect_your_income_to_remain_at_same_level", type: "radio", label: 'Do you expect your income to remain at same level for next 12 months', options: ["Yes", "No"] },
                    {
                        type: 'fieldGroup',
                        label: "Monthly outgoings",
                        name: 'monthly_outgoings',
                        fieldGroup: [
                            { type: 'text-note', name: "", props: { variant: "h6" }, label: "Monthly outgoings" },
                            { type: "semi-number", name: "rent", label: 'Rent', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "text", name: "rates_taxes", label: 'Rates & taxes', props: { sm: 6, md: 4, fieldDescription: "Council tax and any other rates and taxes" } },
                            { type: "text", name: "utilities", label: 'Utilities', props: { sm: 6, md: 4, } },
                            // { type: "semi-number", name: "household", label: 'Household', props: { sm: 6, md: 4 } },
                            { type: "semi-number", name: "other_expenses", label: 'Other expenses', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "total_outgoings", label: 'Total outgoings', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { type: "semi-number", name: "number_of_dependents", label: 'Number of dependents', props: { sm: 6, md: 4 } },
                        ]
                    },
                    { type: "radio", name: "qardan_hasana_outstanding", label: 'Do you have any Qardan Hasana outstanding?', options: ['Yes', 'No'] },
                    { type: "radio", name: "dealing_in_interest", label: 'Are you dealing in interest (Riba) of any sort?', options: ['Yes', 'No'] },
                ]
            },
            {
                name: "guarantor",
                labelIcon: () => <span><Person fontSize='large' /></span>,
                label: "Guarantor Details",
                fields: [
                    { type: 'text-note', name: "form_description", props: {}, label: "We the undersigned having read this application form, support the application of Qardan Hasana by the applicant. We understand that we are co-debtors and agree to repay this loan if the principle debtor fails to do so." },

                    /////////////////////////    guarantor_1 ////////////////////////////////////
                    { type: 'text-note', name: "guarantor_1", props: { variant: "h6" }, label: "Guarantor 1" },
                    {
                        type: "fieldGroup",
                        name: "name_g1",
                        label: "Name",
                        fieldGroup: [
                            { name: "title", type: "select", label: 'Title', props: { sm: 6, md: 3 }, options: ["Bhai", "Behen", "Shaikh", "Mulla"] },
                            { name: "first_name", type: "text", label: 'First Name', value: "", props: { sm: 6, md: 3 } },
                            { name: "middle_name", type: "text", label: 'Middle Name', notRequried: true, value: "", props: { sm: 6, md: 3 } },
                            { name: "last_name", type: "text", label: 'Last Name', value: "", props: { sm: 6, md: 3 } },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        label: "DemoGraph",
                        name: 'demograph_g1',
                        fieldGroup: [
                            { type: "email", name: 'email', label: 'Email', value: "", props: { sm: 6 } },
                            { type: "text", name: 'its_no', label: 'ITS number', value: "", props: { sm: 6 } },
                        ]
                    },
                    // { type: 'file', name: 'guarantor_1_photo', label: "Guarantor 1 photo*", props: { fieldDescription: "Upload a passport size photo wearing a topi / rida" } },
                    { type: "file", name: 'guarantor_1_photo', label: 'Guarantor 1 Photo*', value: "", props: { accept: ".png, .jpg, .jpeg", fieldDescription: <>Upload a passport size photo wearing a topi / rida <br /> Accepted file types: jpg, jpeg, png, Max file size: 2 MB</> } },
                    { type: "file", name: 'guarantor_1_passport', label: 'Guarantor 1 Photo ID*', value: "", props: { accept: ".png, .jpg, .jpeg, .pdf, gif", fieldDescription: <>i.e. Passport, Driver’s License, Govt Issued ID or ITS Card.<br /> Accepted file types: jpg, jpeg, gif, png, pdf, Max file size: 2 MB</> } },
                    {
                        type: "fieldGroup",
                        label: "Address",
                        name: "address_g1",
                        fieldGroup: [
                            { type: "text", name: 'street_address', label: 'Street Address', value: "" },
                            { type: "text", name: 'city', label: 'City', value: "", props: { sm: 6, md: 4 } },
                            { type: "select", name: 'country', label: 'Country', options: countries.map(({ name }) => name), props: { sm: 6, md: 4 }, value: 'United Kingdom' },
                            { type: "text", name: 'post_code', label: 'ZIP / Postal Code', value: "", props: { sm: 6, md: 4 } },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        label: "Contact Details",
                        name: "contact_g1",
                        fieldGroup: [
                            { type: "tel", name: 'phone', label: 'Phone', value: "", props: { sm: 6, md: 3 } },
                            { type: "tel", name: 'mobile', label: 'Mobile', value: "", props: { sm: 6, md: 3 } },
                            { type: "text", name: 'position_in_community', label: 'Position in the community held', value: "", props: { sm: 6, md: 6 } },
                        ]
                    },
                    {
                        type: "text-note", name: "guarantor-1-note", props: { style: { color: "red" } },
                        label: "Please leave the below fields blank. We will ask your guarantor to fill out the details.",
                    },
                    {
                        type: "fieldGroup",
                        label: "Bank Details",
                        name: "bank_g1",
                        fieldGroup: [
                            { notRequried: true, type: "text", name: 'bank_name', label: 'Bank Name', value: "", props: { sm: 6, md: 4 } },
                            { notRequried: true, type: "text", name: 'account_name', label: 'A/C Name', value: "", props: { sm: 6, md: 4 } },
                            { notRequried: true, type: "tel", name: 'account_no', label: 'A/C number', value: "", props: { sm: 6, md: 4 } },
                            { notRequried: true, type: "tel", name: 'sort_code', label: 'Sort Code', value: "", props: { sm: 6, md: 4 } },
                        ]
                    },
                    {
                        type: 'fieldGroup',
                        label: "Income and Saving",
                        name: 'income_saving_g1',
                        fieldGroup: [
                            { notRequried: true, type: 'text-note', name: "", props: { variant: "h6" }, label: "Income and Savings" },
                            { notRequried: true, type: "semi-number", name: "month_income", label: 'Net monthly income (after tax national insurance)', props: { sm: 12 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                        ],
                    },
                    { notRequried: true, name: "receive_any_benefits_from_any_government_g1", type: "radio", label: 'Do you receive any benefits from any government body or local borough?', options: ["Yes", "No"] },
                    { notRequried: true, name: "expect_your_income_to_remain_at_same_level_g1", type: "radio", label: 'Do you expect your income to remain at same level for next 12 months', options: ["Yes", "No"] },
                    {
                        type: 'fieldGroup',
                        label: "Monthly outgoings",
                        name: 'monthly_outgoings_g1',
                        fieldGroup: [
                            { notRequried: true, type: 'text-note', name: "", props: { variant: "h6" }, label: "Monthly outgoings" },
                            { notRequried: true, type: "semi-number", name: "rent", label: 'Rent', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { notRequried: true, type: "text", name: "rates_taxes", label: 'Rates & taxes', props: { sm: 6, md: 4, fieldDescription: "Council tax and any other rates and taxes" } },
                            { notRequried: true, type: "text", name: "utilities", label: 'Utilities', props: { sm: 6, md: 4, } },
                            { notRequried: true, type: "semi-number", name: "other_expenses", label: 'Other expenses', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { notRequried: true, type: "semi-number", name: "total_outgoings", label: 'Total outgoings', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { notRequried: true, type: "semi-number", name: "number_of_dependents", label: 'Number of dependents', props: { sm: 6, md: 4 }, inputProps: {} },
                        ]
                    },

                    /////////////////////////    guarantor_2 ////////////////////////////////////
                    { type: 'text-note', name: "guarantor_2", props: { variant: "h6" }, label: "Guarantor 2" },
                    {
                        type: "fieldGroup",
                        name: "name_g2",
                        label: "Name",
                        fieldGroup: [
                            { name: "title", type: "select", label: 'Title', props: { sm: 6, md: 3 }, options: ["Bhai", "Behen", "Shaikh", "Mulla"] },
                            { name: "first_name", type: "text", label: 'First Name', value: "", props: { sm: 6, md: 3 } },
                            { name: "middle_name", type: "text", label: 'Middle Name', notRequried: true, value: "", props: { sm: 6, md: 3 } },
                            { name: "last_name", type: "text", label: 'Last Name', value: "", props: { sm: 6, md: 3 } },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        label: "DemoGraph",
                        name: 'demograph_g2',
                        fieldGroup: [
                            { type: "email", name: 'email', label: 'Email', value: "", props: { sm: 6 } },
                            { type: "text", name: 'its_no', label: 'ITS number', value: "", props: { sm: 6 } },
                        ]
                    },
                    { type: "file", name: 'guarantor_2_photo', label: 'Guarantor 2 Photo*', value: "", props: { accept: ".png, .jpg, .jpeg", fieldDescription: <>Upload a passport size photo wearing a topi / rida <br /> Accepted file types: jpg, jpeg, png, Max file size: 2 MB</> } },
                    { type: "file", name: 'guarantor_2_passport', label: 'Guarantor 2 Photo ID*', value: "", props: { accept: ".png, .jpg, .jpeg, .pdf, gif", fieldDescription: <>i.e. Passport, Driver’s License, Govt Issued ID or ITS Card.<br /> Accepted file types: jpg, jpeg, gif, png, pdf, Max file size: 2 MB</> } },
                    {
                        type: "fieldGroup",
                        label: "Address",
                        name: "address_g2",
                        fieldGroup: [
                            { type: "text", name: 'street_address', label: 'Street Address', value: "" },
                            { type: "text", name: 'city', label: 'City', value: "", props: { sm: 6, md: 4 } },
                            { type: "select", name: 'country', label: 'Country', options: countries.map(({ name }) => name), props: { sm: 6, md: 4 }, value: 'United Kingdom' },
                            { type: "text", name: 'post_code', label: 'ZIP / Postal Code', value: "", props: { sm: 6, md: 4 } },
                        ]
                    },
                    {
                        type: "fieldGroup",
                        label: "Contact Details",
                        name: "contact_g2",
                        fieldGroup: [
                            { type: "tel", name: 'phone', label: 'Phone', value: "", props: { sm: 6, md: 3 } },
                            { type: "tel", name: 'mobile', label: 'Mobile', value: "", props: { sm: 6, md: 3 } },
                            { type: "text", name: 'position_in_community', label: 'Position in the community held', value: "", props: { sm: 6, md: 6 } },
                        ]
                    },
                    {
                        type: "text-note", name: "guarantor-2-note", props: { style: { color: "red" } },
                        label: "Please leave the below fields blank. We will ask your guarantor to fill out the details.",
                    },
                    {
                        type: "fieldGroup",
                        label: "Bank Details",
                        name: "bank_g2",
                        fieldGroup: [
                            { notRequried: true, type: "text", name: 'bank_name', label: 'Bank Name', value: "", props: { sm: 6, md: 4 } },
                            { notRequried: true, type: "text", name: 'account_name', label: 'A/C Name', value: "", props: { sm: 6, md: 4 } },
                            { notRequried: true, type: "tel", name: 'account_no', label: 'A/C number', value: "", props: { sm: 6, md: 4 } },
                            { notRequried: true, type: "tel", name: 'sort_code', label: 'Sort Code', value: "", props: { sm: 6, md: 4 } },
                        ]
                    },
                    {
                        type: 'fieldGroup',
                        label: "Income and Saving",
                        name: 'income_saving_g2',
                        fieldGroup: [
                            { notRequried: true, type: 'text-note', name: "", props: { variant: "h6" }, label: "Income and Savings" },
                            { notRequried: true, type: "semi-number", name: "month_income", label: 'Net monthly income (after tax national insurance)', props: { sm: 12 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                        ],
                    },
                    { notRequried: true, name: "receive_any_benefits_from_any_government_g2", type: "radio", label: 'Do you receive any benefits from any government body or local borough?', options: ["Yes", "No"] },
                    { notRequried: true, name: "expect_your_income_to_remain_at_same_level_g2", type: "radio", label: 'Do you expect your income to remain at same level for next 12 months', options: ["Yes", "No"] },
                    {
                        type: 'fieldGroup',
                        label: "Monthly outgoings",
                        name: 'monthly_outgoings_g2',
                        fieldGroup: [
                            { notRequried: true, type: 'text-note', name: "", props: { variant: "h6" }, label: "Monthly outgoings" },
                            { notRequried: true, type: "semi-number", name: "rent", label: 'Rent', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { notRequried: true, type: "text", name: "rates_taxes", label: 'Rates & taxes', props: { sm: 6, md: 4, fieldDescription: "Council tax and any other rates and taxes" } },
                            { notRequried: true, type: "text", name: "utilities", label: 'Utilities', props: { sm: 6, md: 4 } },
                            { notRequried: true, type: "semi-number", name: "other_expenses", label: 'Other expenses', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { notRequried: true, type: "semi-number", name: "total_outgoings", label: 'Total outgoings', props: { sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            { notRequried: true, type: "semi-number", name: "number_of_dependents", label: 'Number of dependents', props: { sm: 6, md: 4 }, inputProps: {} },
                        ]
                    },

                ]
            }
        ]
    }

}
