// ref https://medium.com/javascript-in-plain-english/stripe-payment-form-with-reactjs-and-material-ui-part-4-118e60fca962
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
import React, { FC, useRef, useImperativeHandle, useEffect, useCallback } from 'react';
import { CardCvcElementComponent, CardExpiryElementComponent, CardNumberElementComponent, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { FormFields as TS_FormFields } from './TS_PaymentForm';
import { FormFields as MS_FormFields } from './MS_PaymentForm';
import { FormikErrors } from 'formik';

export interface Props {
    component: CardCvcElementComponent | CardExpiryElementComponent | CardNumberElementComponent,
    inputRef: any,
    setErrors: (error: FormikErrors<TS_FormFields | MS_FormFields>) => void,
    fieldName: string,
    errors: FormikErrors<TS_FormFields | MS_FormFields>,
}

const StripeInput: FC<Props> = ({ component: Component, inputRef, setErrors, errors, fieldName, ...other }) => {
    const elementRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(inputRef, () => ({
        focus: () => elementRef.current?.focus
    }));

    const onchange = useCallback((e) => {
        // if (e.error.message) {
        //     setErrors({ [fieldName]: e.error.message });
        // console.log(e)
        if (!e.complete) {
            setErrors({ ...errors, [fieldName]: e.error?.message || "Incomplete" })
        } else if (e.complete) {
            setErrors({ ...errors })
        }
    }, [])

    // useEffect(() => {
    //     setErrors({ [fieldName]: 'Required' })
    // }, [])

    return (
        <Component
            onReady={element => (elementRef.current = element)} {...other}
            onChange={onchange}
        // onEscape={(e) => console.log("On escape", e)}
        // onFocus={(e) => console.log("On focus", e)}
        // options={{}}
        />
    );
}

export default StripeInput;


export const CARD_ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};