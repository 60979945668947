import { combineReducers } from '@reduxjs/toolkit';
import hsPortalReducer from './slices/hsPortalSlice';
import sbqhPortalReducer from './slices/sbqhPortalSlice';
import qardanPortalReducer from './slices/qardanPortalSlice';

const parentReducer = combineReducers({
    hsPortal: hsPortalReducer,
    sbqhPortal: sbqhPortalReducer,
    qardanPortal: qardanPortalReducer,
})

export default parentReducer;