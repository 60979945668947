
const AWS_REGION = process.env.AWS_REGION;
const APPSYNC_API_ENDPOINT = process.env.APPSYNC_API_ENDPOINT;
const APPSYNC_API_KEY = process.env.APPSYNC_API_KEY;


const awsmobile = {
    "aws_project_region": AWS_REGION,
    "aws_appsync_graphqlEndpoint": APPSYNC_API_ENDPOINT,
    "aws_appsync_region": AWS_REGION,
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": APPSYNC_API_KEY
};


export default awsmobile;