import axios, { AxiosError } from 'axios';
import { Body, BodyRes } from '../../../aws-cdk/lambda-fns/husain-scheme-new-account';
import { endpoints } from '../../app-config.json';

type ResponseData = {
    response?: BodyRes
    error?: AxiosError<BodyRes>
}


export const createNewAccount = async (body: Body): Promise<ResponseData> => {
    const url = endpoints['husain-scheme-new-account'];
    try {
        const { data } = await axios.post<{}, { data: BodyRes }>(url, body, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        console.log(data);
        return { response: data }

    } catch (error) {
        console.log(error);
        return { error } as any
    }
}