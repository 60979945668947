import styled from 'styled-components';

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;

  >.__pagelayout_header{
    flex-shrink: 0;
  }
  >.__pagelayout_main{
    flex: 1 0 auto;
  }

  >.__pagelayout_footer{
    flex-shrink: 0;
  }
`