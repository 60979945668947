import styled from 'styled-components';
import colors from '../../theme/colors';
import { CSSProperties } from 'react';
import { displayFlex } from '../../theme/css-generators';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const LabelWrap = styled.div`
    ${displayFlex({})}
    background-color:${colors[7]}; /*colors[6] */
    padding: 2px 0px;
    color: ${colors[4]}; 
    &>span{
        margin: 10px;
    }
`

/* M-Ui style */
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fieldSet: { border: '1px solid rgba(0, 0, 0, 0.1)', padding: '3px' },
        // withoutLabel: { marginTop: theme.spacing(3), },
        // personTitle: { width: '13ch', },
        // country: { width:'100%' },
    }),
);

export const formStyle = () => ({
    backgroundColor: 'white',
    boxShadow: "2px 2px 10px rgb(0,0,0,0.3)",
    padding: '15px',
    borderRadius: '5px'
} as CSSProperties);


export const TotalAmount = styled.div`
    ${displayFlex({})}
    >span{margin-left: 30px;}
    >div{
        width: 100%; height: 60px;
        padding: 10px;
        margin-left: 5px;
        border: 1px solid lightgrey;
        border-radius: 5px;
        ${displayFlex({ justify: 'space-between' })}
        
        &>p{
            font-size: 15px;
        }
    }
`
