import axios, { AxiosError } from 'axios';
import { } from '../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import FORM_VALUES from '../../../aws-cdk/lambda-fns/apply-qardan-hasana/form-fields.json';
import { FileFields } from '../../../aws-cdk/lambda-fns/apply-qardan-hasana';
import { GeneratePDFconfig } from '../../../aws-cdk/lambda-fns/commonTypeDefs';
import { endpoints } from '../../app-config.json';
import { ErrorDialog } from '../components/Alert';
import { getFileExt } from '../helper';
// https://stackoverflow.com/questions/8608724/how-to-zip-files-using-javascript
import JSZip from 'jszip';

export const applyQardanHasana = async ({ formvalues }: { formvalues: typeof FORM_VALUES }) => {
    // const config = btoa(JSON.stringify({ param, type }));
    const jsonFormvalues = JSON.stringify(formvalues);
    try {
        const formdata = new FormData();
        formdata.append("formvalues", jsonFormvalues);

        /* making a zip file of supporting documents if available */
        const supportingDocuments = formvalues.qardan_hasana.supporting_documents as any;
        let supportingDocumentsZip: Blob | undefined;
        if (supportingDocuments instanceof FileList) {
            const zip = new JSZip();
            for (let x = 0; x < supportingDocuments.length; x++) {
                zip.file(supportingDocuments[x].name, supportingDocuments[x], { compression: "DEFLATE", compressionOptions: { level: 9 } });
            }
            supportingDocumentsZip = await zip.generateAsync({ type: "blob" });
        }

        /* making a zip file of required documents */
        const requiredDocumentsZip = new JSZip();
        Object.entries({
            "applicant_passport": formvalues.personal.applicant_passport as any,
            "safai_chitthi": formvalues.personal.safai_chitthi as any,
            "guarantor_1_passport": formvalues.guarantor.guarantor_1_passport as any,
            "guarantor_2_passport": formvalues.guarantor.guarantor_2_passport as any,
        }).forEach(([name, file]) => {
            if (file instanceof File)
                requiredDocumentsZip.file(name + getFileExt(file.name), file, { compression: "DEFLATE", compressionOptions: { level: 9 } });
        })

        /* merging all files in formdata object */
        const files: FileFields = {
            "applicant_photo": formvalues.personal.applicant_photo,
            "guarantor_1_photo": formvalues.guarantor.guarantor_1_photo,
            "guarantor_2_photo": formvalues.guarantor.guarantor_2_photo,
            "required_documents": await requiredDocumentsZip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions: { level: 9 } }),
            "supporting_documents": supportingDocumentsZip
            // "applicant_passport": formvalues.personal.applicant_passport,
            // "safai_chitthi": formvalues.personal.safai_chitthi,
            // "supporting_documents": formvalues.qardan_hasana.supporting_documents,
            // "guarantor_1_passport": formvalues.guarantor.guarantor_1_passport,
            // "guarantor_2_passport": formvalues.guarantor.guarantor_2_passport,
        }

        console.log("files", files);

        Object.entries(files).forEach(([name, file]) => {
            if (file instanceof File || file instanceof Blob)
                formdata.append(`files`, file, name)
        });

        const apiEndpoint = endpoints['apply-qardan-hasana'];
        const resp = await axios.post(apiEndpoint, formdata, { headers: { 'Content-Type': "multipart/form-data" } });
        console.log("applyQardanHasana ===>", resp.data);
        return { data: resp.data }

    } catch (error) {
        // console.log("applyQardanHasana_Error", (error as any));
        if (axios.isAxiosError(error)) {
            console.log("isAxiosError.applyQardanHasana_Error_response1", Object.entries(error));
            console.log("isAxiosError.applyQardanHasana_Error_response2", error.response);
        }
        else {
            console.log("applyQardanHasana_Error_response", error);
        }
        return { error: error as AxiosError }
    }
}
