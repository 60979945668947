import styled, { keyframes } from 'styled-components';
const qardanHasanaIllustration = require('../assets/qardan-hasana-illustration.png')
const noToInterestIllustration = require('../assets/illustration-of-no-interest.png')
const syednaMuffaddal = require('../assets/syedna-muffaddal.jpg');
import colors from '../theme/colors';
import { screenWidths } from '../theme/css-generators';
import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

// const hovering = keyframes`
//     0% { transform: translate(0, 30px); }
//     100% { transform: translate(0, -30px); }
// `

export const BodyWrap = styled.div`
    max-width: 1080px;
    margin: 30px auto;
    padding: 0 20px;
    color: ${colors[4]};
    & h1 { font-size: 50px; }
    & > .__ayat-{
        font-size: 26px ;
    }

    @media (max-width: ${screenWidths._500px}){
       & h1 { font-size: 34px; }
       & h2 { font-size: 29px; }
       & h4 { font-size: 23px; }
       & h6 { font-size: 18px; }
       & > .__ayat-{ font-size: 19px; line-height:31px;}
    }

`

export const Highlight = styled.div`
    background-color: ${colors[7]};
    padding: 5px;

    @media (max-width: ${screenWidths._500px}){
        & p{font-size: 15px;}
    }
`


export const QardanHasanaIllustration = styled.div`
    width: 100%;
    padding: 24% 0;
    margin: 10px 0;
    background: url(${qardanHasanaIllustration}) no-repeat;
    background-size: 90%;
    background-position: center;
`

export const NoToInterestIllustration = styled.div`
    width: 100%;
    padding: 24% 0;
    margin: 10px 0;
    background: url(${noToInterestIllustration}) no-repeat;
    background-size: 90%;
    background-position: center;
`

export const SyednaMuffaddalImg = styled.div`
    width: 100%;
    padding: 55% 0;
    /* margin: 0px 0; */
    background: url(${syednaMuffaddal}) no-repeat;
    background-size: 84%;
    background-position: center;
`;

export const TypoList: FC<{ type: "ul" | "ol" }> = ({ type, children }) => {
    return (
        <Typography component={type} align='justify' style={{ paddingLeft: '20px' }} >
            {children}
        </Typography>
    )
}