import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { QardanApplicant, UserType, QardanInstallment, FilterOptions, LogicalOperator, Order } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes'
import { GraphQLResult } from '../../../typedefs';
import { } from '../../../graphql/types';
import { RootStateType } from '../../store';
import { changeQardanApplicantPassword, getAllQardanInstallments, getQardanApplicantInfo, loginToQardanPortal, checkTokenExpiryQardanApplicant, verifyQardanSecuritycode } from './reducers';
import { errorMessages } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/helper';
// import appConfig from '../../../../app-config.json';
import { setCookie } from '../../../helper';
import moment from 'moment';
import { WritableDraft } from 'immer/dist/internal';
import { WarningDialog, InfoDialog } from '../../../components/Alert';

type StateType = {
    authToken?: string | null,
    isAuthenticated: boolean,
    isLoggedInWithTempPassword: boolean | null,
    applicantDetails: QardanApplicant | null,
    installmentDetails: {
        data: null | QardanInstallment[], totalCount: number, filterOptions: { [P in keyof FilterOptions]-?: FilterOptions[P] }
    },
    fetchingStatus: {
        loginToQardanPortal: boolean
        verifyQardanSecuritycode: boolean
        checkTokenExpiryQardanApplicant: boolean
        getAllQardanInstallments: boolean
        changeQardanApplicantPassword: boolean
        getQardanApplicantInfo: boolean
        // changeHSuserDetails: boolean
        // verifyHSsecuritycode: boolean
        // sendEmailConfirmationCode: boolean
        // getHsUserBankAccounts: boolean
        // addHsUserBankAccount: boolean
        // updateHsUserBankAccount: boolean
        // deleteHsUserBankAccount: boolean
    },
    error: {
        loginToQardanPortal: any | null
        verifyQardanSecuritycode: any | null
        checkTokenExpiryQardanApplicant: any | null
        getAllQardanInstallments: any | null
        changeQardanApplicantPassword: any | null
        getQardanApplicantInfo: any | null
        // changeHSuserDetails: any | null
        // verifyHSsecuritycode: any | null
        // sendEmailConfirmationCode: any | null
        // getHsUserBankAccounts: any | null
        // addHsUserBankAccount: any | null
        // updateHsUserBankAccount: any | null
        // deleteHsUserBankAccount: any | null
    }
}

const initialState: StateType = {
    authToken: null,
    isAuthenticated: false,
    applicantDetails: null,
    isLoggedInWithTempPassword: null,
    installmentDetails: {
        data: null, totalCount: 0,
        filterOptions: {
            filterBy: [],
            logicalOperator: LogicalOperator.And,
            pageNumber: 1,
            pageSize: 100,
            sortBy: { fields: ["id"], order: Order.Desc }
        }
    },
    fetchingStatus: {
        loginToQardanPortal: false,
        verifyQardanSecuritycode: false,
        checkTokenExpiryQardanApplicant: false,
        getAllQardanInstallments: false,
        changeQardanApplicantPassword: false,
        getQardanApplicantInfo: false,
    },
    error: {
        loginToQardanPortal: null,
        verifyQardanSecuritycode: null,
        checkTokenExpiryQardanApplicant: null,
        getAllQardanInstallments: null,
        changeQardanApplicantPassword: null,
        getQardanApplicantInfo: null,
    },
}

const slice = createSlice({
    name: 'qardanPortalSlice',
    initialState,
    reducers: {
        // authenticateUser: (state, { payload }: PayloadAction<{ authToken: string }>) => {
        //     state.authToken = payload.authToken
        //     state.isAuthenticated = true
        // },
        logoutFromQardanPortal: (state) => {
            setCookie("qardan_auth_token", "", { "max-age": -1, path: "/" });
            return initialState
        },
    },

    extraReducers(builder) {

        builder.addCase(loginToQardanPortal.pending, handlePending("loginToQardanPortal"))
        builder.addCase(loginToQardanPortal.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.loginToQardanPortal = false
            state.error.loginToQardanPortal = null
            state.isLoggedInWithTempPassword = false;
            const { accessToken, userDetails } = payload.data.loginToQardanPortal
            state.isAuthenticated = !!accessToken;
            state.authToken = accessToken;
            state.applicantDetails = userDetails;

            if (payload.data.loginToQardanPortal.warning === errorMessages.loginToQardanPortal.TemporaryPasswordWarning) {
                WarningDialog("Warning", "You are logged in with temporary password. In order to proceed, please click “OK” to change your password.")
                state.isLoggedInWithTempPassword = true;
            }

            accessToken && setCookie("qardan_auth_token", accessToken, { "max-age": 3600, path: '/', samesite: "lax" }, true)
            arg.callback && arg.callback(null, payload.data.loginToQardanPortal)
        })
        builder.addCase(loginToQardanPortal.rejected, handleReject("loginToQardanPortal"))


        builder.addCase(checkTokenExpiryQardanApplicant.pending, handlePending("checkTokenExpiryQardanApplicant"))
        builder.addCase(checkTokenExpiryQardanApplicant.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.checkTokenExpiryQardanApplicant = false
            state.isAuthenticated = true;
            state.authToken = payload.data.checkTokenExpiry.accessToken
            state.applicantDetails = payload.data.checkTokenExpiry.userDetails

            if (payload.data.checkTokenExpiry.warning === errorMessages.loginToQardanPortal.TemporaryPasswordWarning) {
                WarningDialog("Warning", "You are logged in with temporary password. In order to proceed, please click “OK” to change your password.");
                state.isLoggedInWithTempPassword = true;
            }
            arg.callback && arg.callback(null, payload.data.checkTokenExpiry)
        })
        builder.addCase(checkTokenExpiryQardanApplicant.rejected, handleReject("checkTokenExpiryQardanApplicant"))


        builder.addCase(verifyQardanSecuritycode.pending, handlePending("verifyQardanSecuritycode"))
        builder.addCase(verifyQardanSecuritycode.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.applicantDetails) { return }
            state.fetchingStatus.verifyQardanSecuritycode = false;
            state.error.verifyQardanSecuritycode = null;
            const { accessToken, userDetails } = payload.data.verifyQardanSecuritycode;
            state.isAuthenticated = !!accessToken;
            state.authToken = accessToken;
            state.applicantDetails = userDetails;
            accessToken && setCookie("qardan_auth_token", accessToken, { "max-age": 3600, path: '/', samesite: "lax" }, true)
            arg.callback && arg.callback(null, payload.data.verifyQardanSecuritycode)
        })
        builder.addCase(verifyQardanSecuritycode.rejected, handleReject("verifyQardanSecuritycode"))


        builder.addCase(getAllQardanInstallments.pending, handlePending("getAllQardanInstallments"))
        builder.addCase(getAllQardanInstallments.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.installmentDetails) { return }
            state.fetchingStatus.getAllQardanInstallments = false;
            state.error.getAllQardanInstallments = null;
            state.installmentDetails.data = payload.data.getAllQardanInstallments.list;
            state.installmentDetails.totalCount = payload.data.getAllQardanInstallments.totalCount;
            state.installmentDetails.filterOptions = {
                ...state.installmentDetails.filterOptions,
                ...arg.filterBy
            };
            // arg.callback && arg.callback(null, payload.data.getAllQardanInstallments)
        })
        builder.addCase(getAllQardanInstallments.rejected, handleReject("getAllQardanInstallments"))


        builder.addCase(changeQardanApplicantPassword.pending, handlePending("changeQardanApplicantPassword"))
        builder.addCase(changeQardanApplicantPassword.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.changeQardanApplicantPassword = false;
            state.error.changeQardanApplicantPassword = null;
            if (state.isLoggedInWithTempPassword === true) {
                state.isLoggedInWithTempPassword = false;
            }
            arg.callback && arg.callback(null, payload.data.changeQardanApplicantPassword)
        })
        builder.addCase(changeQardanApplicantPassword.rejected, handleReject("changeQardanApplicantPassword"))


        builder.addCase(getQardanApplicantInfo.pending, handlePending("getQardanApplicantInfo"))
        builder.addCase(getQardanApplicantInfo.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getQardanApplicantInfo = false;
            state.error.getQardanApplicantInfo = null;
            state.applicantDetails = payload.data.getQardanApplicantInfo;
            // arg.callback && arg.callback(null, payload.data.getQardanApplicantInfo)
        })
        builder.addCase(getQardanApplicantInfo.rejected, handleReject("getQardanApplicantInfo"))

    }
})

export const { logoutFromQardanPortal } = slice.actions;
export { changeQardanApplicantPassword, getAllQardanInstallments, getQardanApplicantInfo, loginToQardanPortal, verifyQardanSecuritycode, checkTokenExpiryQardanApplicant };
export default slice.reducer;

function handlePending(thunkName: string) {
    return (state: WritableDraft<StateType>, { meta: { arg } }) => {
        state.fetchingStatus[thunkName] = true
    }
}

function handleReject(thunkName: string) {
    return (state: WritableDraft<StateType>, { error, meta: { arg } }) => {
        const err = JSON.parse(error.message || "{}") as GraphQLResult<any>
        console.log(`${thunkName}_Error`, err);
        state.error[thunkName] = err.errors ? err.errors[0].message : err;
        state.fetchingStatus[thunkName] = false
        arg?.callback && arg.callback(err, null)
    }
}
