import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { GetAllHSusersFilter, GetAllSbqHusersFilter, WithdrawalRequestStatus, Transaction, FilterOptions, HSuserBasicInfo, WithdrawalRequestWithHSuserInfo, LogicalOperator, Order, UserType, HSuserFullInfo, QueryGetAllHSaccountsWithBalancesArgs, QueryGetAllDormantHSaccountsArgs, HSuser, QardanApplicant, QardanInstallment } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes'
import { GraphQLResult } from '../../../typedefs';
import { RootStateType } from '../../store';
import { WritableDraft } from "immer/dist/internal";
// import appConfig from '../../../../app-config.json';
import { setCookie } from '../../../helper';
import { changeQardanApplicantPasswordBySBQHuser, getQardanApplicantsWithOutstandingBalances, changeHSuserPasswordBySBQHuser, getQardanApplicantAndInstallments, getAllQardanInstallments, addQardanApplicant, changeQardanApplicantDetails, deleteQardanApplicant, getAllQardanApplicants, getAllDormantHSaccounts, verifySBQHSecuritycode, getAllHSaccountsWithBalances, loginToSBQHPortal, deleteTransaction, changeSBQHusersAccess, deleteSBQHuser, checkTokenExpirySBQHuser, getAllHStransactions, getAllHSusers, getAllSBQHusers, getAllWithdrawalRequests, fulfillPaymentWithdrawlRequests, changeHSuserDetails, addSBQHuser, addTransaction, getHSuserAccountAndTransaction, addQardanInstallment, deleteQardanInstallment } from './reducers';
import { SbqHuser, } from '../../../graphql/types';
import moment from 'moment';

type StateType = {
    authToken?: string | null,
    isAuthenticated: boolean,
    userDetails: SbqHuser | null,
    sbqh_users_data: { data: null | SbqHuser[], totalCount: number, pageNumber: number, pageSize: number, filterBy?: GetAllSbqHusersFilter },
    qardan_applicants_data: { data: null | QardanApplicant[], totalCount: number, filterOptions: { [P in keyof FilterOptions]-?: FilterOptions[P] } },
    qardan_installments: { data: null | QardanInstallment[], totalCount: number, totalLoanAllocated: number, totalLoanPaid: number, totalLoanPending: number, filterOptions: { [P in keyof FilterOptions]-?: FilterOptions[P] } },
    hs_withdrawalRequests_data: { data: null | WithdrawalRequestWithHSuserInfo[], totalCount: number, pageNumber: number, pageSize: number, status: WithdrawalRequestStatus | null, },
    hs_users_data: { data: null | HSuserBasicInfo[], totalCount: number, pageNumber: number, pageSize: number, filterBy?: GetAllHSusersFilter },
    hs_transactions_data: { data: null | Transaction[], totalCount: number, amountIn: number, amountOut: number, filterOptions: FilterOptions },
    analytics_hs_accounts: { data: null | HSuserFullInfo[], totalCount: number, filterOptions: QueryGetAllHSaccountsWithBalancesArgs },
    analytics_hs_dormant_accounts: { data: null | HSuserFullInfo[], totalCount: number, filterOptions: QueryGetAllDormantHSaccountsArgs },
    analytics_hs_bank_reconciliation: { userDetails: HSuserFullInfo | null, userTransactions: { data: null | Transaction[], totalCount: number }, input: { filterOptions: FilterOptions, userId: string, } },
    qardan_applicant_search: { applicantInfo: QardanApplicant | null, installments: { data: null | QardanInstallment[], totalCount: number }, input: { filterOptions: FilterOptions, applicantId: string, } },
    qardan_applicants_with_outstanding_balances: { data: QardanApplicant[] | null, totalCount: number, filterOptions: { [P in keyof FilterOptions]-?: FilterOptions[P] } },

    fetchingStatus: {
        loginToSBQHPortal: boolean
        checkTokenExpirySBQHuser: boolean
        getAllHSusers: boolean
        getAllWithdrawalRequests: boolean
        changeHSuserDetails: boolean
        getAllSBQHusers: boolean
        getAllHStransactions: boolean
        changeSBQHusersAccess: boolean
        fulfillPaymentWithdrawlRequests: boolean
        addSBQHuser: boolean
        addTransaction: boolean
        deleteSBQHuser: boolean
        getAllDormantHSaccounts: boolean
        getAllHSaccountsWithBalances: boolean
        getHSuserAccountAndTransaction: boolean
        deleteTransaction: boolean
        verifySBQHSecuritycode: boolean
        changeQardanApplicantDetails: boolean
        deleteQardanApplicant: boolean
        getAllQardanApplicants: boolean
        addQardanApplicant: boolean
        getAllQardanInstallments: boolean
        addQardanInstallment: boolean
        deleteQardanInstallment: boolean
        getQardanApplicantAndInstallments: boolean
        getQardanApplicantsWithOutstandingBalances: boolean
        changeHSuserPasswordBySBQHuser: boolean
        changeQardanApplicantPasswordBySBQHuser: boolean
    },
    error: {
        loginToSBQHPortal: any | null
        changeSBQHusersAccess: any | null
        checkTokenExpirySBQHuser: any | null
        getAllHSusers: any | null
        getAllWithdrawalRequests: any | null
        changeHSuserDetails: any | null
        getAllSBQHusers: any | null
        getAllHStransactions: any | null
        fulfillPaymentWithdrawlRequests: any | null
        addSBQHuser: any | null
        deleteSBQHuser: any | null
        getAllDormantHSaccounts: any | null
        addTransaction: any | null
        getAllHSaccountsWithBalances: any | null
        getHSuserAccountAndTransaction: string | null
        deleteTransaction: string | null
        verifySBQHSecuritycode: string | null
        changeQardanApplicantDetails: string | null
        deleteQardanApplicant: string | null
        getAllQardanApplicants: string | null
        addQardanApplicant: string | null
        getAllQardanInstallments: string | null
        addQardanInstallment: string | null
        deleteQardanInstallment: string | null
        getQardanApplicantAndInstallments: string | null
        getQardanApplicantsWithOutstandingBalances: string | null
        changeHSuserPasswordBySBQHuser: string | null
        changeQardanApplicantPasswordBySBQHuser: string | null
    }
}

const initialState: StateType = {
    authToken: null,
    isAuthenticated: false,
    userDetails: null,
    sbqh_users_data: { data: null, totalCount: 0, pageNumber: 1, pageSize: 100 },
    hs_users_data: { data: null, totalCount: 0, pageNumber: 1, pageSize: 100 },
    qardan_applicants_data: {
        data: null, totalCount: 0,
        filterOptions: {
            filterBy: [], logicalOperator: LogicalOperator.And,
            pageNumber: 1, pageSize: 100,
            sortBy: { fields: ["id"], order: Order.Desc }
        }
    },
    qardan_installments: {
        data: null, totalCount: 0, totalLoanAllocated: 0, totalLoanPaid: 0, totalLoanPending: 0,
        filterOptions: {
            filterBy: [], logicalOperator: LogicalOperator.And,
            pageNumber: 1, pageSize: 100,
            sortBy: { fields: ["date DESC", "id"], order: Order.Desc }
        }
    },
    qardan_applicants_with_outstanding_balances: {
        data: null, totalCount: 0,
        filterOptions: {
            filterBy: [], logicalOperator: LogicalOperator.And,
            pageNumber: 1, pageSize: 100,
            sortBy: { fields: ["id"], order: Order.Desc }
        }
    },
    hs_withdrawalRequests_data: { data: null, totalCount: 0, pageNumber: 1, pageSize: 100, status: null },
    analytics_hs_accounts: { data: null, totalCount: 0, filterOptions: { dateFrom: Date.now(), pageNumber: 1, pageSize: 100 } },
    analytics_hs_dormant_accounts: { data: null, totalCount: 0, filterOptions: { dateFrom: Date.now(), pageNumber: 1, pageSize: 100 } },
    hs_transactions_data: {
        data: null, totalCount: 0, amountOut: 0, amountIn: 0,
        filterOptions: {
            filterBy: [],
            logicalOperator: LogicalOperator.And,
            pageNumber: 1,
            pageSize: 100,
            sortBy: { fields: ["id"], order: Order.Desc }
        }
    },
    analytics_hs_bank_reconciliation: {
        userDetails: null,
        userTransactions: {
            data: null, totalCount: 0,
        },
        input: {
            filterOptions: {
                filterBy: [],
                logicalOperator: LogicalOperator.And,
                pageNumber: 1,
                pageSize: 100,
                sortBy: { fields: ["date"], order: Order.Desc }
            },
            userId: ""
        }
    },
    qardan_applicant_search: {
        applicantInfo: null,
        installments: { data: null, totalCount: 0 },
        input: {
            filterOptions: {
                filterBy: [],
                logicalOperator: LogicalOperator.And,
                pageNumber: 1,
                pageSize: 100,
                sortBy: { fields: ["id"], order: Order.Desc }
            },
            applicantId: ""
        }
    },
    fetchingStatus: {
        loginToSBQHPortal: false,
        getQardanApplicantsWithOutstandingBalances: false,
        getQardanApplicantAndInstallments: false,
        checkTokenExpirySBQHuser: false,
        getAllHSusers: false,
        getAllWithdrawalRequests: false,
        changeHSuserDetails: false,
        getAllSBQHusers: false,
        getAllHStransactions: false,
        changeSBQHusersAccess: false,
        fulfillPaymentWithdrawlRequests: false,
        addSBQHuser: false,
        deleteSBQHuser: false,
        getAllDormantHSaccounts: false,
        addTransaction: false,
        getAllHSaccountsWithBalances: false,
        getHSuserAccountAndTransaction: false,
        deleteTransaction: false,
        verifySBQHSecuritycode: false,
        deleteQardanApplicant: false,
        changeQardanApplicantDetails: false,
        getAllQardanApplicants: false,
        addQardanApplicant: false,
        getAllQardanInstallments: false,
        addQardanInstallment: false,
        deleteQardanInstallment: false,
        changeHSuserPasswordBySBQHuser: false,
        changeQardanApplicantPasswordBySBQHuser: false,
    },
    error: {
        loginToSBQHPortal: null,
        getQardanApplicantAndInstallments: null,
        verifySBQHSecuritycode: null,
        checkTokenExpirySBQHuser: null,
        getAllHSusers: null,
        getAllWithdrawalRequests: null,
        changeHSuserDetails: null,
        getAllHStransactions: null,
        getAllSBQHusers: null,
        changeSBQHusersAccess: null,
        fulfillPaymentWithdrawlRequests: null,
        addSBQHuser: null,
        deleteSBQHuser: null,
        getAllDormantHSaccounts: null,
        addTransaction: null,
        getAllHSaccountsWithBalances: null,
        getHSuserAccountAndTransaction: null,
        deleteTransaction: null,
        changeQardanApplicantDetails: null,
        deleteQardanApplicant: null,
        getAllQardanApplicants: null,
        addQardanApplicant: null,
        getAllQardanInstallments: null,
        addQardanInstallment: null,
        deleteQardanInstallment: null,
        getQardanApplicantsWithOutstandingBalances: null,
        changeHSuserPasswordBySBQHuser: null,
        changeQardanApplicantPasswordBySBQHuser: null,
    }
}

const slice = createSlice({
    name: 'adminAuth',
    initialState,
    reducers: {
        logoutFromSbqhPortal: (state) => {
            // deleteCookie("sbqh_auth_token");
            setCookie("sbqh_auth_token", "", { "max-age": -1, path: "/" });
            return initialState
        },
    },

    extraReducers(builder) {

        builder.addCase(loginToSBQHPortal.pending, handlePending("loginToSBQHPortal"))
        builder.addCase(loginToSBQHPortal.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.loginToSBQHPortal = false;
            const response = payload.data.loginToSBQHPortal;
            arg.callback && arg.callback(null, response);
        })
        builder.addCase(loginToSBQHPortal.rejected, handleReject("loginToSBQHPortal"))

        builder.addCase(verifySBQHSecuritycode.pending, handlePending("verifySBQHSecuritycode"))
        builder.addCase(verifySBQHSecuritycode.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.verifySBQHSecuritycode = false
            state.isAuthenticated = true;
            const { accessToken, userDetails } = payload.data.verifySBQHSecuritycode
            state.authToken = accessToken;
            state.userDetails = userDetails;
            // setCookie("sbqh_auth_token", accessToken, { "max-age": 3600, path: appConfig.pages.ADMIN.path, samesite: "lax" }, true)
            accessToken && setCookie("sbqh_auth_token", accessToken, { "max-age": 7200, path: '/', samesite: "lax" }, true)
            // saveAuthToken({ type: "sbqh_auth_token", expiry: 3600, token: accessToken })
            arg.callback && arg.callback(null, payload.data.verifySBQHSecuritycode)
        })
        builder.addCase(verifySBQHSecuritycode.rejected, handleReject("verifySBQHSecuritycode"))


        builder.addCase(checkTokenExpirySBQHuser.pending, handlePending("checkTokenExpirySBQHuser"))
        builder.addCase(checkTokenExpirySBQHuser.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.checkTokenExpirySBQHuser = false
            state.error.checkTokenExpirySBQHuser = null
            state.isAuthenticated = true;
            const { accessToken, userDetails } = payload.data.checkTokenExpiry
            state.authToken = accessToken;
            state.userDetails = userDetails;
            arg.callback && arg.callback(null, payload.data.checkTokenExpiry)
        })
        builder.addCase(checkTokenExpirySBQHuser.rejected, handleReject("checkTokenExpirySBQHuser"))


        builder.addCase(addSBQHuser.pending, handlePending("addSBQHuser"))
        builder.addCase(addSBQHuser.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.addSBQHuser = false;
            state.error.addSBQHuser = null;
            state.sbqh_users_data.data?.push(payload.data.addSBQHuser)
            arg.callback && arg.callback(null, payload.data.addSBQHuser)
        })
        builder.addCase(addSBQHuser.rejected, handleReject("addSBQHuser"))


        builder.addCase(addQardanApplicant.pending, handlePending("addQardanApplicant"))
        builder.addCase(addQardanApplicant.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.addQardanApplicant = false;
            state.error.addQardanApplicant = null;
            state.qardan_applicants_data.data?.push(payload.data.addQardanApplicant)
            arg.callback && arg.callback(null, payload.data.addQardanApplicant)
        })
        builder.addCase(addQardanApplicant.rejected, handleReject("addQardanApplicant"))


        builder.addCase(getAllWithdrawalRequests.pending, handlePending("getAllWithdrawalRequests"))
        builder.addCase(getAllWithdrawalRequests.fulfilled, (state, { payload, meta }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getAllWithdrawalRequests = false;
            state.hs_withdrawalRequests_data = {
                data: payload.data.getAllWithdrawalRequests.data,
                totalCount: payload.data.getAllWithdrawalRequests.totalCount,
                pageNumber: meta.arg.pageNumber,
                pageSize: meta.arg.pageSize,
                status: meta.arg.status || null,
            }
        })
        builder.addCase(getAllWithdrawalRequests.rejected, handleReject("getAllWithdrawalRequests"))


        builder.addCase(getAllQardanApplicants.pending, handlePending("getAllQardanApplicants"))
        builder.addCase(getAllQardanApplicants.fulfilled, (state, { payload, meta }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getAllQardanApplicants = false;
            state.qardan_applicants_data = {
                data: payload.data.getAllQardanApplicants.list,
                totalCount: payload.data.getAllQardanApplicants.totalCount,
                filterOptions: { ...state.qardan_applicants_data.filterOptions, ...meta.arg }
            };
        })
        builder.addCase(getAllQardanApplicants.rejected, handleReject("getAllQardanApplicants"))


        builder.addCase(getAllQardanInstallments.pending, handlePending("getAllQardanInstallments"))
        builder.addCase(getAllQardanInstallments.fulfilled, (state, { payload, meta }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getAllQardanInstallments = false;
            state.qardan_installments = {
                data: payload.data.getAllQardanInstallments.list,
                totalCount: payload.data.getAllQardanInstallments.totalCount,
                totalLoanAllocated: payload.data.getAllQardanInstallments.totalLoanAllocated || 0,
                totalLoanPaid: payload.data.getAllQardanInstallments.totalLoanPaid || 0,
                totalLoanPending: payload.data.getAllQardanInstallments.totalLoanPending || 0,
                filterOptions: { ...state.qardan_applicants_data.filterOptions, ...meta.arg }
            };
        })
        builder.addCase(getAllQardanInstallments.rejected, handleReject("getAllQardanInstallments"))


        builder.addCase(getAllHSusers.pending, handlePending("getAllHSusers"))
        builder.addCase(getAllHSusers.fulfilled, (state, { payload, meta }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getAllHSusers = false;
            state.hs_users_data = {
                data: payload.data.getAllHSusers.users,
                totalCount: payload.data.getAllHSusers.totalCount,
                pageNumber: meta.arg.pageNumber,
                pageSize: meta.arg.pageSize,
                filterBy: meta.arg.filterBy
            };
        })
        builder.addCase(getAllHSusers.rejected, handleReject("getAllHSusers"))


        builder.addCase(getAllSBQHusers.pending, handlePending("getAllSBQHusers"))
        builder.addCase(getAllSBQHusers.fulfilled, (state, { payload, meta }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getAllSBQHusers = false;
            state.error.getAllSBQHusers = null;
            state.sbqh_users_data = {
                data: payload.data.getAllSBQHusers.users,
                totalCount: payload.data.getAllSBQHusers.totalCount,
                pageNumber: meta.arg.pageNumber,
                pageSize: meta.arg.pageSize,
                filterBy: meta.arg.filterBy
            };
        })
        builder.addCase(getAllSBQHusers.rejected, handleReject("getAllSBQHusers"))


        builder.addCase(changeQardanApplicantDetails.pending, handlePending("changeQardanApplicantDetails"))
        builder.addCase(changeQardanApplicantDetails.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.qardan_applicants_data.data) { return }
            state.fetchingStatus.changeQardanApplicantDetails = false;
            state.error.changeQardanApplicantDetails = null;
            const index = state.qardan_applicants_data.data.findIndex(v => v.id === arg.input.id);
            if (index !== -1) {
                state.qardan_applicants_data.data[index] = {
                    ...state.qardan_applicants_data.data[index],
                    ...payload.data.changeQardanApplicantDetails
                }
            }
            arg.callback && arg.callback(null, payload.data.changeQardanApplicantDetails)
        })
        builder.addCase(changeQardanApplicantDetails.rejected, handleReject("changeQardanApplicantDetails"))


        builder.addCase(changeHSuserDetails.pending, handlePending("changeHSuserDetails"))
        builder.addCase(changeHSuserDetails.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.hs_users_data.data) { return }
            state.fetchingStatus.changeHSuserDetails = false;
            state.error.changeHSuserDetails = null;
            const index = state.hs_users_data.data.findIndex(v => v.id === arg.id);
            if (index !== -1) {
                state.hs_users_data.data[index] = {
                    ...state.hs_users_data.data[index],
                    first_name: arg.first_name,
                    last_name: arg.last_name,
                    its_no: arg.its_no,
                }
            }
            arg.callback && arg.callback(null, payload.data.changeHSuserDetails)
        })
        builder.addCase(changeHSuserDetails.rejected, handleReject("changeHSuserDetails"))


        builder.addCase(changeSBQHusersAccess.pending, handlePending("changeSBQHusersAccess"))
        builder.addCase(changeSBQHusersAccess.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.sbqh_users_data.data || arg.users_access.length < 1) { return }
            state.fetchingStatus.changeSBQHusersAccess = false;
            state.error.changeSBQHusersAccess = null;
            // let counter = 0;
            arg.users_access.forEach((userAccess, idx) => {
                const userDetails = state.sbqh_users_data.data?.find(v => v.id === userAccess.id);
                if (state.userDetails && state.userDetails?.id === userAccess?.id) {
                    state.userDetails.access = {
                        ...state.userDetails.access,
                        ...userAccess.access
                    };
                }
                if (userDetails) {
                    userDetails.access = {
                        ...userDetails.access,
                        ...userAccess.access
                    }
                }
            })

            arg.callback && arg.callback(null, payload.data.changeSBQHusersAccess)
        })
        builder.addCase(changeSBQHusersAccess.rejected, handleReject("changeSBQHusersAccess"))


        builder.addCase(getAllHStransactions.pending, handlePending("getAllHStransactions"))
        builder.addCase(getAllHStransactions.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getAllHStransactions = false;
            state.error.getAllHStransactions = null;

            state.hs_transactions_data = {
                data: payload.data.getAllHStransactions.data || [],
                totalCount: payload.data.getAllHStransactions.totalCount,
                amountIn: payload.data.getAllHStransactions.amountIn || 0,
                amountOut: payload.data.getAllHStransactions.amountOut || 0,
                filterOptions: arg,
            }

        })
        builder.addCase(getAllHStransactions.rejected, handleReject("getAllHStransactions"))


        builder.addCase(fulfillPaymentWithdrawlRequests.pending, handlePending("fulfillPaymentWithdrawlRequests"))
        builder.addCase(fulfillPaymentWithdrawlRequests.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.fulfillPaymentWithdrawlRequests = false;
            state.error.fulfillPaymentWithdrawlRequests = null;

            state.hs_withdrawalRequests_data.data = state.hs_withdrawalRequests_data.data?.map(v => ({
                ...v,
                status: arg.account_ids.includes(v.account_id) ? WithdrawalRequestStatus.Fulfilled : WithdrawalRequestStatus.Pending,
            })) || null

            arg.callback && arg.callback(null, payload.data.fulfillPaymentWithdrawlRequests)

        })
        builder.addCase(fulfillPaymentWithdrawlRequests.rejected, handleReject("fulfillPaymentWithdrawlRequests"))


        builder.addCase(deleteSBQHuser.pending, handlePending("deleteSBQHuser"))
        builder.addCase(deleteSBQHuser.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.deleteSBQHuser = false;
            state.error.deleteSBQHuser = null;

            state.sbqh_users_data.data = state.sbqh_users_data.data?.filter((user) => user.id !== arg.id) || null

            arg.callback && arg.callback(null, payload.data.deleteSBQHuser)

        })
        builder.addCase(deleteSBQHuser.rejected, handleReject("deleteSBQHuser"))


        builder.addCase(deleteQardanApplicant.pending, handlePending("deleteQardanApplicant"))
        builder.addCase(deleteQardanApplicant.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.deleteQardanApplicant = false;
            state.error.deleteQardanApplicant = null;
            state.qardan_applicants_data.data = state.qardan_applicants_data.data?.filter((applicant) => applicant.id !== arg.id) || null
            arg.callback && arg.callback(null, payload.data.deleteQardanApplicant)
        })
        builder.addCase(deleteQardanApplicant.rejected, handleReject("deleteQardanApplicant"))


        builder.addCase(deleteTransaction.pending, handlePending("deleteTransaction"))
        builder.addCase(deleteTransaction.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.deleteTransaction = false;
            state.error.deleteTransaction = null;

            arg.callback && arg.callback(null, payload.data.deleteTransaction);
            state.hs_transactions_data.data = state.hs_transactions_data.data?.filter(t => t.id !== arg.transaction_id) || null;
            state.hs_transactions_data.totalCount--;
        })
        builder.addCase(deleteTransaction.rejected, handleReject("deleteTransaction"))


        builder.addCase(deleteQardanInstallment.pending, handlePending("deleteQardanInstallment"))
        builder.addCase(deleteQardanInstallment.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.deleteQardanInstallment = false;
            state.error.deleteQardanInstallment = null;

            arg.callback && arg.callback(null, payload.data.deleteQardanInstallment);
            state.qardan_installments.data = state.qardan_installments.data?.filter(t => t.id !== arg.id) || null;
            state.qardan_installments.totalCount--;

        })
        builder.addCase(deleteQardanInstallment.rejected, handleReject("deleteQardanInstallment"))


        builder.addCase(addQardanInstallment.pending, handlePending("addQardanInstallment"))
        builder.addCase(addQardanInstallment.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.qardan_installments.data) { return }
            state.fetchingStatus.addQardanInstallment = false;
            state.error.addQardanInstallment = null;

            /* removing one item from installments list and  */
            if (state.qardan_installments.data.length >= (state.qardan_installments.filterOptions.pageSize || 0)) {
                state.qardan_installments.data.pop()
            }

            state.qardan_installments.data.unshift(payload.data.addQardanInstallment)
            state.qardan_installments.totalCount++

            state.qardan_installments.totalLoanPaid += payload.data.addQardanInstallment.amount;
            state.qardan_installments.totalLoanPending -= payload.data.addQardanInstallment.amount;

            arg.callback && arg.callback(null, payload.data.addQardanInstallment)

        })
        builder.addCase(addQardanInstallment.rejected, handleReject("addQardanInstallment"))


        builder.addCase(addTransaction.pending, handlePending("addTransaction"))
        builder.addCase(addTransaction.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.hs_transactions_data.data) { return }
            state.fetchingStatus.addTransaction = false;
            state.error.addTransaction = null;

            if (state.hs_transactions_data.data.length >= (state.hs_transactions_data.filterOptions.pageSize || 0)) {
                state.hs_transactions_data.data.pop()
            }

            state.hs_transactions_data.data = [{
                id: Number(payload.data.addTransaction.responseId!),
                amount: arg.amount,
                date: moment(arg.date).format("YYYY-MM-DD HH:mm:ss"),
                description: arg.description,
                hsUserId: arg.hsUserId,
                account_id: +arg.hsUserId.slice(2) // hs101 => 101,
            }, ...(state.hs_transactions_data.data || [])]

            state.hs_transactions_data.totalCount++

            arg.callback && arg.callback(null, payload.data.addTransaction)

        })
        builder.addCase(addTransaction.rejected, handleReject("addTransaction"))


        builder.addCase(getAllHSaccountsWithBalances.pending, handlePending("getAllHSaccountsWithBalances"))
        builder.addCase(getAllHSaccountsWithBalances.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getAllHSaccountsWithBalances = false;
            state.error.getAllHSaccountsWithBalances = null;
            state.analytics_hs_accounts.data = payload.data.getAllHSaccountsWithBalances.data;
            if (payload.data.getAllHSaccountsWithBalances.totalCount) {
                state.analytics_hs_accounts.totalCount = payload.data.getAllHSaccountsWithBalances.totalCount;
            }
            state.analytics_hs_accounts.filterOptions = arg;
        })
        builder.addCase(getAllHSaccountsWithBalances.rejected, handleReject("getAllHSaccountsWithBalances"))


        builder.addCase(getAllDormantHSaccounts.pending, handlePending("getAllDormantHSaccounts"))
        builder.addCase(getAllDormantHSaccounts.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getAllDormantHSaccounts = false;
            state.error.getAllDormantHSaccounts = null;
            state.analytics_hs_dormant_accounts.data = payload.data.getAllDormantHSaccounts.data;
            if (payload.data.getAllDormantHSaccounts.totalCount) {
                state.analytics_hs_dormant_accounts.totalCount = payload.data.getAllDormantHSaccounts.totalCount;
            }
            state.analytics_hs_dormant_accounts.filterOptions = arg;
        })
        builder.addCase(getAllDormantHSaccounts.rejected, handleReject("getAllDormantHSaccounts"))


        builder.addCase(getHSuserAccountAndTransaction.pending, handlePending("getHSuserAccountAndTransaction"))
        builder.addCase(getHSuserAccountAndTransaction.fulfilled, (state, { payload, meta: { arg } }) => {
            state.fetchingStatus.getHSuserAccountAndTransaction = false;
            state.error.getHSuserAccountAndTransaction = null;
            if (!payload.userTransactions) { return }
            state.analytics_hs_bank_reconciliation = {
                userDetails: payload.userDetails || state.analytics_hs_bank_reconciliation.userDetails,
                userTransactions: {
                    ...payload.userTransactions,
                },
                input: {
                    filterOptions: arg.transactionfilter || state.analytics_hs_bank_reconciliation.input.filterOptions,
                    userId: arg.userId
                }
            }

        })
        builder.addCase(getHSuserAccountAndTransaction.rejected, handleReject("getHSuserAccountAndTransaction"))


        builder.addCase(getQardanApplicantAndInstallments.pending, handlePending("getQardanApplicantAndInstallments"))
        builder.addCase(getQardanApplicantAndInstallments.fulfilled, (state, { payload, meta: { arg } }) => {
            state.fetchingStatus.getQardanApplicantAndInstallments = false;
            state.error.getQardanApplicantAndInstallments = null;
            if (!payload.installments) { return }
            state.qardan_applicant_search = {
                applicantInfo: payload.applicantInfo || state.qardan_applicant_search.applicantInfo,
                installments: {
                    data: payload.installments.list,
                    totalCount: payload.installments.totalCount
                },
                input: {
                    filterOptions: arg.installmentfilter || state.qardan_applicant_search.input.filterOptions,
                    applicantId: arg.applicantId
                }
            }

        })
        builder.addCase(getQardanApplicantAndInstallments.rejected, handleReject("getQardanApplicantAndInstallments"))


        builder.addCase(getQardanApplicantsWithOutstandingBalances.pending, handlePending("getQardanApplicantsWithOutstandingBalances"))
        builder.addCase(getQardanApplicantsWithOutstandingBalances.fulfilled, (state, { payload, meta: { arg } }) => {
            state.fetchingStatus.getQardanApplicantsWithOutstandingBalances = false;
            state.error.getQardanApplicantsWithOutstandingBalances = null;
            if (!payload.data) { return }
            const { getQardanApplicantsWithOutstandingBalances } = payload.data;
            const { list, totalCount, } = getQardanApplicantsWithOutstandingBalances;
            state.qardan_applicants_with_outstanding_balances = {
                data: list, totalCount,
                filterOptions: {
                    ...state.qardan_applicants_with_outstanding_balances.filterOptions,
                    ...arg.filter
                }
            }

        })
        builder.addCase(getQardanApplicantsWithOutstandingBalances.rejected, handleReject("getQardanApplicantsWithOutstandingBalances"))


        builder.addCase(changeHSuserPasswordBySBQHuser.pending, handlePending("changeHSuserPasswordBySBQHuser"))
        builder.addCase(changeHSuserPasswordBySBQHuser.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.changeHSuserPasswordBySBQHuser = false;
            state.error.changeHSuserPasswordBySBQHuser = null;

            arg.callback && arg.callback(null, payload.data.changeHSuserPasswordBySBQHuser);
        })
        builder.addCase(changeHSuserPasswordBySBQHuser.rejected, handleReject("changeHSuserPasswordBySBQHuser"))


        builder.addCase(changeQardanApplicantPasswordBySBQHuser.pending, handlePending("changeQardanApplicantPasswordBySBQHuser"))
        builder.addCase(changeQardanApplicantPasswordBySBQHuser.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.changeQardanApplicantPasswordBySBQHuser = false;
            state.error.changeQardanApplicantPasswordBySBQHuser = null;

            arg.callback && arg.callback(null, payload.data.changeQardanApplicantPasswordBySBQHuser);
        })
        builder.addCase(changeQardanApplicantPasswordBySBQHuser.rejected, handleReject("changeQardanApplicantPasswordBySBQHuser"))


    }

})

export const { logoutFromSbqhPortal } = slice.actions;
export { getQardanApplicantsWithOutstandingBalances, getQardanApplicantAndInstallments, deleteQardanInstallment, addQardanInstallment, getAllQardanInstallments, addQardanApplicant, changeQardanApplicantDetails, deleteQardanApplicant, getAllQardanApplicants, getHSuserAccountAndTransaction, verifySBQHSecuritycode, loginToSBQHPortal, deleteSBQHuser, deleteTransaction, getAllDormantHSaccounts, getAllHSaccountsWithBalances, addTransaction, addSBQHuser, changeSBQHusersAccess, checkTokenExpirySBQHuser, fulfillPaymentWithdrawlRequests, getAllHStransactions, getAllHSusers, getAllWithdrawalRequests, changeHSuserDetails, getAllSBQHusers, changeQardanApplicantPasswordBySBQHuser };
export const getSbqhPortalDetails = () => useSelector((state: RootStateType) => state.sbqhPortal);
export default slice.reducer;


function handlePending(thunkName: string) {
    return (state: WritableDraft<StateType>, { meta: { arg } }) => {
        state.fetchingStatus[thunkName] = true
    }
}
function handleReject(thunkName: string) {
    return (state: WritableDraft<StateType>, { error, meta: { arg } }) => {
        const err = JSON.parse(error.message || "{}") as GraphQLResult<any>
        console.log(`${thunkName}_Error`, err);
        state.error[thunkName] = err.errors ? err.errors[0].message : err;
        state.fetchingStatus[thunkName] = false
        arg.callback && arg.callback(err, null)
    }
}