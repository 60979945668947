import React, { CSSProperties, useState, useEffect } from 'react';
import { Paper, Table, TableBody, Checkbox, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useMediaQuery } from '@material-ui/core';
import * as s from './style';
import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

interface Column<RowValues> {
    id: string;
    label: string;
    minWidth?: number;
    align?: "right" | "inherit" | "left" | "center" | "justify";
    format?: (cellValue, rowValues: RowValues, tableRowIndex: number) => string | number | JSX.Element;
}

interface Props<T> {
    label: JSX.Element;
    columns: Column<T>[],
    rows: T[],
    /**
     * this will be used when there is an empty array of row
     */
    emptyRowMsg?: React.ReactNode,
    totalRows?: number,
    onChangeRowsPerPage?: (pageNumber: number, pageSize: number) => void,
    onChangePageNo?: (pageNumber: number, pageSize: number) => void,
    pageNumber?: number,
    // pageSize?: number,
    loading?: boolean,
    /**
     * when data will be fetching asynchronously on pageNumber and pageSize change then it should be true
     */
    dataFetchingAsync?: boolean,
    checkboxSelection?: boolean,
    onRowSelection?: (selectedRows: number[]) => void,
    width?: CSSProperties['width'],
    tableStyle?: CSSProperties,
    handleClickOnRow?: (rowValues: any, tableRowIndex: number) => void
    onContextMenu?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, rowValues: any, tableRowIndex: number) => void
}

export default function MyTable<T = object>({ columns, rows, label, width, emptyRowMsg = <></>, handleClickOnRow, onContextMenu, totalRows, onChangeRowsPerPage, onChangePageNo, pageNumber, loading, dataFetchingAsync, checkboxSelection, onRowSelection, tableStyle }: Props<T>): React.ReactElement {
    // const MyTable: FC<Props> = ({ columns, rows, label, emptyRowMsg = <></>, handleClickOnRow, totalRows, onChangeRowsPerPage, onChangePageNo, pageNumber = 0, pageSize, loading, dataFetchingAsync, tableStyle }) => {
    // pageNumber = (pageNumber && pageNumber > 0) ? pageNumber - 1 : 0;
    const [page, setPage] = useState<number>(pageNumber || 0);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const widthUnder667 = useMediaQuery(`(min-width: 667px)`);

    useEffect(() => { pageNumber !== undefined && setPage(pageNumber); }, [pageNumber])
    useEffect(() => { setSelectedRows([]) }, [rows])
    useEffect(() => { onRowSelection && onRowSelection(selectedRows) }, [selectedRows])

    if (!dataFetchingAsync) {
        rows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        // setSelectedRows([])
        onChangePageNo && onChangePageNo(newPage, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        const startFromPage = 0;
        setPage(startFromPage);
        // setSelectedRows([])
        onChangeRowsPerPage && onChangeRowsPerPage(startFromPage, +event.target.value);
    };
    // console.log(totalRows);

    return (
        <Paper style={{ width }}  >
            {label && <s.LabelWrap>
                {label}
            </s.LabelWrap>}
            <TableContainer style={tableStyle} >
                <Table stickyHeader={true} >
                    <TableHead>
                        <TableRow  >
                            {checkboxSelection && (() => {
                                const checked = selectedRows.length > 0 && selectedRows.length === rows.length;
                                const indeterminate = selectedRows.length > 0 && selectedRows.length < rows.length
                                return <TableCell padding="checkbox">
                                    <Checkbox checked={checked} indeterminate={indeterminate} color='primary'
                                        onChange={() => {
                                            checked ?
                                                setSelectedRows([]) :
                                                setSelectedRows(Array(rows.length).fill(1).map((_, i) => i))
                                        }} />
                                </TableCell>
                            })()}
                            {columns.map((column, idx) => (
                                <TableCell style={{ minWidth: column.minWidth, fontWeight: 'bold', fontSize: widthUnder667 ? "16px" : undefined }} align={column.align} key={idx} >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rowIdx) => {
                            const isRowSelected = selectedRows.includes(rowIdx);
                            return <CTableRow onContextMenu={(e) => { onContextMenu && onContextMenu(e, row, rowIdx) }} selected={isRowSelected} hover key={rowIdx} style={{ cursor: handleClickOnRow ? "pointer" : undefined }} onClick={() => { handleClickOnRow && handleClickOnRow(row, rowIdx) }} >
                                {checkboxSelection &&
                                    <TableCell padding="checkbox">
                                        <Checkbox color='primary' checked={isRowSelected} onChange={() => {
                                            isRowSelected ?
                                                setSelectedRows(v => v.filter(v => v !== rowIdx)) :
                                                setSelectedRows(v => [...v, rowIdx])
                                        }
                                        } />
                                    </TableCell>
                                }
                                {columns.map((column, colIdx) => {
                                    const value = row[column.id];
                                    return <TableCell key={colIdx} align={column.align} style={{ minWidth: column.minWidth, fontSize: widthUnder667 ? "16px" : undefined }} >
                                        {column.format ? column.format(value, row, rowIdx) : value}
                                    </TableCell>
                                })}
                            </CTableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {!rows.length ? emptyRowMsg : null}

            {loading ? <Skeleton variant='rect' height={48} width="100%" animation='wave' /> :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={totalRows || rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}
        </Paper >
    )
}


const CTableRow = styled(TableRow)`
    &.Mui-selected {
        background-color: #edbb4a4a !important;
    }
`