import React, { FC } from 'react'
import { BodyWrap } from "../taher-scheme";
import { UserDetails } from '../../components/Form';
import { PageProps } from 'gatsby';
import { Seo, PageLayout } from '../../components';
import { siteMetadata } from '../../../gatsby-config';
import config from '../../../app-config.json';
import { Typography } from '@material-ui/core';
import { MetaDescription } from './';

const PAGE_TITLE = config.pages.HUSAIN_SCHEME.routes.newAccount.title;
const PARENT_PAGE_TITLE = config.pages.HUSAIN_SCHEME.title
const PARENT_PAGE_PATH = config.pages.HUSAIN_SCHEME.path
const NEW_ACCOUNT_FORM_PATH = config.pages.HUSAIN_SCHEME.routes.newAccount.path
export const NEW_ACCOUNT_FORM_LINK = `${PARENT_PAGE_PATH}${NEW_ACCOUNT_FORM_PATH}`;


const NewAccount: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${PARENT_PAGE_TITLE} | ${siteMetadata.title}`} propMetaDescription={MetaDescription} />
            <BodyWrap >
                <br />
                <Typography variant='h2' >{PARENT_PAGE_TITLE} - {PAGE_TITLE}</Typography>
                <Typography variant='body1' gutterBottom >QARDAN HASANA - {PARENT_PAGE_TITLE.toUpperCase()} (REFUNDABLE)</Typography>
                <hr /><br /> <br /> <br />
                <UserDetails pageTitle={PARENT_PAGE_TITLE} />
            </BodyWrap>
        </PageLayout>
    )
};

export default NewAccount;
