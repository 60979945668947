import styled from 'styled-components';
import { ContentNo, y_positions } from './';
import { displayFlex, screenWidths } from '../../theme/css-generators';
import colors from '../../theme/colors';
const masjidImg = require('../../assets/home-cover/masjid-cover.jpg');
const masjidImgSmall = require('../../assets/home-cover/masjid-cover-small.jpg');
const quote_arb = require('../../assets/home-cover/qoutes-arb.png');
const quotes_en = require('../../assets/home-cover/qoutes-en.png');

// const swipe = keyframes`
//     0% { background-position: center 0%; }
//     27% { background-position: center 0%; }
//     30% { background-position: center 50%; }
//     67% { background-position: center 50%; }
//     70% { background-position: center 100%; }
//     97% { background-position: center 100%; }
//     100% { background-position: center 0%; }
// `


export const Wrap = styled.div`
    width: 100%;
    padding: 3% 0px 3% 0px;
    background: url(${masjidImg}) no-repeat;
    background-position: center;
    background-size: cover;
    ${displayFlex({ dir: 'row', justify: 'normal', })};
    &:hover .__content-control{
        opacity: 1;
    }
    >.__text_container_{
        margin-bottom: 21%;
        >div:nth-child(1){ position:relative; left: -30px; }
        >div:nth-child(2){ position:relative; left: 30px; }
    }
    
    @media (max-width: ${screenWidths._600px}){
        background-image: url(${masjidImgSmall});
        background-position: bottom;
        padding: 2% 0px 35% 0px;
        >.__text_container_{
            margin-bottom: 150px;
            height: 360px;
            justify-content: center;
            >div:nth-child(1){ position:initial; margin-bottom:auto; }
            >div:nth-child(2){ position:initial; margin-top:auto; }
        }
    }

    @media (max-width: ${screenWidths._390px}){
        padding: 5% 0px 22% 0px;
        >.__text_container_{
            margin-bottom: 150px;
            height: 270px;
            >div:nth-child(1){ margin-bottom:0; }
            >div:nth-child(2){ margin-top:0; }
        }
    }

`


export const Qoutes_EN = styled.div<{ contentNo: ContentNo }>`
    width: 100%;
    padding: 25% 0%;
    background: url(${quotes_en}) no-repeat;
    background-size: cover;
    background-position: center ${({ contentNo }) => y_positions[contentNo - 1]};
    transition: 1.5s ease-in-out;
`
export const Qoutes_ARB = styled.div<{ contentNo: ContentNo }>`
    width: 95%;
    padding: 18% 0%;
    background: url(${quote_arb}) no-repeat;
    background-size: cover;
    transition: 1.5s ease-in-out;
    background-position: center ${({ contentNo }) => y_positions[contentNo - 1]};

    @media (max-width: ${screenWidths._390px}){
        width: 100%;
    }
`

const borderSize = '10px';
const borderColor = colors[6];
const bgColor = ["#E4D448", "#DAA520"]

export const Controller = styled.button<{ mirror?: boolean }>`
    width: 70px;
    padding: 20% 0;
    outline: 0;
    ${({ mirror }) => mirror ? "-webkit-transform: scaleX(-1); transform: scaleX(-1);" : ""};
    border-top: ${borderSize} solid ${borderColor};
    border-right: 0;
    border-left: ${borderSize} solid ${borderColor} ;
    border-bottom: ${borderSize} solid ${borderColor};
    border-radius: 50% 0% 0% 50%;
    background-image: linear-gradient(to right, ${bgColor[1]} 0%, ${bgColor[0]} 51%, ${bgColor[1]} 100%);
    background-size: 200% auto;
    transition: 0.7s;
    opacity: 0;
    &:hover{
        background-position: right center;
    }

    @media (max-width: ${screenWidths._390px}){
        width: 50px;
        padding: 10% 0;
    }

`;
