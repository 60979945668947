import { GraphQLResult, GraphqlError } from '../typedefs';
import { getCookie } from '../helper';
import { Response, ChangeSbqHusersAccessInput, MutationChangeHSuserDetailsArgs, MutationAddHSuserArgs, MutationAddTransactionArgs, MutationUpdateTransactionArgs, MutationDeleteTransactionArgs, MutationAddSbqHuserArgs, UserType, MutationDeleteSbqHuserArgs, MutationAddHsUserBankAccountArgs, HSuserBank, MutationDeleteHsUserBankAccountArgs, MutationUpdateHsUserBankAccountArgs, MutationSendLoginIssueArgs, QardanApplicant, AddQardanApplicantInput, UpdateQardanApplicantInput, QardanInstallment, AddQardanInstallmentInput, MutationDeleteQardanInstallmentArgs } from '../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { SbqHuser } from './types';
import { API } from "aws-amplify";
import { isAuthTokenExpired } from '.';

export const HSpaymentWithdrawalRequest = (cookieNameForToken?: string) => async (amount: number, bank_account_number: string, name_as_per_bank_account: string, sort_code: string, email?: string,): Promise<GraphQLResult<{ hsPaymentWithdrawalRequest: Response }>> => {
    const authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }
    email = email ? `email : "${email}"` : ""

    return await API.graphql({
        query: `mutation MyMutation {
            hsPaymentWithdrawalRequest(amount:${amount}, bank_account_number:"${bank_account_number}", name_as_per_bank_account:"${name_as_per_bank_account}", sort_code:"${sort_code}", ${email}  ) {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${getCookie((cookieNameForToken as any) || "hs_auth_token")}` }) as any
}

export const SendLoginIssue = async ({ hstmNumber = '', fileNumber = '', name, message }: MutationSendLoginIssueArgs): Promise<GraphQLResult<{ sendLoginIssue: Response }>> => {
    return await API.graphql({
        query: `mutation MyMutation {
            sendLoginIssue(hstmNumber:"${hstmNumber}", fileNumber:"${fileNumber}", name:"${name}", message:"${message}" ) {
                success 
                message
                warning
            }
          }
          `}) as any
}

export const AddQardanInstallment = async (input: AddQardanInstallmentInput): Promise<GraphQLResult<{ addQardanInstallment: QardanInstallment }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation ($input: AddQardanInstallmentInput!) {
            addQardanInstallment( input: $input ) {
                id
                date
                applicant_id
                amount
                description
            }
          }
          `, variables: { input }
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const DeleteQardanInstallment = async ({ id }: MutationDeleteQardanInstallmentArgs): Promise<GraphQLResult<{ deleteQardanInstallment: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            deleteQardanInstallment( id: ${id} ) {
                message
                success
                responseId
                warning
            }
          }
          `
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const AddQardanApplicant = async (input: AddQardanApplicantInput): Promise<GraphQLResult<{ addQardanApplicant: QardanApplicant }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation ($input: AddQardanApplicantInput!) {
            addQardanApplicant( input: $input ) {
                id
                userType
                title
                first_name
                last_name
                company_name
                email
                phone_no
                its_no
                loanAllocation { id date applicant_id amount repayment_date repayment_duration_in_months status }
            }
          }
          `, variables: { input }
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const ChangeQardanApplicantDetails = async (input: UpdateQardanApplicantInput): Promise<GraphQLResult<{ changeQardanApplicantDetails: QardanApplicant }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation ($input: UpdateQardanApplicantInput!) {
            changeQardanApplicantDetails( input: $input ) {
                id
                userType
                title
                first_name
                last_name
                company_name
                email
                phone_no
                its_no
                loanAllocation { id date applicant_id amount repayment_date repayment_duration_in_months status }
            }
          }
          `, variables: { input }
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const AddHSuser = async ({ id, first_name, last_name, email, phone_no, its_no }: MutationAddHSuserArgs): Promise<GraphQLResult<{ addHSuser: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            addHSuser(id:"${id}", first_name:"${first_name}", last_name:"${last_name}", its_no:${its_no}, email: "${email}", phone_no: "${phone_no}") {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const AddSBQHuser = async ({ email, name }: MutationAddSbqHuserArgs): Promise<GraphQLResult<{ addSBQHuser: SbqHuser }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            addSBQHuser(email:"${email}", name:"${name}" ) {
                email
                id
                name
                userType
                access {
                    HS_Accounts {
                        read
                        write
                    }
                    HS_Transactions {
                        read
                        write
                    }
                    HS_Withdrawal_Requests {
                        read
                        write
                    }
                    SBQH_Admin_Users {
                        read
                        write
                    }
                }
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const ChangeHSuserPassword = (cookieNameForToken?: string) => async (oldPassword: string, newPassword: string,): Promise<GraphQLResult<{ changeHSuserPassword: Response }>> => {
    const authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            changeHSuserPassword( oldPassword:"${oldPassword}", newPassword:"${newPassword}" ) {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${getCookie((cookieNameForToken as any) || "hs_auth_token")}` }) as any
}

export const ChangeSQBHuserPassword = async (oldPassword: string, newPassword: string,): Promise<GraphQLResult<{ changeSBQHuserPassword: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            changeSBQHuserPassword( oldPassword:"${oldPassword}", newPassword:"${newPassword}" ) {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const ChangeHSuserPasswordBySBQHuser = async (hsUserid: string, newPassword: string):Promise<GraphQLResult<{ changeHSuserPasswordBySBQHuser: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            changeHSuserPasswordBySBQHuser(hsUserid: "${hsUserid}", newPassword: "${newPassword}") {
              message
              success
              warning
            }
          }
           `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const ChangeQardanApplicantPassword = async (oldPassword: string, newPassword: string,): Promise<GraphQLResult<{ changeQardanApplicantPassword: Response }>> => {
    const authRes = isAuthTokenExpired("qardan_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            changeQardanApplicantPassword( oldPassword:"${oldPassword}", newPassword:"${newPassword}" ) {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${getCookie("qardan_auth_token")}` }) as any
}

export const ChangeQardanApplicantPasswordBySBQHuser = async (id: string, newPassword: string): Promise<GraphQLResult<{ changeQardanApplicantPasswordBySBQHuser: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            changeQardanApplicantPasswordBySBQHuser(applicantId: "${id}", newPassword: "${newPassword}") {
              message
              success
              warning
            }
          }
           `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const FulfillPaymentWithdrawlRequests = async (account_ids: number[]): Promise<GraphQLResult<{ fulfillPaymentWithdrawlRequests: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }
    /*make sure that all ids should be a number */
    const _account_ids = account_ids.join(', ')

    return await API.graphql({
        query: `mutation MyMutation {
            fulfillPaymentWithdrawlRequests( account_ids: [${_account_ids}] ) {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const DeleteHSuser = async (hsUserId: string): Promise<GraphQLResult<{ deleteHSuser: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            deleteHSuser( id: "${hsUserId}" ) {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const DeleteQardanApplicant = async (applicantId: string): Promise<GraphQLResult<{ deleteQardanApplicant: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            deleteQardanApplicant( id: "${applicantId}" ) {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}


export const ChangeHSuserDetails = (cookieNameForToken?: string) => async (userType: UserType, { first_name, id, last_name, email, its_no, phone_no }: MutationChangeHSuserDetailsArgs): Promise<GraphQLResult<{ changeHSuserDetails: Response }>> => {
    let authRes, jwtToken;
    if (userType === UserType.SbqhUser) {
        authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }
        jwtToken = getCookie("sbqh_auth_token")
    }
    else if (userType === UserType.HsUser) {
        authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }
        jwtToken = getCookie((cookieNameForToken as any) || "hs_auth_token")
    }

    const _its_no = its_no ? `its_no: ${its_no}` : "";
    const _email = email ? `email: "${email}"` : "";
    const _phone_no = phone_no ? `phone_no: "${phone_no}"` : "";

    return await API.graphql({
        query: `mutation MyMutation {
            changeHSuserDetails(id:"${id}", first_name:"${first_name}", last_name:"${last_name}", ${_its_no}, ${_email}, ${_phone_no} ) {
                success 
                message
                warning
            }
          }
          `}, { Authorization: `Bearer ${jwtToken}` }) as any
}


export const ChangeSBQHusersAccess = async (users_access: ChangeSbqHusersAccessInput[]): Promise<GraphQLResult<{ changeSBQHusersAccess: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation($users_access: [ChangeSBQHusersAccessInput!]!) {
            changeSBQHusersAccess(users_access: $users_access) {
              message
              warning
              success
            }
          }`,
        variables: { users_access }
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}


export const AddTransaction = async ({ hsUserId, date, amount, description }: MutationAddTransactionArgs): Promise<GraphQLResult<{ addTransaction: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            addTransaction(hsUserId: "${hsUserId}", date:${date}, amount: ${amount}, description: "${description}" ) {
              message
              warning
              success
              responseId
            }
          }`,
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}


export const UpdateTransaction = async ({ transaction_id, date, amount, description }: MutationUpdateTransactionArgs): Promise<GraphQLResult<{ updateTransaction: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            updateTransaction(transaction_id: ${transaction_id}, date:${date}, amount: ${amount}, description: ${description}) {
              message
              warning
              success
              responseId
            }
          }`,
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const DeleteTransaction = async ({ transaction_id }: MutationDeleteTransactionArgs): Promise<GraphQLResult<{ deleteTransaction: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            deleteTransaction(transaction_id: ${transaction_id}) {
              message
              warning
              success
              responseId
            }
          }`,
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const DeleteSBQHuser = async ({ id }: MutationDeleteSbqHuserArgs): Promise<GraphQLResult<{ deleteSBQHuser: Response }>> => {
    const authRes = isAuthTokenExpired("sbqh_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation {
            deleteSBQHuser(id: ${id}) {
              message
              warning
              success
            }
          }`,
    }, { Authorization: `Bearer ${getCookie("sbqh_auth_token")}` }) as any
}

export const AddHsUserBankAccount = (cookieNameForToken?: string) => async (args: MutationAddHsUserBankAccountArgs): Promise<GraphQLResult<{ addHsUserBankAccount: HSuserBank }>> => {
    const authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation($bank_account_number: String = "", $name_as_per_bank_account: String = "", $sort_code: String = "", $user_id: String = "") {
            addHsUserBankAccount(bank_account_number: $bank_account_number, name_as_per_bank_account: $name_as_per_bank_account, sort_code: $sort_code, user_id: $user_id) {
              bank_account_number
              id
              name_as_per_bank_account
              sort_code
              user_id
            }
          }`, variables: args
    }, { Authorization: `Bearer ${getCookie((cookieNameForToken as any) || "hs_auth_token")}` }) as any
}

export const DeleteHsUserBankAccount = (cookieNameForToken?: string) => async (args: MutationDeleteHsUserBankAccountArgs): Promise<GraphQLResult<{ deleteHsUserBankAccount: Response }>> => {
    const authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation($id: Int!) {
            deleteHsUserBankAccount(id: $id) {
              message
              responseId
              success
              warning
            }
          }`, variables: args
    }, { Authorization: `Bearer ${getCookie((cookieNameForToken as any) || "hs_auth_token")}` }) as any
}

export const UpdateHsUserBankAccount = (cookieNameForToken?: string) => async (args: MutationUpdateHsUserBankAccountArgs): Promise<GraphQLResult<{ updateHsUserBankAccount: Response }>> => {
    const authRes = isAuthTokenExpired((cookieNameForToken as any) || "hs_auth_token"); if (authRes) { throw authRes }

    return await API.graphql({
        query: `mutation MyMutation($id: Int!, $user_id: String!, $bank_account_number: String!, $name_as_per_bank_account: String!, $sort_code: String!) {
            updateHsUserBankAccount(bank_account_number: $bank_account_number, id: $id, name_as_per_bank_account: $name_as_per_bank_account, sort_code: $sort_code, user_id: $user_id) {
              message
              responseId
              success
              warning
            }
          }`, variables: args
    }, { Authorization: `Bearer ${getCookie((cookieNameForToken as any) || "hs_auth_token")}` }) as any
}
