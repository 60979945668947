import styled from 'styled-components';
const goldStripe = require('../../assets/golden-stripe.png');

export const Wrap = styled.div`
    width: 100%;
    min-height: 80px;
    background-color: #F0F0F0;
    >p{
        font-size: 15px;
        padding: 10px;

    }
`;

export const DesignStripe = styled.div`
    background: url(${goldStripe}) repeat-x  100% 100% / auto 100% ;
    background-position: -2px center;
    padding: 20px 0;
    width: 100%;
`