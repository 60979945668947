import React, { FC, useEffect, useState } from 'react'
import { BodyWrap } from "../taher-scheme";
import { PageProps, navigate } from 'gatsby';
import { Seo, PageLayout, BackDrop, Button } from '../../components';
import { siteMetadata } from '../../../gatsby-config';
import config from '../../../app-config.json';
import { Link } from 'gatsby';
import { NEW_ACCOUNT_FORM_LINK } from './new-account';
import { CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { MetaDescription } from './';
import { HSportalLoginForm } from '../../components/Form';
import { FormFields as LoginFormProps } from '../../components/Form/HSportalLoginForm';
import { checkTokenExpiryHSuser, getHsPortalDetails, loginToHSPortal, sendEmailConfirmationCode, verifyHSsecuritycode } from '../../reduxStore/slices/hsPortalSlice';
import { ErrorDialog, LoginFailDialog, WarningDialog } from '../../components/Alert';
import { GraphQLResult } from '../../typedefs';
import { HSloginResponse } from '../../graphql/types';
import { useAppDispatch, useAppSelector } from '../../reduxStore/store';
import { getCookie, getQueryParam } from '../../helper';
import { errorMessages } from '../../../../aws-cdk/lambda-fns/appsync-handlers/helper';
import * as formStyle from '../../components/Form/style';

const PAGE_TITLE = config.pages.HUSAIN_SCHEME.routes.login.title;
const PARENT_PAGE_TITLE = config.pages.HUSAIN_SCHEME.title;
const PARENT_PAGE_PATH = config.pages.HUSAIN_SCHEME.path;
const LOGIN_FORM_PATH = config.pages.HUSAIN_SCHEME.routes.login.path;
export const LOGIN_FORM_LINK = `${PARENT_PAGE_PATH}${LOGIN_FORM_PATH}`;

const Login: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${PARENT_PAGE_TITLE} | ${siteMetadata.title}`} propMetaDescription={MetaDescription} />
            <BodyWrap >
                <br />
                <Typography variant='h2' >{PARENT_PAGE_TITLE}</Typography>
                <Typography variant='h6' color='inherit' gutterBottom >(Account Login)</Typography>
                <hr /><br /> <br />
                <LoginFormHandler />
                {/* <br /> */}
                {/* <Typography style={{ minWidth: '280px', maxWidth: '500px', margin: '0 auto' }} >Don't have an account? <Link to={NEW_ACCOUNT_FORM_LINK} >Click here</Link></Typography> */}
                <br />
            </BodyWrap>
        </PageLayout>
    )
};

export default Login;


const LoginFormHandler: FC<{}> = ({ }) => {
    const dispatch = useAppDispatch();
    const { fetchingStatus } = getHsPortalDetails();
    const [loginDone, setLoginDone] = useState(false);
    const [hsUserId, setHsUserId] = useState("");

    /* Login callback */
    const onLogin = async (val: LoginFormProps) => {
        setHsUserId(val.hstmNumber);
        const { res } = await new Promise<{ err?: GraphQLResult<any>, res?: HSloginResponse }>((resolve, reject) => {
            dispatch(loginToHSPortal({
                userId: val.hstmNumber, password: val.password,
                callback: (err, res) => {
                    if (res) {
                        res.accessToken && navigate(PARENT_PAGE_PATH);
                        resolve({ res });
                        return
                    };
                    err && resolve({ err });
                    err?.errors && LoginFailDialog(err.errors[0].message)
                }
            }));
        });
        res?.userDetails && setLoginDone(true);
    }

    useEffect(() => {
        (async () => {
            const cookieNameForToken = getQueryParam('child_acc')
            const query = window.location.search
            // console.log(getCookie("hs_auth_token"))
            if (getCookie((cookieNameForToken as any) || "hs_auth_token")) {
                const { err, res } = await new Promise<{ err: any, res: any }>((resolve, reject) => {
                    dispatch(checkTokenExpiryHSuser({
                        callback: (err, res) => {
                            resolve({ err, res })
                        }
                    }))
                })
                if (res) { navigate(PARENT_PAGE_PATH + query) }
            }
        })()
    }, [])

    return (
        <>
            <BackDrop open={fetchingStatus.checkTokenExpiryHSuser} >
                <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
            </BackDrop>

            {!loginDone ?
                <HSportalLoginForm handleSubmit={onLogin} /> :
                <VerifyCode hsUserId={hsUserId} />
            }
            {/* <LoginForm idName={hsNumberFieldName} idTitle='Enter HS/TM Number'
                handleSubmit={onLogin}
            /> */}
        </>
    )
}


const VerifyCode: FC<{ hsUserId: string }> = ({ hsUserId }) => {
    const dispatch = useAppDispatch();
    const isVerifyingCode = useAppSelector(s => s.hsPortal.fetchingStatus.verifyHSsecuritycode);
    const [code, setCode] = useState("");

    /* verificationCode callback */
    const onCodeVerify = async () => {
        await new Promise<GraphQLResult<any> | HSloginResponse>((resolve, reject) => {
            dispatch(verifyHSsecuritycode({
                hsUserId, verificationCode: code,
                callback: (err, res) => {
                    if (res) { navigate(PARENT_PAGE_PATH); resolve(res) };
                    err?.errors && ErrorDialog("Verification Failed", err.errors[0].message)
                    err && resolve(err);
                }
            }));
        })
    }

    return (
        <Grid style={{ ...formStyle.formStyle(), minWidth: '280px', maxWidth: '500px', margin: '0 auto' }} >
            <BackDrop open={isVerifyingCode} >
                <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
            </BackDrop>

            <Typography>
                <span style={{ fontSize: "22px", fontWeight: "bold" }} >Enter your verification code</span>
                <br /><br />
                A Verification Code has been sent to your email address.
            </Typography>

            <br />

            <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                <Grid item container alignItems='center' >
                    <TextField required fullWidth label="Code" type='text' variant='outlined' value={code} onChange={e => setCode(e.target.value)} />
                </Grid>
                <br />
                <Grid item container direction='column' >
                    <Button onClick={onCodeVerify} disabled={(!code || isVerifyingCode)} color='primary' size='large' type='submit' >
                        {isVerifyingCode ? <div><CircularProgress color='inherit' /></div> : <div>Verify</div>}
                    </Button>
                </Grid>
            </Grid>
            <br />
        </Grid>
    )
}
