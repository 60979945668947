import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { HSuser, UserType, Transaction, HSuserFullInfo, FilterOptions, LogicalOperator, Order, HSuserBank } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes'
import { GraphQLResult } from '../../../typedefs';
import { } from '../../../graphql/types';
import { RootStateType } from '../../store';
import { changeHSuserDetails, checkTokenExpiryHSuser, getAllHStransactions, getHSuserInfo, loginToHSPortal, hsPaymentWithdrawalRequest, changeHSuserPassword, verifyHSsecuritycode, sendEmailConfirmationCode, getHsUserBankAccounts, addHsUserBankAccount, updateHsUserBankAccount, deleteHsUserBankAccount, getHsUserChildAccessToken } from './reducers';
import { errorMessages } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/helper';
// import appConfig from '../../../../app-config.json';
import { getQueryParam, setCookie } from '../../../helper';
import moment from 'moment';
import { WritableDraft } from 'immer/dist/internal';
import { WarningDialog, InfoDialog } from '../../../components/Alert';
import { LOGIN_FORM_LINK } from '../../../pages/husain-scheme/login';

type StateType = {
    authToken?: string | null,
    isAuthenticated: boolean,
    isLoggedInWithTempPassword: boolean | null,
    userDetails: HSuser | null,
    accountBalance: HSuserFullInfo['balance_amount'],
    transactionDetails: { data: null | Transaction[], totalCount: number, filterOptions: FilterOptions },
    userBanks: HSuserBank[]
    fetchingStatus: {
        loginToHSPortal: boolean
        getAllHStransactions: boolean
        checkTokenExpiryHSuser: boolean
        hsPaymentWithdrawalRequest: boolean
        getHSuserInfo: boolean
        changeHSuserPassword: boolean
        changeHSuserDetails: boolean
        verifyHSsecuritycode: boolean
        sendEmailConfirmationCode: boolean
        getHsUserBankAccounts: boolean
        addHsUserBankAccount: boolean
        updateHsUserBankAccount: boolean
        deleteHsUserBankAccount: boolean
        getHsUserChildAccessToken: boolean
    },
    error: {
        loginToHSPortal: any | null
        getAllHStransactions: any | null
        checkTokenExpiryHSuser: any | null
        hsPaymentWithdrawalRequest: any | null
        getHSuserInfo: any | null
        changeHSuserPassword: any | null
        changeHSuserDetails: any | null
        verifyHSsecuritycode: any | null
        sendEmailConfirmationCode: any | null
        getHsUserBankAccounts: any | null
        addHsUserBankAccount: any | null
        updateHsUserBankAccount: any | null
        deleteHsUserBankAccount: any | null
        getHsUserChildAccessToken: any | null
    }
}
const initialState: StateType = {
    authToken: null,
    isAuthenticated: false,
    userDetails: null,
    isLoggedInWithTempPassword: null,
    accountBalance: 0,
    userBanks: [],
    transactionDetails: {
        data: null, totalCount: 0,
        filterOptions: {
            filterBy: [],
            logicalOperator: LogicalOperator.And,
            pageNumber: 1,
            pageSize: 100,
            sortBy: { fields: ["date"], order: Order.Desc }
        }
    },
    fetchingStatus: {
        loginToHSPortal: false,
        getHSuserInfo: false,
        checkTokenExpiryHSuser: false,
        hsPaymentWithdrawalRequest: false,
        getAllHStransactions: false,
        changeHSuserPassword: false,
        changeHSuserDetails: false,
        verifyHSsecuritycode: false,
        sendEmailConfirmationCode: false,
        getHsUserBankAccounts: false,
        addHsUserBankAccount: false,
        updateHsUserBankAccount: false,
        deleteHsUserBankAccount: false,
        getHsUserChildAccessToken: false,
    },
    error: {
        loginToHSPortal: null,
        getHSuserInfo: null,
        checkTokenExpiryHSuser: null,
        hsPaymentWithdrawalRequest: null,
        getAllHStransactions: false,
        changeHSuserPassword: false,
        changeHSuserDetails: false,
        verifyHSsecuritycode: false,
        sendEmailConfirmationCode: false,
        getHsUserBankAccounts: false,
        addHsUserBankAccount: false,
        updateHsUserBankAccount: false,
        deleteHsUserBankAccount: false,
        getHsUserChildAccessToken: false,
    }
}

const slice = createSlice({
    name: 'hsPortalSlice',
    initialState,
    reducers: {
        // authenticateUser: (state, { payload }: PayloadAction<{ authToken: string }>) => {
        //     state.authToken = payload.authToken
        //     state.isAuthenticated = true
        // },
        logoutFromHsPortal: (state) => {
            const cookieNameForToken = getQueryParam('child_acc')
            setCookie((cookieNameForToken as any) || "hs_auth_token", "", { "max-age": -1, path: "/" });
            return initialState
        },
    },

    extraReducers(builder) {

        builder.addCase(loginToHSPortal.pending, handlePending("loginToHSPortal"))
        builder.addCase(loginToHSPortal.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.loginToHSPortal = false
            state.error.loginToHSPortal = null
            state.isLoggedInWithTempPassword = false;
            const { accessToken, userDetails } = payload.data.loginToHSPortal
            state.isAuthenticated = !!accessToken;
            state.authToken = accessToken;
            state.userDetails = userDetails;

            if (payload.data.loginToHSPortal.warning === errorMessages.loginToHSPortal.TemporaryPasswordWarning) {
                InfoDialog({ title: "Information", message: "You are logged in for the first time. Please update your account details in order to proceed further." })
                state.isLoggedInWithTempPassword = true;
                accessToken && setCookie("hs_auth_token", accessToken, { "max-age": 1200, path: '/', samesite: "lax" }, true)
            }

            // setCookie("hs_auth_token", accessToken, { "max-age": 3600, path: appConfig.pages.HUSAIN_SCHEME.path, samesite: "lax" }, true)
            arg.callback && arg.callback(null, payload.data.loginToHSPortal)
        })
        builder.addCase(loginToHSPortal.rejected, handleReject("loginToHSPortal"))


        builder.addCase(verifyHSsecuritycode.pending, handlePending("verifyHSsecuritycode"))
        builder.addCase(verifyHSsecuritycode.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.userDetails) { return }
            state.fetchingStatus.verifyHSsecuritycode = false;
            state.error.verifyHSsecuritycode = null;
            const { accessToken, userDetails } = payload.data.verifyHSsecuritycode;
            state.isAuthenticated = !!accessToken;
            state.authToken = accessToken;
            state.userDetails = userDetails;
            const cookieNameForToken = getQueryParam('child_acc')
            accessToken && setCookie((cookieNameForToken as any) || "hs_auth_token", accessToken, { "max-age": 1200, path: '/', samesite: "lax" }, true)
            arg.callback && arg.callback(null, payload.data.verifyHSsecuritycode)
        })
        builder.addCase(verifyHSsecuritycode.rejected, handleReject("verifyHSsecuritycode"))


        builder.addCase(sendEmailConfirmationCode.pending, handlePending("sendEmailConfirmationCode"))
        builder.addCase(sendEmailConfirmationCode.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.userDetails) { return }
            state.fetchingStatus.sendEmailConfirmationCode = false;
            state.error.sendEmailConfirmationCode = null;
            payload.data.sendEmailConfirmationCode;
            // arg.callback && arg.callback(null, payload.data.sendEmailConfirmationCode)
        })
        builder.addCase(sendEmailConfirmationCode.rejected, handleReject("sendEmailConfirmationCode"))


        builder.addCase(changeHSuserPassword.pending, handlePending("changeHSuserPassword"))
        builder.addCase(changeHSuserPassword.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            const response = payload.data.changeHSuserPassword
            state.fetchingStatus.changeHSuserPassword = false;
            state.error.changeHSuserPassword = null;
            if (state.isLoggedInWithTempPassword === true) {
                state.isLoggedInWithTempPassword = false;
            }
            arg.callback && arg.callback(null, response)
        })
        builder.addCase(changeHSuserPassword.rejected, handleReject("changeHSuserPassword"))


        builder.addCase(getAllHStransactions.pending, handlePending("getAllHStransactions"))
        builder.addCase(getAllHStransactions.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            const hsTransactions = payload.data.getAllHStransactions
            state.fetchingStatus.getAllHStransactions = false;
            state.error.getAllHStransactions = null;

            state.transactionDetails = {
                data: hsTransactions.data || [],
                totalCount: hsTransactions.totalCount,
                filterOptions: arg,
            }
        })
        builder.addCase(getAllHStransactions.rejected, handleReject("getAllHStransactions"))


        builder.addCase(getHSuserInfo.pending, handlePending("getHSuserInfo"))
        builder.addCase(getHSuserInfo.fulfilled, (state, { payload }) => {
            if (!payload.data) { return }
            const hsuserInfo = payload.data.getHSuserInfo
            state.fetchingStatus.getHSuserInfo = false
            state.accountBalance = hsuserInfo.balance_amount;
        })
        builder.addCase(getHSuserInfo.rejected, handleReject("getHSuserInfo"))


        builder.addCase(checkTokenExpiryHSuser.pending, handlePending("checkTokenExpiryHSuser"))
        builder.addCase(checkTokenExpiryHSuser.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.checkTokenExpiryHSuser = false
            state.isAuthenticated = true;
            state.authToken = payload.data.checkTokenExpiry.accessToken
            state.userDetails = payload.data.checkTokenExpiry.userDetails
            if (payload.data.checkTokenExpiry.warning === errorMessages.loginToHSPortal.TemporaryPasswordWarning) {
                InfoDialog({ title: "Information", message: "You are logged in for the first time. Please update your account details in order to proceed further." })
                state.isLoggedInWithTempPassword = true;
            }
            arg.callback && arg.callback(null, payload.data.checkTokenExpiry)
        })
        builder.addCase(checkTokenExpiryHSuser.rejected, handleReject("checkTokenExpiryHSuser"))


        builder.addCase(hsPaymentWithdrawalRequest.pending, handlePending("hsPaymentWithdrawalRequest"))
        builder.addCase(hsPaymentWithdrawalRequest.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.hsPaymentWithdrawalRequest = false;
            state.isAuthenticated = true;
            arg.callback && arg.callback(null, payload.data.hsPaymentWithdrawalRequest);
        })
        builder.addCase(hsPaymentWithdrawalRequest.rejected, handleReject("hsPaymentWithdrawalRequest"))


        builder.addCase(changeHSuserDetails.pending, handlePending("changeHSuserDetails"))
        builder.addCase(changeHSuserDetails.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.userDetails) { return }
            state.fetchingStatus.changeHSuserDetails = false;
            state.error.changeHSuserDetails = null;
            state.userDetails = {
                ...state.userDetails,
                id: arg.input.id,
                first_name: arg.input.first_name,
                last_name: arg.input.last_name,
                email: arg.input.email,
                its_no: arg.input.its_no,
                phone_no: arg.input.phone_no
            }
            arg.callback && arg.callback(null, payload.data.changeHSuserDetails)
        })
        builder.addCase(changeHSuserDetails.rejected, handleReject("changeHSuserDetails"))

        builder.addCase(getHsUserBankAccounts.pending, handlePending("getHsUserBankAccounts"))
        builder.addCase(getHsUserBankAccounts.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.userDetails) { return }
            state.fetchingStatus.getHsUserBankAccounts = false;
            state.error.getHsUserBankAccounts = null;
            state.userBanks = payload.data.getHsUserBankAccounts
        })
        builder.addCase(getHsUserBankAccounts.rejected, handleReject("getHsUserBankAccounts"))

        builder.addCase(addHsUserBankAccount.pending, handlePending("addHsUserBankAccount"))
        builder.addCase(addHsUserBankAccount.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.userDetails) { return }
            state.fetchingStatus.addHsUserBankAccount = false;
            state.error.addHsUserBankAccount = null;
            state.userBanks.unshift(payload.data.addHsUserBankAccount)
            // state.userBanks = [payload.data.addHsUserBankAccount, ...state.userBanks ]
            arg.callback && arg.callback(null, payload.data.addHsUserBankAccount)
        })
        builder.addCase(addHsUserBankAccount.rejected, handleReject("addHsUserBankAccount"))

        builder.addCase(updateHsUserBankAccount.pending, handlePending("updateHsUserBankAccount"))
        builder.addCase(updateHsUserBankAccount.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.userDetails) { return }
            state.fetchingStatus.updateHsUserBankAccount = false;
            state.error.updateHsUserBankAccount = null;
            const index = state.userBanks.findIndex(v => v.id === arg.input.id);
            if (index !== -1) {
                state.userBanks[index] = { ...state.userBanks[index], ...arg.input };
            }
            arg.callback && arg.callback(null, payload.data.updateHsUserBankAccount)
        })
        builder.addCase(updateHsUserBankAccount.rejected, handleReject("updateHsUserBankAccount"))

        builder.addCase(deleteHsUserBankAccount.pending, handlePending("deleteHsUserBankAccount"))
        builder.addCase(deleteHsUserBankAccount.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data || !state.userDetails) { return }
            state.fetchingStatus.deleteHsUserBankAccount = false;
            state.error.deleteHsUserBankAccount = null;
            const index = state.userBanks.findIndex(v => v.id === arg.input.id);
            if (index !== -1) {
                state.userBanks = state.userBanks.filter(v => v.id !== arg.input.id);
            }
            arg.callback && arg.callback(null, payload.data.deleteHsUserBankAccount)
        });
        builder.addCase(deleteHsUserBankAccount.rejected, handleReject("deleteHsUserBankAccount"))


        builder.addCase(getHsUserChildAccessToken.pending, handlePending("getHsUserChildAccessToken"))
        builder.addCase(getHsUserChildAccessToken.fulfilled, (state, { payload, meta: { arg } }) => {
            if (!payload.data) { return }
            state.fetchingStatus.getHsUserChildAccessToken = false;
            state.error.getHsUserChildAccessToken = null;
            const accessToken = payload.data.getHsUserChildAccessToken;
            console.log("accessToken", accessToken);
            if (typeof accessToken === 'string') {
                setCookie((arg.childUserId as any), accessToken, { "max-age": 1200, path: '/', samesite: "lax" }, true)
                const link = document.createElement('a');
                link.href = `${LOGIN_FORM_LINK}?child_acc=${arg.childUserId}`;
                link.target = "_blank"
                // document.body.appendChild(link);
                link.click();
            }


        });
        builder.addCase(getHsUserChildAccessToken.rejected, handleReject("getHsUserChildAccessToken"))


    }
})

export const { logoutFromHsPortal } = slice.actions;
export { getHsUserChildAccessToken, changeHSuserDetails, addHsUserBankAccount, getHsUserBankAccounts, updateHsUserBankAccount, deleteHsUserBankAccount, loginToHSPortal, verifyHSsecuritycode, sendEmailConfirmationCode, getAllHStransactions, getHSuserInfo, changeHSuserPassword, checkTokenExpiryHSuser, hsPaymentWithdrawalRequest };
export default slice.reducer;
export const getHsPortalDetails = () => useSelector((state: RootStateType) => state.hsPortal);


function handlePending(thunkName: string) {
    return (state: WritableDraft<StateType>, { meta: { arg } }) => {
        state.fetchingStatus[thunkName] = true
    }
}

function handleReject(thunkName: string) {
    return (state: WritableDraft<StateType>, { error, meta: { arg } }) => {
        const err = JSON.parse(error.message || "{}") as GraphQLResult<any>
        console.log(`${thunkName}_Error`, err);
        state.error[thunkName] = err.errors ? err.errors[0].message : err;
        state.fetchingStatus[thunkName] = false
        arg?.callback && arg.callback(err, null)
    }
}
