// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-login-tsx": () => import("./../../../src/pages/admin/login.tsx" /* webpackChunkName: "component---src-pages-admin-login-tsx" */),
  "component---src-pages-apply-qardan-hasana-tsx": () => import("./../../../src/pages/apply-qardan-hasana.tsx" /* webpackChunkName: "component---src-pages-apply-qardan-hasana-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-husain-scheme-index-tsx": () => import("./../../../src/pages/husain-scheme/index.tsx" /* webpackChunkName: "component---src-pages-husain-scheme-index-tsx" */),
  "component---src-pages-husain-scheme-login-tsx": () => import("./../../../src/pages/husain-scheme/login.tsx" /* webpackChunkName: "component---src-pages-husain-scheme-login-tsx" */),
  "component---src-pages-husain-scheme-new-account-tsx": () => import("./../../../src/pages/husain-scheme/new-account.tsx" /* webpackChunkName: "component---src-pages-husain-scheme-new-account-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mohammedi-scheme-index-tsx": () => import("./../../../src/pages/mohammedi-scheme/index.tsx" /* webpackChunkName: "component---src-pages-mohammedi-scheme-index-tsx" */),
  "component---src-pages-mohammedi-scheme-payment-form-tsx": () => import("./../../../src/pages/mohammedi-scheme/payment-form.tsx" /* webpackChunkName: "component---src-pages-mohammedi-scheme-payment-form-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-qardan-hasana-apply-tsx": () => import("./../../../src/pages/qardan-hasana/apply.tsx" /* webpackChunkName: "component---src-pages-qardan-hasana-apply-tsx" */),
  "component---src-pages-qardan-hasana-index-tsx": () => import("./../../../src/pages/qardan-hasana/index.tsx" /* webpackChunkName: "component---src-pages-qardan-hasana-index-tsx" */),
  "component---src-pages-qardan-hasana-login-tsx": () => import("./../../../src/pages/qardan-hasana/login.tsx" /* webpackChunkName: "component---src-pages-qardan-hasana-login-tsx" */),
  "component---src-pages-taher-scheme-index-tsx": () => import("./../../../src/pages/taher-scheme/index.tsx" /* webpackChunkName: "component---src-pages-taher-scheme-index-tsx" */),
  "component---src-pages-taher-scheme-payment-form-tsx": () => import("./../../../src/pages/taher-scheme/payment-form.tsx" /* webpackChunkName: "component---src-pages-taher-scheme-payment-form-tsx" */)
}

