import { createAsyncThunk } from "@reduxjs/toolkit";
import { CheckTokenExpiryQardanApplicant, LoginToQardanPortal, VerifyQardanSecuritycode, GetQardanApplicantInfo, GetAllQardanInstallments } from '../../../graphql/queries';
import { ChangeQardanApplicantPassword } from '../../../graphql/mutations';
import { QardanloginResponse } from '../../../graphql/types';
import { FilterOptions, MutationChangeQardanApplicantPasswordArgs, QueryGetAllQardanInstallmentsArgs, QueryGetQardanApplicantInfoArgs, Response, UserType } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { GraphQLResult } from "../../../typedefs";


type LoginToQardanPortalProps = { userId: string, password: string, callback?: (err: GraphQLResult<any> | null, res: QardanloginResponse | null) => void }
export const loginToQardanPortal = createAsyncThunk(
    "query/loginToQardanPortal",
    async ({ userId, password }: LoginToQardanPortalProps, thunkAPI) => {
        try {
            return await LoginToQardanPortal(userId, password)
        } catch (error) {
            console.log("loginToQardanPortal-error", error)
            throw JSON.stringify(error);
        }
    }
)

type CheckTokenExpiryQardanApplicantProps = { callback?: (err: GraphQLResult<any> | null, res: QardanloginResponse | null) => void }
export const checkTokenExpiryQardanApplicant = createAsyncThunk(
    "query/checkTokenExpiryQardanApplicant",
    async ({ }: CheckTokenExpiryQardanApplicantProps, thunkAPI) => {
        try {
            return await CheckTokenExpiryQardanApplicant()
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type VerifyHSsecuritycodeProps = { applicantId: string, verificationCode: string, callback?: (err: GraphQLResult<any> | null, res: QardanloginResponse | null) => void }
export const verifyQardanSecuritycode = createAsyncThunk(
    "query/verifyQardanSecuritycode",
    async ({ applicantId, verificationCode }: VerifyHSsecuritycodeProps, thunkAPI) => {
        try {
            return await VerifyQardanSecuritycode(applicantId, verificationCode)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const getAllQardanInstallments = createAsyncThunk(
    "query/qardanAuth/getAllQardanInstallments",
    async (filter: FilterOptions, thunkAPI) => {
        try {
            return await GetAllQardanInstallments(UserType.QardanUser, { filter })
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

export const getQardanApplicantInfo = createAsyncThunk(
    "query/qardanAuth/getQardanApplicantInfo",
    async ({ applicantId }: QueryGetQardanApplicantInfoArgs, thunkAPI) => {
        try {
            return await GetQardanApplicantInfo(UserType.QardanUser, { applicantId })
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)

type changeQardanApplicantPasswordProps = MutationChangeQardanApplicantPasswordArgs & { callback?: (err?: any, res?: Response) => void }
export const changeQardanApplicantPassword = createAsyncThunk(
    "mutation/changeQardanApplicantPassword",
    async ({ oldPassword, newPassword }: changeQardanApplicantPasswordProps, thunkAPI) => {
        try {
            return await ChangeQardanApplicantPassword(oldPassword, newPassword)
        } catch (error) {
            throw JSON.stringify(error);
        }
    }
)



