export class ApplyQardanHasanaFormFields {
  static storageKey: string = 'apply-qardan-form-values';

  static get = () => {
    const jsonSting = localStorage.getItem(ApplyQardanHasanaFormFields.storageKey);
    if (jsonSting) {
      return JSON.parse(jsonSting) as { formvalues: object };
    }
    return null;
  };

  static set = ({ formvalues }: { formvalues: object }) => {
    localStorage.setItem(ApplyQardanHasanaFormFields.storageKey, JSON.stringify({ formvalues }));
  };

  static remove = () => {
    localStorage.removeItem(ApplyQardanHasanaFormFields.storageKey);
  };
}
