import { Mutation, Query } from "../schemaTypes";
// import { GQL_QUERRIES } from '../query-handler';
// import { } from '../mutation-handler';

const QUERRIES_MUTATIONS: { [P in keyof Query]?: P } & { [P in keyof Mutation]?: P } = {
    loginToHSPortal: "loginToHSPortal",
    loginToQardanPortal: "loginToQardanPortal"
}

const messages = {
    [QUERRIES_MUTATIONS.loginToHSPortal!]: {
        TemporaryPasswordWarning: "Warning: This is a temporary password, please change your password."
    },
    [QUERRIES_MUTATIONS.loginToQardanPortal!]: {
        TemporaryPasswordWarning: "Warning: This is a temporary password, please change your password."
    },
}

export default messages;