import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Amplify from "aws-amplify";
import awsmobile from "./aws-exports";
import { Provider } from 'react-redux';
import store from './reduxStore/store';

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            h2: { fontSize: "48px" },
        },
    },
    breakpoints: {

    }
});

export const wrapRootElement = ({ element }) => {
    Amplify.configure(awsmobile);
    // const widthUnder500 = useMediaQuery(`(max-width: 500px)`);
    // console.log(widthUnder500);


    return (
        <ThemeProvider theme={theme} >
            <Provider store={store}>
                {/* <Font /> */}
                {element}
            </Provider>
        </ThemeProvider>
    )
};
