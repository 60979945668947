import styled, { keyframes } from 'styled-components';
import { CSSProperties } from 'react';
import colors from '../../theme/colors';
import { displayFlex, screenWidths } from '../../theme/css-generators';


export const HeaderWrap = styled.div<{ isFixed?: boolean, showHeader: boolean }>`
     ${({ isFixed, showHeader }) => isFixed ?
        `position:fixed; 
        background-color:${colors[2]};
        top: ${showHeader ? "0px" : '-300px'};
        box-shadow: 0px 0px 10px grey;
        z-index: 200;
        ` :
        ''
    }
    user-select: none;
    width: 100%;
    padding: 0px 0px;
    margin-bottom: 10px;
    transition: 0.5s ease-in-out;
    ${displayFlex({ dir: 'column', align: 'normal' })}
    >:only-child{ width: 100%; }
`


export const NavLinks = styled.ul.attrs(() => ({
    fontSize: 17.3,
})) <{ compact?: boolean, justify: CSSProperties['justifyContent'] }>`
    width: 100%;
    padding-left: 5px;
    ${({ justify }) => displayFlex({ justify, align: 'center' })}
    &>li{
        color: ${colors[4]};
        >a{
            text-decoration:none; color:inherit; 
            >P{ font-size: ${({ fontSize }) => `${fontSize}px`} !important;}
        }
        >p{font-size: ${({ fontSize }) => `${fontSize}px`} !important; }
        ${displayFlex({})}
        list-style: none;
        transition: 0.15s ease-in-out;
        border-radius: 40px;
        padding: 5px 10px;
        ${({ compact }) => compact ? "padding: 5px 10px;" : "padding: 5px 18px;"}
        ${({ compact }) => compact ? "margin: 0 4px;" : "margin: 0 8px;"}
        
        &#__contact-link_, &#__apply-qardan_, &#__contribure-online_{margin-left: 0;}
        &#__scheme-links_{padding-right:8px;}

        :hover{
            background-color: ${colors[6]};
            color: white !important;
        }
        :active{
            background-color: ${"#EDBB4A"};
        }
    }

    @media (max-width: ${screenWidths._1600px}){
       &>li{
        >a{>P{ font-size: ${({ fontSize }) => `${fontSize - 2}px`} !important;}}
        >p{font-size: ${({ fontSize }) => `${fontSize - 2}px`} !important; }
    }}
    @media (max-width: ${screenWidths._1503px}){
       &>li{
        >a{>P{ font-size: ${({ fontSize }) => `${fontSize - 3}px`} !important;}}
        >p{font-size: ${({ fontSize }) => `${fontSize - 3}px`} !important; }
    }}
    @media (max-width: ${screenWidths._1375px}){
       &>li{
        >a{>P{ font-size: ${({ fontSize }) => `${fontSize - 4}px`} !important;}}
        >p{font-size: ${({ fontSize }) => `${fontSize - 4}px`} !important; }
    }}
    @media (max-width: ${screenWidths._1280px}){
       &>li{
        >a{>P{ font-size: ${({ fontSize }) => `${fontSize - 5.5}px`} !important;}}
        >p{font-size: ${({ fontSize }) => `${fontSize - 5.5}px`} !important; }
    }}
`

export const NavTitle = styled.div`
    ${displayFlex({ justify: 'space-between' })};
    text-align: center;
    >div{width:100%; display:flex;}

    @media (max-width: ${screenWidths._960px}){
        width:100%;
     }
    
`


const swipeDown = keyframes`
    0% {  transform: translate(0px, -110%); }
    100% {  transform: translate(0px, 0 ); }
`


export const DropDownWrap = styled.div<{ x: number, y: number }>`
    position: absolute;
    z-index: 100;
    transform: translate(${({ x }) => x}px,${({ y }) => y}px);
    overflow-y: hidden;
`

export const DropDownContainer = styled.div<{ open: boolean, width?:number }>`
    box-shadow: 3px 3px 7px grey;
    ${displayFlex({ dir: 'column', justify: 'normal', align: 'normal' })};
    animation: ${swipeDown} 0.2s ease-in-out forwards;
    >a{
        ${({width})=>width&&`width: ${width}px;`}
        text-decoration: none;
        color: ${colors[4]};
        background-color: ${colors[2]};
        transition: 0.20s ease-in-out;
        &>div{
            padding: 15px;
        }

        &:hover{
            background-color: ${colors[6]};
            color: whitesmoke;
        }
    }
`

