import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FastField, FormikHelpers } from 'formik';
import { TextField, } from 'formik-material-ui';
import { Typography, Grid, Checkbox, CircularProgress, InputAdornment } from '@material-ui/core';
// import { AccountCircle, EuroSymbol, Business, LocalAtm, Description, Person } from '@material-ui/icons';
import * as s from './style';
// import { countries } from './countries.json';
import { StyledBtn } from '../Button';
// import * as yup from 'yup';
// import { schemaObject, formInitialValues as formFieldValues, field as userDetailField } from './UserDetails';
// import { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import Alert, { SuccessDialog, ErrorDialog } from '../Alert';
import { applyQardanHasana } from './form';
import { ApplyQardanHasanaFormFields } from '../../helper/local-storage-handler';
import { applyQardanHasana as applyQardanHasanaFormApi } from '../../services/apply-qardan-hasana';
import { GenerateFormDefaultValues, GenerateFormSchema, GenerateFrom } from './utils';
import { setValueInObjectField } from '../../helper';
import { Link, navigate } from 'gatsby';
import { pages } from '../../../app-config.json';
import { filterNumber } from './UserDetails';


const onSubmit = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
    console.log(values);
    const { data, error } = await applyQardanHasanaFormApi({ formvalues: values });
    error && ErrorDialog("Something Went Wrong!", error.response?.data.message);
    data && (await SuccessDialog("Form Submitted", "Your application has been submitted successfully. One of the Qardan Hasana committee members will be in touch soon."));
    if (data && process.env.NODE_ENV !== "development") {
        // ApplyQardanHasanaFormFields.remove();
        navigate("/") // move to home
    }
    setSubmitting(false);

}

const onSaveAndContinue = async (_values: any) => {
    console.log("values", _values);
    const values = JSON.parse(JSON.stringify(_values));
    
    ["personal.applicant_photo", "personal.applicant_passport", "professional.supporting_documents",
        "professional.safai_chitthi", "guarantor.guarantor_1_photo", "guarantor.guarantor_1_passport",
        "guarantor.guarantor_2_photo", "guarantor.guarantor_2_passport"]
        .forEach(key => {
            setValueInObjectField(values, key, "");
        })
    // await applyQardanHasanaFormApi({formvalues: values as any });
    ApplyQardanHasanaFormFields.set({ formvalues: values });
    SuccessDialog("Saved", "Your details has been successfully saved.")
    // localStorage.setItem("apply-qardan-form-values", JSON.stringify(values));
}

//////////////////////////////////  component ////////////////////////////////
const ApplyQardanHasana: FC<{}> = () => {
    const classes = s.useStyles();
    const [formInitialValues, setFormInitialValues] = useState<object | null>(null)
    // console.log("new values",new GenerateFormDefaultValues(applyQardanHasana().formGroup).run());

    const formSchema = new GenerateFormSchema(applyQardanHasana().formGroup).run();
    const formFields = new GenerateFrom(applyQardanHasana().formGroup, classes.fieldSet);
    const renderedFormFields = formFields.render();
    formInitialValues && console.log("formInitialValues", formInitialValues)

    useEffect(() => {
        setFormInitialValues(ApplyQardanHasanaFormFields.get()?.formvalues || new GenerateFormDefaultValues(applyQardanHasana().formGroup).run())
    }, [])

    if (!formInitialValues) { return null }
    // ApplyQardanHasanaFormFields.get();
    return (
        <Formik initialValues={formInitialValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
        >
            {(formikProps) => {
                formFields.setFormikProps(formikProps);
                const { isValid, isSubmitting, values, errors } = formikProps;
                // console.log("errors", errors)
                return (
                    <Form style={s.formStyle()}>

                        {renderedFormFields}

                        <br />

                        <Typography color='textPrimary' >Confirmation and personal information *</Typography>
                        <Grid item container xs={12} sm={6} alignContent='center' >
                            <Grid item ><Checkbox
                                style={{ margin: '0 10px 0 0', padding: '3px' }}
                                color='primary'
                            // onChange={(e) => { setState({ ...state, jewellery: e.target.checked }) }}
                            // inputProps={{ 'aria-label': 'jewellery checkbox' }}
                            /></Grid>
                            <Grid item container alignContent='center' xs={3}  ><Typography>I agree</Typography></Grid>
                        </Grid>
                        <Typography color='textPrimary' variant='caption'>
                            I confirm the information is complete and accurate to the best of my knowledge and that the
                            information will be used to consider the application and obtain information from credit
                            reference agencies.
                        </Typography>
                        <br /><br />

                        <Typography color='textPrimary' >Privacy policy</Typography>
                        <Grid item container xs={12} alignContent='center' >
                            <Grid item ><Checkbox
                                style={{ margin: '0 10px 0 0', padding: '3px' }}
                                color='primary'
                            // onChange={(e) => { setState({ ...state, jewellery: e.target.checked }) }}
                            // inputProps={{ 'aria-label': 'jewellery checkbox' }}
                            /></Grid>
                            <Grid item container alignContent='center' xs={11}  ><Typography>I agree to the <Link to={pages.PRIVACY_POLICY.path} >privacy policy</Link> and would like to proceed with the application.</Typography></Grid>
                        </Grid>
                        <br /><br />

                        {/* /////////////////////////////////////////   Submit /////////////////////////////////////// */}
                        <hr /><br />

                        <Grid container justify='center' spacing={2} >
                            <Grid item ><StyledBtn
                                variant='contained' size='medium'
                                disabled={(isSubmitting)} color='primary' type="submit" >
                                {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Apply</div>}
                            </StyledBtn> </Grid>
                            <Grid item><StyledBtn
                                variant='contained' size='medium'
                                color='primary' type="button"
                                onClick={e => { onSaveAndContinue(values) }}
                            >
                                {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Save and Continue Later</div>}
                            </StyledBtn> </Grid>
                        </Grid>
                        {Object.keys(errors).length === 1 ?
                            <Typography align='center' style={{ color: "red", marginTop: "10px" }} >Some input field is empty</Typography> :
                            Object.keys(errors).length > 1 ?
                                <Typography align='center' style={{ color: "red", marginTop: "10px" }} >Some input fields are empty</Typography> :
                                ""
                        }
                    </Form>
                )
            }}
        </Formik >
    )
}

export default ApplyQardanHasana;



//////////////////////////////////  Helping components ////////////////////////////////

export const SecurityOffered: FC<{ name: string, label: string, scope?: GenerateFrom }> = ({ name, label, scope, ...others }) => {
    const [state, setState] = useState({ jewellery: true });
    // const [field, meta, helpers] = useField({ name });
    // console.log("render")
    return (
        <>
            <Grid container spacing={1} direction='column'  >
                <Typography color='textPrimary' >{label}</Typography>
                <Grid item container xs={12} sm={6} alignContent='center' >
                    <Grid item ><Checkbox
                        style={{ margin: '0 10px 0 0', padding: '3px' }}
                        color='primary'
                        // value={state.jewellery}
                        defaultChecked={state.jewellery}
                        onChange={(e) => { setState({ ...state, jewellery: e.target.checked }) }}
                        inputProps={{ 'aria-label': 'jewellery checkbox' }}
                    /></Grid>
                    <Grid item container alignContent='center' xs={3}  ><Typography>Jewellery</Typography></Grid>
                </Grid>
                {state.jewellery && <Grid item xs={12} sm={6} md={4}  >
                    <FastField
                        InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                        component={TextField} type='text' variant="outlined" name={name} onChange={(e) => { filterNumber({ e, handleChange: scope?.formik?.handleChange!, formating: true }) }} label="Jewellery value" fullWidth />
                </Grid>}
            </Grid>
            <br /><br />
        </>
    )
}
