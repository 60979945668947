import React, { FC, useState, useEffect } from 'react';
import { Button } from '../../';
import { Dialog, Box, Typography, Grid, CircularProgress, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Close, Edit, Delete, Add, Save } from '@material-ui/icons';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { HSuserBank } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { TextField } from 'formik-material-ui';
import { compare2Objects } from '../../../helper';
import { addHsUserBankAccount, updateHsUserBankAccount, deleteHsUserBankAccount, getHsUserBankAccounts } from '../../../reduxStore/slices/hsPortalSlice';
import { ConfirmationDialog, ErrorDialog, SuccessDialog } from '../../Alert';



const formSchema = yup.object({
    id: yup.number(),
    // user_id: yup.string().matches(hsTmNumberRegex, { message: "Invalid format" }),
    bank_account_number: yup.string().required('Required'),
    name_as_per_bank_account: yup.string().required('Required'),
    sort_code: yup.string().matches(/^[0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/, { message: 'sort code should be like xx-xx-xx' }).required('Required'),
})
interface FormFields extends yup.InferType<typeof formSchema> { }

const formInitialValues: FormFields = {
    id: -1,
    bank_account_number: "",
    name_as_per_bank_account: "",
    sort_code: "",
}


const BankAccountsList: FC = () => {
    const [_open, setOpen] = useState(false);
    // const [isAddBank, setAddBank] = useState(true);
    const dispatch = useAppDispatch();
    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };
    const user_id = useAppSelector(s => s.hsPortal.userDetails?.id);
    const userBanks = useAppSelector(s => s.hsPortal.userBanks);

    const onSubmit: any = async (values: FormFields, { setSubmitting, resetForm }: FormikHelpers<FormFields>) => {
        // console.log(values);
        // await handleSubmit(values);
        const { err, res } = await handleAddHsUserBankAccount({ ...values })
        setSubmitting(true);
        // res && resetForm();
        res && handleClose();
    }

    const handleAddHsUserBankAccount = async ({ sort_code, name_as_per_bank_account, bank_account_number }) => {
        const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "Do you want to add new bank details", confirmBtnText: "Yes" })
        if (!isConfirmed) return {} // { setAddBank(false); return }
        const { err, res } = await new Promise<{ err, res }>((resolve, reject) => {
            user_id && dispatch(addHsUserBankAccount({
                input: { user_id, sort_code, name_as_per_bank_account, bank_account_number },
                callback: (err, res) => {
                    // res && SuccessDialog("Added", res.message)
                    err && ErrorDialog("Error", err.errors![0].message);
                    resolve({ err, res })
                }
            }));
        })
        return { err, res }
    }

    return (
        <>
            <Button onClick={handleClickOpen} color='primary'>My Bank Accounts</Button>
            <Dialog open={_open} onClose={handleClose} maxWidth='md' fullWidth style={{ zIndex: 1000 }}>
                <DialogTitle style={{ paddingBottom: "7px" }}  >
                    <Grid container justify='flex-end' alignItems='center' style={{ color: 'inherit' }} >
                        <IconButton title='Close' onClick={handleClose} ><Close /></IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box mb="1rem" borderBottom="1px solid lightgray" display="flex">
                        <Box style={{ width: "10%" }} >
                            {/* <IconButton aria-label="add-btn" size="small" onClick={e => { setAddBank(true) }} ><Add fontSize='small' /></IconButton> */}
                        </Box>
                        <Typography style={{ width: "30%" }} ><b>Name as per bank account</b></Typography>
                        <Typography style={{ width: "40%" }} ><b>Account number</b></Typography>
                        <Typography style={{ width: "20%" }} ><b>Sort code</b></Typography>
                    </Box>

                    <Formik initialValues={{ ...formInitialValues }}
                        validationSchema={formSchema}
                        onSubmit={onSubmit}
                    // validate={handleValidation}
                    >
                        {({ isSubmitting }) => {
                            // console.log("compare2Objects",compare2Objects(others, values))
                            return <Form style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginBottom: "1rem", borderBottom: "1px solid lightgray" }} >
                                <Box width='100%' mb="1rem" display="flex" >
                                    <Box display="flex" alignItems="center" flexWrap="wrap" style={{ width: "10%" }} >
                                        {/* <IconButton aria-label="cancel-add-btn" size="small" style={{ margin: "5px" }} onClick={e => { setAddBank(false) }} ><Close fontSize='small' /></IconButton> */}
                                    </Box>
                                    <Field style={{ width: "30%" }} required component={TextField} size="small" name="name_as_per_bank_account" label="" type='text' variant='outlined' />
                                    <Field style={{ width: "40%" }} required component={TextField} size="small" name="bank_account_number" label="" type='text' variant='outlined' />
                                    <Field style={{ width: "20%" }} required component={TextField} size="small" name="sort_code" label="" type='text' variant='outlined' />
                                </Box>
                                <Button disabled={isSubmitting} aria-label="save-btn" size='small' type="submit" color='primary' style={{ marginBottom: "1rem" }} >
                                    {isSubmitting ?
                                        <CircularProgress size="1rem" /> :
                                        "Add"
                                    }
                                </Button>
                            </Form>
                        }}
                    </Formik>

                    {/* [
                        { id: 1, bank_account_number: "asd", name_as_per_bank_account: "asd", sort_code: "22-22-22" },
                        { id: 2, bank_account_number: "asd", name_as_per_bank_account: "asd", sort_code: "22-22-22" },
                    ].map((v, idx) => { */}
                    {userBanks.map((v, idx) => {
                        return <AccountRow {...v} key={idx} />
                    })}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default BankAccountsList


const AccountRow: FC<Omit<HSuserBank, "__typename" | "user_id">> = ({ children, ...others }) => {
    const dispatch = useAppDispatch();
    const [isEdit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const fetchingStatus = useAppSelector(s => s.hsPortal.fetchingStatus)
    const fetchingStatusDeleteHsUserBankAccount = useAppSelector(s => s.hsPortal.fetchingStatus.deleteHsUserBankAccount);
    const fetchingStatusUpdateHsUserBankAccount = useAppSelector(s => s.hsPortal.fetchingStatus.updateHsUserBankAccount);
    const user_id = useAppSelector(s => s.hsPortal.userDetails?.id)

    const { sort_code, name_as_per_bank_account, bank_account_number } = others;
    // console.log("others", others)


    const handleUpdateHsUserBankAccount = async ({ id, sort_code, name_as_per_bank_account, bank_account_number }) => {
        const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "You won't be able to revert this!", confirmBtnText: "Yes, Save it!" })
        if (!isConfirmed) { return }
        setLoading(true)
        const { err, res } = await new Promise<{ err, res }>((resolve, reject) => {
            user_id && dispatch(updateHsUserBankAccount({
                input: { user_id, id, sort_code, name_as_per_bank_account, bank_account_number },
                callback: (err, res) => {
                    res && SuccessDialog("Updated", res.message);
                    err && ErrorDialog("Update Error", err.errors![0].message);
                    resolve({ err, res });
                }
            }));
        })
        res && setEdit(false);
        setLoading(false);

    }

    const handleDeleteHsUserBankAccount = async ({ id }) => {
        const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "You won't be able to revert this!", confirmBtnText: "Yes, delete it!" })
        if (!isConfirmed) { return }
        setLoading(true);
        const { err, res } = await new Promise<{ err, res }>((resolve, reject) => {
            dispatch(deleteHsUserBankAccount({
                input: { id },
                callback: (err, res) => {
                    res && SuccessDialog("Deleted", res.message)
                    err && ErrorDialog("Delete Error", err.errors![0].message)
                    resolve({ res, err });
                }
            }));
        })
        setLoading(false);
    }

    const onSubmit: any = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        // console.log(values);
        // await handleSubmit(values);
        setSubmitting(true);
    }

    return (
        <Formik initialValues={{ ...formInitialValues, ...others }}
            validationSchema={formSchema}
            onSubmit={onSubmit}
        // validate={handleValidation}
        >
            {({ setValues, values, }) => {
                // console.log("compare2Objects", compare2Objects(others, values));
                // console.log("others", others, ", ", "values", values);
                useEffect(() => { setValues(others) }, [others])
                return <Form style={{ display: "flex", marginBottom: "1rem", borderBottom: "1px solid lightgray" }} >
                    <Box display="flex" alignItems="center" flexWrap="wrap" style={{ width: "10%" }} >
                        {isEdit ?
                            <IconButton aria-label="cancel-edit-btn" size="small" style={{ margin: "5px" }} onClick={e => { setEdit(false) }} ><Close fontSize='small' /></IconButton> :
                            <IconButton aria-label="edit-btn" size="small" style={{ margin: "5px" }} onClick={e => { setEdit(true); setValues(others); }} ><Edit fontSize='small' /></IconButton>}
                        {isLoading ?
                            <CircularProgress size="1rem" /> :
                            compare2Objects(others, values) ?
                                <IconButton aria-label="delete-btn" size="small" style={{ margin: "5px" }} onClick={e => { handleDeleteHsUserBankAccount({ id: values.id }) }} ><Delete fontSize='small' /></IconButton> :
                                <IconButton aria-label="save-btn" size="small" style={{ margin: "5px" }} onClick={e => { handleUpdateHsUserBankAccount({ ...values }) }} ><Save fontSize='small' /></IconButton>}
                    </Box>
                    {isEdit ?
                        <>
                            <Field style={{ width: "30%" }} required component={TextField} size="small" name="name_as_per_bank_account" label="" type='text' variant='outlined' />
                            <Field style={{ width: "40%" }} required component={TextField} size="small" name="bank_account_number" label="" type='text' variant='outlined' />
                            <Field style={{ width: "20%" }} required component={TextField} size="small" name="sort_code" label="" type='text' variant='outlined' />
                        </> :
                        <>
                            <Typography style={{ width: "30%" }} >{name_as_per_bank_account}</Typography>
                            <Typography style={{ width: "40%" }} >{bank_account_number}</Typography>
                            <Typography style={{ width: "20%" }} >{sort_code}</Typography>
                        </>}
                </Form>
            }}
        </Formik>
    )
}