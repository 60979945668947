import React, { FC, useState } from 'react';
import { HSloginResponse } from '../../../graphql/types';
import { verifyHSsecuritycode, sendEmailConfirmationCode, changeHSuserPassword } from '../../../reduxStore/slices/hsPortalSlice';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { GraphQLResult } from '../../../typedefs';
import UpdateDetailsForm from './UpdateDetailsForm';
import config from '../../../../app-config.json';
import { PageProps, navigate } from 'gatsby';
import { ErrorDialog } from '../../Alert';
import * as formStyle from '../../../components/Form/style';
import { CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { BackDrop, Button } from '../../../components';
// import { ChangePasswordForm,  ChangePasswordFormProps } from '../../../components/Form';
import ChangePasswordForm, { Props as ChangePasswordFormProps } from '../../../components/Form/ChangePassword';
import { Response } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';

const PARENT_PAGE_PATH = config.pages.HUSAIN_SCHEME.path;

const Main: FC<{ onStepComplete?: () => void }> = ({ onStepComplete }) => {
    const [step, setStep] = useState<"Update Details" | "Verify Code" | "Update Password">("Update Details");
    const dispatch = useAppDispatch();
    const hsUserId = useAppSelector(s => s.hsPortal.userDetails?.id);

    const handleSendEmailConfirmationCode = () => {
        setStep("Verify Code");
        dispatch(sendEmailConfirmationCode());
    }

    const handleChangePassword: ChangePasswordFormProps['handleSubmit'] = async (values) => {
        const { err, res } = await new Promise<{ err?: any, res?: Response }>((resolve) => {
            dispatch(changeHSuserPassword({
                oldPassword: values.oldPassword, newPassword: values.newConfirmPassword, callback: (err, res) => {
                    err && resolve({ err })
                    res && resolve({ res })
                }
            }))
        })
        if (res) {
            onStepComplete && onStepComplete();
            return { successMsg: res.message || "Password has been changed successfully" }
        } else {
            console.log("ChangePassword_Error", err);
            return { errorMsg: err.errors[0].message }
        }
    }

    // if (step === "Update Details")
    //     return (
    //         <div>
    //             <UpdateDetailsForm onSuccess={handleSendEmailConfirmationCode} hideFields={["id", "first_name", "last_name"]} />
    //         </div>
    //     )
    // else if (step === "Verify Code" && hsUserId)
    //     return <VerifyCode onSuccess={() => { setStep("Update Password"); }} hsUserId={hsUserId} />

    // else if (step === "Update Password")
    //     return <ChangePasswordForm defaultOpen={true} handleSubmit={handleChangePassword} />

    // else
    //     return <></>

    return (
        <>
            {step === "Update Details" ?
                <UpdateDetailsForm defaultOpen={true} onSuccess={handleSendEmailConfirmationCode} hideFields={["id", "first_name", "last_name"]} /> :
                (step === "Verify Code" && hsUserId) ?
                    <VerifyCode onSuccess={() => { setStep("Update Password"); }} hsUserId={hsUserId} /> :
                    step === "Update Password" ?
                        <ChangePasswordForm defaultOpen={true} handleSubmit={handleChangePassword} /> :
                        <></>
            }

        </>
    )

}

export default Main;



const VerifyCode: FC<{ hsUserId: string, onSuccess?: () => void }> = ({ hsUserId, onSuccess }) => {
    const dispatch = useAppDispatch();
    const isVerifyingCode = useAppSelector(s => s.hsPortal.fetchingStatus.verifyHSsecuritycode);
    const [code, setCode] = useState("");

    /* verificationCode callback */
    const onCodeVerify = async () => {
        const { res } = await new Promise<{ err?: GraphQLResult<any>, res?: HSloginResponse }>((resolve, reject) => {
            dispatch(verifyHSsecuritycode({
                hsUserId, verificationCode: code,
                callback: (err, res) => {
                    if (res) { resolve({ res }) };
                    err?.errors && ErrorDialog("Verification Failed", err.errors[0].message)
                    err && resolve({ err });
                }
            }));
        })
        res?.userDetails && onSuccess && onSuccess();
    }

    return (
        <Grid style={{ ...formStyle.formStyle(), minWidth: '280px', maxWidth: '500px', margin: '0 auto' }} >
            <BackDrop open={isVerifyingCode} >
                <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
            </BackDrop>

            <Typography>
                <span style={{ fontSize: "22px", fontWeight: "bold" }} >Enter your verification code</span>
                <br /><br />
                A Verification Code has been sent to your email address.
            </Typography>

            <br />

            <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                <Grid item container alignItems='center' >
                    <TextField required fullWidth label="Code" type='text' variant='outlined' value={code} onChange={e => setCode(e.target.value)} />
                </Grid>
                <br />
                <Grid item container direction='column' >
                    <Button onClick={onCodeVerify} disabled={(!code || isVerifyingCode)} color='primary' size='large' type='submit' >
                        {isVerifyingCode ? <div><CircularProgress color='inherit' /></div> : <div>Verify</div>}
                    </Button>
                </Grid>
            </Grid>
            <br />
        </Grid>
    )
}
