import React, { FC, useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery, TextField, CircularProgress } from '@material-ui/core';
import { BackDrop, Table, Button, Seo, PageLayout } from '../../components';
import { screenWidths } from '../../theme/css-generators';
import { useAppDispatch, useAppSelector } from '../../reduxStore/store';
import { loginToSBQHPortal, checkTokenExpirySBQHuser, verifySBQHSecuritycode } from '../../reduxStore/slices/sbqhPortalSlice';
import { BodyWrap } from "../taher-scheme";
import config from '../../../app-config.json';
import { PageProps, navigate } from 'gatsby'
import { LoginForm } from '../../components/Form';
import { getCookie } from '../../helper';
import { FormFields as LoginFormProps } from '../../components/Form/LoginForm';
import { siteMetadata } from '../../../gatsby-config';
import { GraphQLResult } from '../../typedefs';
import { SBQHloginResponse } from '../../graphql/types';
import { LoginFailDialog, ErrorDialog } from '../../components/Alert';
import * as formStyle from '../../components/Form/style';

const PAGE_TITLE = config.pages.ADMIN.routes.login.title;
const PARENT_PAGE_TITLE = config.pages.ADMIN.title;
const PARENT_PAGE_PATH = config.pages.ADMIN.path;
const LOGIN_FORM_PATH = config.pages.ADMIN.routes.login.path
export const LOGIN_PAGE_LINK = `${PARENT_PAGE_PATH}${LOGIN_FORM_PATH}`;

const Login: FC<PageProps> = () => {
    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} indexing={false} />
            <BodyWrap >
                <br />
                <Typography variant='h2' >{PARENT_PAGE_TITLE}</Typography>
                <Typography variant='h6' color='inherit' gutterBottom >(Account Login)</Typography>
                <hr /><br /> <br />
                <LoginFormHandler />
                <br />
            </BodyWrap>
        </PageLayout>
    )
}

export default Login;



const LoginFormHandler: FC<{}> = ({ }) => {
    const dispatch = useAppDispatch();
    const userIdFieldName = 'email';
    const ischeckingTokenExpirySBQHuser = useAppSelector(s => s.sbqhPortal.fetchingStatus.checkTokenExpirySBQHuser);
    const [loginDone, setLoginDone] = useState(false);
    const [email, setEmail] = useState("");

    /* Login callback */
    const onLogin = async (val: LoginFormProps) => {
        const res = await new Promise<GraphQLResult<any> | string>((resolve, reject) => {
            setEmail(val[userIdFieldName]);
            dispatch(loginToSBQHPortal({
                email: val[userIdFieldName], password: val.password,
                callback: (err, res) => {
                    if (res) { resolve(res) };
                    err && resolve(err);
                    err?.errors && LoginFailDialog(err.errors[0].message)
                }
            }));
        })
        typeof res === "string" && setLoginDone(true);
    }

    useEffect(() => {
        (async () => {
            if (getCookie("sbqh_auth_token")) {
                const { res } = await new Promise<{ err: any, res: any }>((resolve, reject) => {
                    dispatch(checkTokenExpirySBQHuser({
                        callback: (err, res) => {
                            resolve({ err, res })
                        }
                    }))
                })
                if (res) { navigate(PARENT_PAGE_PATH) }
            }
        })()
    }, [])

    return (
        <>
            <BackDrop open={ischeckingTokenExpirySBQHuser} >
                <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
            </BackDrop>

            {!loginDone ?
                <LoginForm idName={userIdFieldName}
                    handleSubmit={onLogin}
                /> :
                <VerifyCode email={email} />
            }
        </>
    )
}


const VerifyCode: FC<{ email: string }> = ({ email }) => {
    const dispatch = useAppDispatch();
    const isVerifyingCode = useAppSelector(s => s.sbqhPortal.fetchingStatus.verifySBQHSecuritycode);
    const [code, setCode] = useState("");

    /* verificationCode callback */
    const onCodeVerify = async () => {
        await new Promise<GraphQLResult<any> | SBQHloginResponse>((resolve, reject) => {
            dispatch(verifySBQHSecuritycode({
                email: email, verificationCode: code,
                callback: (err, res) => {
                    if (res) { navigate(PARENT_PAGE_PATH); resolve(res) };
                    err && resolve(err);
                    err?.errors && ErrorDialog("Verification Failed", err.errors[0].message)
                }
            }));
        })
    }

    return (
        <Grid style={{ ...formStyle.formStyle(), minWidth: '280px', maxWidth: '500px', margin: '0 auto' }} >
            <BackDrop open={isVerifyingCode} >
                <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
            </BackDrop>

            <Typography>
                <span style={{ fontSize: "22px", fontWeight: "bold" }} >Enter your verification code</span>
                <br /><br />
                A Verification Code has been sent to your email address.
            </Typography>

            <br />

            <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                <Grid item container alignItems='center' >
                    <TextField required fullWidth label="Code" type='text' variant='outlined' value={code} onChange={e => setCode(e.target.value)} />
                </Grid>
                <br />
                <Grid item container direction='column' >
                    <Button onClick={onCodeVerify} disabled={(!code || isVerifyingCode)} color='primary' size='large' type='submit' >
                        {isVerifyingCode ? <div><CircularProgress color='inherit' /></div> : <div>Verify</div>}
                    </Button>
                </Grid>
            </Grid>
            <br />
        </Grid>
    )
}