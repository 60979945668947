import React, { FC } from 'react';
import { Seo, PageLayout } from '../../components';
import { navigate, PageProps } from 'gatsby';
import config from '../../../app-config.json';
import { Grid, Typography } from '@material-ui/core';
import * as s from '../../style.pages/index.style';
import colors from '../../theme/colors';
import { StyledBtn1 } from '../../components/Button';
import styled from 'styled-components';
import { screenWidths } from '../../theme/css-generators';
import { PAYMENT_FORM_LINK } from './payment-form';
import { siteMetadata } from '../../../gatsby-config';

export const PAGE_TITLE = config.pages.TAHERI_SCHEME.title;
// export const PAGE_PATH = config.pages.TAHERI_SCHEME.path;

export const MetaDescription = `The ${PAGE_TITLE} is based on the understanding that contributions made by Mumineen will be perpetual in nature, i.e. once the amount is contributed the amount becomes part of the corpus of the relevant Qardan Hasana Scheme. Contributions for this scheme is collected during two Miqaat i.e. Urus & Milad of Syedna Taher Saifuddin RA.`

//////////////////////////////////  component ////////////////////////////////
const TaheriScheme: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo
                title={`${PAGE_TITLE} | ${siteMetadata.title}`}
                propMetaDescription={MetaDescription}
            />
            <BodyWrap>
                <br />
                <Typography variant='h2' color='inherit' >{PAGE_TITLE}</Typography>
                <Typography color='inherit' variant='h6' gutterBottom >(Non-Refundable)</Typography>
                <hr /><br /><br />
                <Typography align='justify' >Like the {config.pages.MOHAMMADI_SCHEME.title}, the {PAGE_TITLE} is also based on the understanding that contributions made by Mumineen will be perpetual in nature, i.e. once the amount is contributed the amount becomes part of the corpus of the relevant Qardan Hasana Scheme.</Typography>
                <br />
                <Typography align='justify' >Contributions for this scheme is collected during two Miqaat i.e. Urus & Milad of Syedna Taher Saifuddin RA.</Typography>
                <br /><br />
                <Grid container justify='center' >
                    <_StyledBtn1 onClick={() => { navigate(PAYMENT_FORM_LINK) }} >
                        Contribute Towards {PAGE_TITLE}
                    </_StyledBtn1>
                </Grid>
            </BodyWrap>
        </PageLayout>
    )
}

export default TaheriScheme;

//////////////////////////////////// styling ///////////////////////////////////
export const BodyWrap = styled(s.BodyWrap)`
    &>p{ font-size: 21px !important; }

    @media (max-width: ${screenWidths._500px}){
        &>p{ font-size: 18px !important; }
    }
`

export const _StyledBtn1 = styled(StyledBtn1)`
    color: ${colors[4]};
`;