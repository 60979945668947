import React, { FC, useState } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { AccountCircle } from 'mdi-material-ui';
import * as s from './style';
import * as yup from 'yup';
import { } from '../Alert';
import { FormInterface } from './form';
import { Button } from '../';
import { CircularProgress, Grid, InputAdornment, Dialog, Typography, MenuItem, Box } from '@material-ui/core';
import { filterNumber } from './UserDetails';
import styled from 'styled-components';
import { screenWidths } from '../../theme/css-generators';
import { useAppDispatch, useAppSelector } from '../../reduxStore/store';
import { hsPaymentWithdrawalRequest } from '../../reduxStore/slices/hsPortalSlice'
import { ErrorDialog } from '../../components/Alert';
import { getCookie } from '../../helper';
import { navigate } from 'gatsby-link';
import { LOGIN_FORM_LINK } from '../../pages/husain-scheme/login';
import { HSuserBank } from '../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';

export enum FieldName {
    // nameAsPerBankAccount = "nameAsPerBankAccount",
    // sortCode = "sortCode",
    // accountNumber = "accountNumber",
    requestedAmount = "requestedAmount",
    email = "email",
    userBank = "userBank",
}

const formSchema = yup.object({
    // [FieldName.nameAsPerBankAccount]: yup.string().required('Required'),
    // [FieldName.sortCode]: yup.string().matches(/^[0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/, { message: 'sort code should be like xx-xx-xx' }).required('Required'),
    // [FieldName.accountNumber]: yup.string().required('Required'),
    [FieldName.email]: yup.string().email().required("Required"),
    [FieldName.requestedAmount]: yup.string().required("Required"),
    [FieldName.userBank]: yup.mixed<HSuserBank>().required("Required"),
})

interface FormFields extends yup.InferType<typeof formSchema> { }

const field: { [P in keyof FormFields]: { name: string, label: string } } = {
    // nameAsPerBankAccount: { name: "nameAsPerBankAccount", label: "Name as per Bank account" },
    // sortCode: { name: "sortCode", label: "Sort code" },
    // accountNumber: { name: "accountNumber", label: "Account number" },
    requestedAmount: { name: "requestedAmount", label: "Requested amount" },
    email: { name: "email", label: "Email" },
    userBank: { name: "userBank", label: "Bank Details" },
}


interface FormProps {
    maxAmount?: number,
    onSuccessSubmit?: () => void,
}

const FundsWithdrawRequestForm: FC<FormProps> = ({ maxAmount, onSuccessSubmit }) => {
    // const [isPaymentRequestSent, setPaymentRequestSent] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const userDetails = useAppSelector(s => s.hsPortal.userDetails)
    const userBanks = useAppSelector(s => s.hsPortal.userBanks)

    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    const formInitialValues: FormFields = {
        // nameAsPerBankAccount: "",
        // sortCode: "",
        // accountNumber: "",
        requestedAmount: "",
        email: userDetails?.email || "",
        userBank: {} as any
    }

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<any>) => {
        console.log(values);
        const { err, res } = await new Promise<{ err: string | null, res: any }>((resolve, reject) => {
            dispatch(hsPaymentWithdrawalRequest({
                amount: Number(values.requestedAmount),
                bank_account_number: values.userBank.bank_account_number,
                name_as_per_bank_account: values.userBank.name_as_per_bank_account,
                sort_code: values.userBank.sort_code,
                email: values.email,
                callback: (err, res) => {
                    err?.errors && resolve({ err: err.errors[0].message, res: null });
                    res && resolve({ err: null, res })
                }
            }))
        });
        // console.log(err, res)
        setSubmitting(false);
        err && ErrorDialog("Request Failed", err);
        if (res) {
            handleClose();
            onSuccessSubmit && onSuccessSubmit();
        }

    }

    const handleValidateForm = (values: FormFields) => {
        const error: { [P in keyof FormFields]?: string; } = {}

        if (!values.requestedAmount) {
            error.requestedAmount = "Required"
        }
        else if (typeof maxAmount === "number" && Number(values.requestedAmount) > maxAmount) {
            error.requestedAmount = "You have insufficient balance"
        }
        else if (Number(values.requestedAmount) < 0) {
            error.requestedAmount = "Negative amount cannot be requested"
        }

        return error
    }


    return (
        <>
            <Button onClick={handleClickOpen} size='large' color='primary' >Withdraw Funds</Button>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
                <Formik initialValues={formInitialValues}
                    validationSchema={formSchema}
                    onSubmit={onSubmit}
                    validate={handleValidateForm}
                >
                    {({ isSubmitting, handleChange }) => {
                        return (
                            <Form style={{ ...s.formStyle(), }}  >

                                {/* /////////////////////////////////////////   personal info /////////////////////////////////////// */}
                                <s.LabelWrap style={{ padding: "10px" }} >
                                    <Typography variant='h5' align='center' >Payment Request Form</Typography>
                                </s.LabelWrap>
                                <br />
                                <Grid xs={12} item>
                                    <Field required variant="outlined" component={TextField} select type='select' name={field.userBank.name} label={field.userBank.label} fullWidth
                                        children={userBanks.map((bank, idx) => (
                                            <MenuItem style={{ display: "block" }} key={idx} value={bank as any}>
                                                <Typography>{bank.name_as_per_bank_account}</Typography>
                                                <Typography variant='body2' color="textSecondary"  >{bank.bank_account_number}</Typography>
                                                <Typography variant='body2' color="textSecondary" >{bank.sort_code}</Typography>
                                            </MenuItem>
                                        ))}
                                    />
                                </Grid>
                                <Typography color="textSecondary" >Please add your preferred bank account by going to the main page and click My Bank Accounts</Typography>
                                <br />
                                {/* <Grid item xs={12} ><Field fullWidth required component={TextField} type='text' name={field.nameAsPerBankAccount.name} label={field.nameAsPerBankAccount.label} /></Grid>
                                <br />
                                <Grid item xs={12} ><Field fullWidth required component={TextField} type='text' placeholder="xx-xx-xx" name={field.sortCode.name} label={field.sortCode.label} onChange={(e) => { sortCodeFormater({ e, handleChange }) }} /></Grid>
                                <br />
                                <Grid item xs={12} ><Field fullWidth required component={TextField} type='text' name={field.accountNumber.name} label={field.accountNumber.label} onChange={(e) => { filterNumber({ e, handleChange }) }} /></Grid>
                                <br /> */}
                                <Grid item xs={12} ><Field fullWidth required component={TextField} type='email' name={field.email.name} label={field.email.label} /></Grid>
                                <Typography color="textSecondary" >The payment confirmation will be sent to this email address</Typography>
                                <br />
                                <Grid item xs={12} ><Field fullWidth required component={TextField} type='number' name={field.requestedAmount.name} label={field.requestedAmount.label} InputProps={{ startAdornment: <InputAdornment position="start">GBP-£</InputAdornment> }} /></Grid>
                                <br />

                                {/* /////////////////////////////////////////   Submit /////////////////////////////////////// */}
                                <br /><br />

                                <Grid container justify='center' spacing={2} >
                                    <Grid item ><Button
                                        onClick={handleClose}
                                        variant='outline' size='medium'
                                        color='primary' type="button" > Cancel</Button>
                                    </Grid>
                                    <Grid item ><Button
                                        variant='contained' size='medium'
                                        disabled={isSubmitting} color='primary' type="submit" >
                                        {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Continue</div>}
                                    </Button> </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </Dialog>
        </>
    )
}

export default FundsWithdrawRequestForm;

const sortCodeFormater = ({ e, handleChange, include = [] }: {
    e: React.ChangeEvent<HTMLInputElement>, handleChange: (e: React.ChangeEvent) => void, include?: string[],
}) => {
    const filterChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "-", ...include];
    let val = e.target.value.split('').filter((chr) => filterChars.includes(chr)).join('');
    // if (val.length > 0 && val.replace(/-/g, '').length % 2 === 0) {
    //     val += '-'
    // }
    // sort code characters should not be more than 8
    e.target.value = val.slice(0, 8);
    handleChange(e);
}

// const _sortCodeFormater = ({ e, handleChange }: {
//     e: React.KeyboardEvent<HTMLInputElement>, handleChange: (e: React.KeyboardEvent) => void,
// }) => {
//     const char = e.key
//     let val = e.target.value?.slice(0,-1) || "";
//     console.log(val)
//     const filterChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9",];
//     val += filterChars.includes(char) ? char : "";
//     // let val = e.target.value.split('').filter((chr) => filterChars.includes(chr)).join('');
//     // if (val.length > 0 && val.replace(/-/g, '').length % 2 === 0) {
//     //     val += '-'
//     // }
//     console.log(val)
//     if (val.length === 2) { val += '-' }
//     else if (val.length === 5) { val += '-' }
//     // sort code characters should not be more than 8
//     e.target.value = val.slice(0, 8);
//     handleChange(e);
//     console.log(val)
// }

// function formSchemaObject(): FormInterface {
//     return {
//         formGroup: [
//             {
//                 label: "Request to Withdraw funds",
//                 name: "fundRequestForm",
//                 labelIcon: () => <span><AccountCircle fontSize='large' /></span>,
//                 fields: [
//                     { name: 'nameAsPerBankAccount', label: "Name as per Bank account", type: "text", value: "" },
//                     { name: 'sortCode', label: "Sort code", type: "semi-number", value: "" },
//                     { name: 'accountNumber', label: "Account number", type: "semi-number", value: "" },
//                     { name: 'requestedAmount', label: "Requested amount", type: "number", value: "" },
//                 ]
//             }
//         ]
//     }
// }

// const AfterRequestSent = styled.div`
//     font-family: "Playball";
//     >h2{ font-size: 37px; margin-bottom: 35px; }
//     >p{ font-size: 26px; margin-bottom: 35px; }

//     @media (max-width: ${screenWidths._500px}){
//         >h2{ font-size: 26px; margin-bottom: 20px; }
//         >p{ font-size: 18px; margin-bottom: 20px; }
//     }
// `