import React, { FC, useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { FormInterface, FieldInterface } from './form';
import { Form, Formik, Field, ErrorMessage, FastField, FormikHelpers, FormikErrors, FormikProps, useField } from 'formik';
import { TextField, Checkbox, RadioGroup } from 'formik-material-ui';
import { MenuItem, Typography, Grid, GridProps, Radio, FormGroup, TextField as MiTextField, InputAdornment, FormControl, FormLabel, FormControlLabel, RadioGroup as MRadioGroup } from '@material-ui/core';
import { AccountCircle, EuroSymbol, Business, LocalAtm, Description, Person } from '@material-ui/icons';
import * as s from './style';
import { filterNumber } from './UserDetails';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
// import { field } from './UserDetails';

export class GenerateFormSchema {
    readonly obj: object = {};

    constructor(formGroups: FormInterface['formGroup']) {
        formGroups.forEach((form) => {
            const { name, fields } = form;
            this.obj[name] = yup.object().shape(this.generateSchema(fields));
        })

    }
    generateSchema(fields?: FieldInterface[]) {
        if (!fields) { console.log("no fields"); return {} }
        const obj = {};
        fields.forEach(({ name, type, fieldGroup, options, notRequried }) => {
            switch (type) {
                case "fieldGroup":
                    obj[name] = yup.object().shape(this.generateSchema(fieldGroup))
                    break;

                case "text": case "semi-number": case "tel": case "radio": case "date": case "text-area":
                    obj[name] = yup.string()//.required('Required');
                    break;

                case "number":
                    obj[name] = yup.number()//.required('Required');
                    break;

                case "email":
                    obj[name] = yup.string().email('Incorrect email format')//.required('Required');
                    break;

                case "file":
                    obj[name] = yup.mixed<File>()//.required('Required');

                case "custom":
                    obj[name] = yup.mixed();

                case "select":
                    if (options) {
                        // const objschema = arrayToObject(options);
                        obj[name] = yup.mixed()//.required('Required');
                    }
                    break;

                case "text-note":
                    break;

                default:
                    console.log("type", type, "not match");
                    break;
            }
            if (!notRequried && obj[name]?.required) {
                obj[name] = obj[name].required('Required');
            }
        });
        return obj;

    }
    run() {
        return yup.object({ ...this.obj } as any)
    }
}

export const arrayToObject = (array: string[]) => {
    const obj = {};
    array.forEach((value) => obj[value] = value);
    return obj;
}



export class GenerateFormDefaultValues {
    readonly obj: object = {};

    constructor(formGroups: FormInterface['formGroup']) {
        formGroups.forEach((form) => {
            const { name, fields } = form;
            this.obj[name] = this.generateValues(fields);
        })
    }

    generateValues(fields: FieldInterface[]) {
        const obj = {}
        fields.forEach(({ fieldGroup, value, name, type }) => {
            if (type === "fieldGroup" && fieldGroup) {
                obj[name] = this.generateValues(fieldGroup)
            }
            else if (type === 'text-note') {
                return
            }
            else {
                obj[name] = value || ""
            }
        })
        return obj;
    }

    run() {
        return this.obj
    }
}


const _Grid: FC<GridProps & { fieldDescription?: string }> = ({ children, fieldDescription, ...other }) => {
    return <Grid {...other} xs={12} item >{children}</Grid>
}
const Cgrid = React.memo(_Grid);

const FileInput: FC<{ label: string, name: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, fieldDescription?: string, accept?: string, scope: GenerateFrom, multiple?: boolean }> = ({ label, name, fieldDescription, onChange, accept, scope, multiple, ...others }) => {
    // const [field, meta, helpers] = useField({ name });
    // const keys = name.split('.');
    // if (keys.length === 3 && scope.formik?.errors) {
    //     // console.log(keys)
    //     const val = scope.formik.errors[keys[0]]
    //         && scope.formik?.errors[keys[0]][keys[1]]
    //         && scope.formik?.errors[keys[0]][keys[1]][keys[2]];
    //     console.log(val);
    // }
    // console.log('render', accept);
    return (
        <React.Fragment >
            <Grid container spacing={0} direction='column' alignItems='flex-start' >
                <Typography color='textPrimary' >{label}</Typography><div style={{ padding: '5px 0' }}> </div>
                <Grid item xs={12} sm={6} ><input type="file" accept={accept || "image/*"} name={name} onChange={onChange} multiple={multiple} /></Grid>
                <ErrorMessage name={name} render={(msg) => <Typography variant='body2' style={{ color: 'red', textAlign: "left" }}>{msg}</Typography>} />
                {/* <div style={{ padding: '5px 0' }}> </div> */}
                <Typography color='textPrimary' variant='caption' >{fieldDescription}</Typography>
                {/* <div style={{ padding: '5px 0' }}> </div> */}
            </Grid>
            {/* <Typography variant='caption' style={{ color: 'red', textAlign: "left" }}>
                {scope.formik?.getFieldMeta(name).error}
            </Typography> */}
            <br />
        </React.Fragment>
    )
}

const RadioInput: FC<{ label: string, name: string, options?: string[], notRequried?: boolean, scope: GenerateFrom }> = ({ label, name, options, notRequried, scope, ...others }) => {
    if (!options) { throw Error('options are required in select field: ' + name) }
    // console.log(name, scope.formik?.getFieldProps(name).value);
    // if (name === "personal.member_of_husain_sheme.member_of_husain_sheme") {
    //     // console.log(name, scope.formik?.values?.personal?.member_of_husain_sheme?.member_of_husain_sheme);
    // }
    const [field, meta, helpers] = useField({ name });
    // name === "personal.staying_at_current_address_for_more_than_3_years.staying_at_current_address_for_more_than_3_years"
    //     && console.log(name, meta);

    // name === "personal.staying_at_current_address_for_more_than_3_years.staying_at_current_address_for_more_than_3_years"
    //     && console.log(name, helpers)

    const handleChange = (value: string) => {
        scope.formik?.setFieldValue(name, value);
    }

    return (
        <React.Fragment>
            <Cgrid {...others} >
                <FormControl component="fieldset" >
                    <FormLabel component="legend">{label}</FormLabel>
                    <MRadioGroup row onChange={e => handleChange(e.target.value)} value={meta.value || scope.formik?.getFieldProps(name).value || ""} >
                        {options.map((name, idx) => (
                            <FormControlLabel value={name} key={idx} control={<Radio color="primary" />} label={name} labelPlacement="start" />
                        ))}
                    </MRadioGroup>
                </FormControl>
            </Cgrid>
            <ErrorMessage name={name} render={(msg) => <Typography variant='body2' style={{ color: 'red', textAlign: "left" }}>{msg}</Typography>} />
            {/* <Typography variant='caption' style={{ color: 'red', textAlign: "left" }}>
                {scope.formik?.getFieldMeta(name).error}
            </Typography> */}
            <br /><br />
        </React.Fragment >
    )
}

const DateInput: FC<{ label: string, name: string, value?: string, notRequried?: boolean, scope: GenerateFrom, variant?: string }> = ({ label, name, notRequried = false, value, scope, variant, ...others }) => {
    const [field, meta, helpers] = useField({ name });
    const dateFormat = useCallback((date) => moment(date, ["DD/MM/yyyy", "yyyy/MM/DD", "DD-MM-yyyy", "yyyy-MM-DD"]), []);
    // console.log(name, meta?.value || scope.formik?.getFieldProps(name).value || "");
    // if (name === "personal.member_of_husain_sheme.member_of_husain_sheme") {
    //     // console.log(name, scope.formik?.values?.personal?.member_of_husain_sheme?.member_of_husain_sheme);
    // }
    const fieldValue = value ? dateFormat(value).format("DD-MM-yyyy") :
        meta.value ? dateFormat(meta.value).format("DD-MM-yyyy") :
            field.value ? dateFormat(field.value).format("DD-MM-yyyy") :
                null;

    // name == "personal.demograph.date_of_birth" && console.log(name, fieldValue);

    const handleChange = (value: MaterialUiPickersDate) => {
        const _val = value?.format("DD-MM-yyyy") || "";
        console.log("DateInput_change", _val, ", previous-value", fieldValue);
        // scope.formik?.setFieldValue(name, _val);
        helpers.setValue(_val, true)
    }

    useEffect(() => { helpers.setValue(fieldValue) }, [])

    return (
        <React.Fragment>
            <Cgrid {...others} >
                <MuiPickersUtilsProvider utils={MomentUtils} >
                    <KeyboardDatePicker inputVariant={variant as any} fullWidth required={!notRequried} format="DD/MM/yyyy" value={fieldValue && dateFormat(fieldValue)} onChange={date => handleChange(date)} label={label} />
                </MuiPickersUtilsProvider>
            </Cgrid>
            <ErrorMessage name={name} render={(msg) => <Typography variant='body2' style={{ color: 'red', textAlign: "left" }}>{msg}</Typography>} />
            {/* <Typography variant='caption' style={{ color: 'red', textAlign: "left" }}>
                {scope.formik?.getFieldMeta(name).error}
            </Typography> */}
        </React.Fragment >
    )
}

export class GenerateFrom {
    readonly formJSX: React.ReactNode[] = [];
    readonly fieldGroupCssClass?: string = ''
    formik?: FormikProps<object>

    constructor(formGroups: FormInterface['formGroup'], fieldGroupCssClass?: string) {
        this.fieldGroupCssClass = fieldGroupCssClass;
        this.formJSX = formGroups.map((form, idx) => (
            <div key={idx} >
                <s.LabelWrap>
                    <form.labelIcon /> <Typography variant='h5' align='center' >{form.label}</Typography>
                </s.LabelWrap>
                <br />
                {form.fields.map((field) => (
                    this.generateField(field, `${form.name}.${field.name}`)
                ))}
            </div>
        ))

    }

    setFormikProps(formikProps: FormikProps<object>) {
        this.formik = formikProps;
    }
    render() {
        return this.formJSX;
    }

    /* f: is the field type and pfName: parent field name */
    private generateField(f: FieldInterface, pfName: FieldInterface['name']) {
        const fieldVarient = 'outlined'

        if (f.type === 'custom') {
            if (!f.CustomField) { throw Error("CustomField required for type custom") }
            return <f.CustomField {...{ ...f.props, scope: this }} onChange={v => { this.formik?.setFieldValue(pfName, v) }} label={f.label} name={pfName} key={`${pfName}.${f.name}`} />
        }

        else if (f.type === "text-note") {
            return <React.Fragment key={`${pfName}.${f.name}`} >
                <Cgrid> <Typography {...f.props} >{f.label}</Typography> </Cgrid>
                <br />
            </React.Fragment>
        }

        else if (f.type === "number") {
            // return <NumberInput {...f.props} required={!f.notRequried} name={`${pfName}.${f.name}`} label={f.label}
            //     variant={fieldVarient} scope={this} key={`${pfName}.${f.name}`}
            // />
            return <Cgrid {...f.props} key={`${pfName}.${f.name}`} >
                <FastField required={!f.notRequried} fullWidth component={TextField} type={f.type} name={`${pfName}.${f.name}`} label={f.label} InputProps={f.inputProps}
                    variant={fieldVarient}
                />
            </Cgrid>
        }

        // semi-number is not exactly a number field its a text field but it filters numbers only
        else if (f.type === "semi-number") {

            return <Cgrid {...f.props} key={`${pfName}.${f.name}`} >
                <FastField required={!f.notRequried} fullWidth component={TextField}
                    InputProps={f.inputProps}
                    type={"text"} name={`${pfName}.${f.name}`} label={f.label} variant={fieldVarient} onChange={(e) => { filterNumber({ e, handleChange: this.formik?.handleChange!, formating: true }) }} />
            </Cgrid>
        }

        else if (f.type === "text-area") {
            return <Cgrid {...f.props} key={`${pfName}.${f.name}`} >
                <FastField required={!f.notRequried} multiline fullWidth component={TextField} rows={f.props?.rows} name={`${pfName}.${f.name}`} label={f.label} variant={fieldVarient} InputProps={f.inputProps} />
            </Cgrid>
        }

        else if (f.type === "tel") {
            return <Cgrid {...f.props} key={`${pfName}.${f.name}`} >
                <FastField required={!f.notRequried} fullWidth component={TextField} type={f.type} name={`${pfName}.${f.name}`} label={f.label} variant={fieldVarient} onChange={(e) => { filterNumber({ e, handleChange: this.formik?.handleChange!, include: ['+', '-'] }) }} InputProps={f.inputProps} />
            </Cgrid>
        }

        else if (f.type === "date") {
            return <DateInput {...f.props} scope={this} key={`${pfName}.${f.name}`}
                name={`${pfName}.${f.name}`} label={f.label} variant={fieldVarient}
                value={f.value}
            />
        }

        else if (f.type === "select") {
            if (!f.options) { throw Error('options are required in select field') }
            return <Cgrid {...f.props} key={`${pfName}.${f.name}`} >
                <FastField required={!f.notRequried} component={TextField} select type={f.type} name={`${pfName}.${f.name}`} label={f.label} fullWidth variant={fieldVarient} InputProps={f.inputProps}
                    children={f.options.map((name, idx) => (
                        <MenuItem key={idx} value={name} >{name}</MenuItem>
                    ))}
                />
            </Cgrid>
        }

        else if (f.type === 'file') {
            return <FileInput {...f.props} scope={this}
                onChange={e => {
                    // console.log(`${pfName}.${f.name}`, e?.target?.files);
                    if (f.props?.multiple) {
                        e?.target?.files && e.target.files[0] &&
                            this.formik?.setFieldValue(pfName, e.target.files)
                    } else {
                        e?.target?.files && e.target.files[0] &&
                            this.formik?.setFieldValue(pfName, e.target.files[0])
                    }
                }}
                name={pfName} label={f.label} key={`${pfName}.${f.name}`}
            />
        }

        else if (f.type === "radio") {
            // if (!f.options) { throw Error('options are required in select field') }
            // console.log(this.formik?.values?.personal)
            // console.log(`${pfName}.${f.name}`)
            return <RadioInput key={`${pfName}.${f.name}`} scope={this} {...f} name={pfName} />
        }

        else if (f.type === "fieldGroup" && f.fieldGroup) {
            return <React.Fragment key={`${pfName}.${f.name}`}>
                <Grid container spacing={1} className={`${this.fieldGroupCssClass}`}  >{
                    f.fieldGroup.map((field, idx) => (
                        this.generateField(field, `${pfName}`)
                    ))
                }</Grid>
                <br /><br />
            </React.Fragment>
        }

        else { // if no type specified return text field
            return <Cgrid {...f.props} key={`${pfName}.${f.name}`} >
                <FastField required={!f.notRequried} fullWidth component={TextField} type={f.type} name={`${pfName}.${f.name}`} label={f.label} variant={fieldVarient} InputProps={f.inputProps} />
                {f.props?.fieldDescription && <>
                    <div style={{ padding: '1px 0' }}> </div>
                    <Typography color='textPrimary' variant='caption' >{f.props?.fieldDescription}</Typography>
                    <div style={{ padding: '5px 0' }}> </div>
                </>}
            </Cgrid>
        }



    }

}
