import { Message } from '../../aws-cdk/lambda-layer/auth';

export type GraphqlError = {
    path: string[],
    data: object | null,
    errorType: string,
    errorInfo: null | object | string,
    locations: { line: number, column: number, sourceName: any }[],
    message: Message,
}[]

export interface GraphQLResult<T> {
    data?: T;
    errors?: GraphqlError;
    extensions?: {
        [key: string]: any;
    };
}

export enum TransactionType {
    So = 'SO', // "amount in by Standing Order"
    Dc = 'DC', // "Direct creadit or amount in "
    Chq = 'CHQ', // "cheque income"
    Cash = 'CASH', // "amount in by cash"
    PaidOl = 'PAID_OL', // "Cash out or amount debit"
    Bf = 'BF' // "or B/F => Balance brought forward"
  }